import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import { server, config } from "../env";
const SettingSidebar = () => {
  const [isAdmin, setIsAdmin] = useState(false);

  const checkIsAdmin = async () => {
    await axios
      .get(server + "/auth/isAdmin", config)
      .then((res) => {
        if (res.status === 200) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
      })
      .catch((err) => {
        setIsAdmin(false);
      });
  };

  useEffect(() => {
    checkIsAdmin();
  }, []);

  return (
    <div class=" sideNav2 py-5 border-0 border-end d-flex flex-column justify-content-start">
      <ul class="list-group gap-1 pt-1">
        <li class="text-decoration-none">
          <Link
            to="/settings/general-details"
            class="list-group-item border-0 bg-transparent customLink"
          >
            General Details
          </Link>
        </li>
        {isAdmin && (
          <li class="text-decoration-none">
            <Link
              to="/settings/user-management"
              class="list-group-item border-0 bg-transparent customLink"
            >
              User Management
            </Link>
          </li>
        )}
        <li class="text-decoration-none">
          <Link
            to="/settings/workspace"
            class="list-group-item border-0 bg-transparent customLink"
          >
            Workspace
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default SettingSidebar;
