import React, { useState, useEffect } from "react";
import MainLayout from "../../layout/MainLayout";
import "../assets/css/bootstrap.min.scoped.css";
import "../assets/css/style.scoped.css";
import NewModal from "../../components/NewModal";
import axios from "axios";
import { server, config } from "../../env";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";
import { Link } from "react-router-dom";

const ActionPlan = () => {
  const [plans, setPlans] = useState([]);
  const [loader, setLoader] = useState("");
  const [message, setMessage] = useState("");
  const [plan, setPlan] = useState({});
  const [category, setCategory] = useState({});
  const [users, setUsers] = useState([]);
  const [planUsers, setPlanUsers] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const readPlans = async () => {
    await axios
      .get(server + "/plan/getAll", config)
      .then((res) => {
        setPlans(res.data.plans);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addPlan = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    const params = {
      name: e.target.name.value,
    };

    await axios
      .post(server + "/plan/create", params, config)
      .then((res) => {
        setLoader("");
        setMessage(<Alert className="success" message={res.data.message} />);
        readPlans();
      })
      .catch((err) => {
        setLoader("");
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  const addCategory = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    const params = {
      name: e.target.name.value,
      plan: plan._id,
    };

    await axios
      .post(server + "/category/create", params, config)
      .then((res) => {
        setLoader("");
        setMessage(<Alert className="success" message={res.data.message} />);
        readPlans();
      })
      .catch((err) => {
        setLoader("");
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  const addContent = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    const params = {
      name: e.target.name.value,
      category: category._id,
      plan: plan._id,
    };

    await axios
      .post(server + "/content/create", params, config)
      .then((res) => {
        setLoader("");
        setMessage(<Alert className="success" message={res.data.message} />);
        readPlans();
      })
      .catch((err) => {
        setLoader("");
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  useEffect(() => {
    readPlans();
    readUsers();
    checkAdmin();
  }, []);

  const updatePlan = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    const params = {
      name: e.target.name.value,
    };

    await axios
      .post(server + "/plan/update/" + plan._id, params, config)
      .then((res) => {
        setLoader("");
        setMessage(<Alert className="success" message={res.data.message} />);
        readPlans();
      })
      .catch((err) => {
        setLoader("");
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };
  const updateCategory = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    const params = {
      name: e.target.name.value,
    };

    await axios
      .post(server + "/category/update/" + category._id, params, config)
      .then((res) => {
        setLoader("");
        setMessage(<Alert className="success" message={res.data.message} />);
        readPlans();
      })
      .catch((err) => {
        setLoader("");
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  const deletePlan = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    await axios
      .delete(server + "/plan/delete/" + plan._id, config)
      .then((res) => {
        setLoader("");
        setMessage(<Alert className="success" message={res.data.message} />);
        readPlans();
      })
      .catch((err) => {
        setLoader("");
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  const deleteCategory = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    await axios
      .delete(server + "/category/delete/" + category._id, config)
      .then((res) => {
        setLoader("");
        setMessage(<Alert className="success" message={res.data.message} />);
        readPlans();
      })
      .catch((err) => {
        setLoader("");
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  const readUsers = async () => {
    await axios
      .get(server + "/management/readUsers", config)
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const readUsersByPlan = async (plan) => {
    await axios
      .get(server + "/plan/readUsers/" + plan._id, config)
      .then((res) => {
        setPlanUsers(res.data.users);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addUserToPlan = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    const params = {
      user: e.target.user.value,
    };

    await axios
      .post(server + "/plan/addUser/" + plan._id, params, config)
      .then((res) => {
        setLoader("");
        setMessage(<Alert className="success" message={res.data.message} />);
        readPlans();
        readUsersByPlan(plan);
      })
      .catch((err) => {
        setLoader("");
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  const removeUserFromPlan = async (user) => {
    const params = {
      user: user,
    };

    await axios
      .post(server + "/plan/deleteUser/" + plan._id, params, config)
      .then((res) => {
        readPlans();
        readUsersByPlan(plan);
      })
      .catch((err) => {});

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  const checkAdmin = async () => {
    await axios
      .get(server + "/auth/isAdmin", config)
      .then((res) => {
        setIsAdmin(true);
      })
      .catch((err) => {
        setIsAdmin(false);
        console.log(err);
      });
  };

  return (
    <MainLayout>
      <section className="main-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="header-top">
                <h6>SOP</h6>
              </div>
              <div className="header-txt">
                <h1>Action Plans</h1>
                <div className="header-bttn">
                  {/* <a
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal5"
                  >
                    <i className="fa fa-users" /> Manage Access
                  </a> */}
                  {isAdmin && (
                    <a href="#" data-toggle="modal" data-target="#add">
                      <i className="fa fa-plus" /> New Plan
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="faq_main_wrap">
                <div className="bs-example">
                  <div className="accordion" id="accordionExample">
                    {plans.map((plan, index) => (
                      <div className="parent-card" key={index}>
                        <div
                          className="parent-card-header"
                          id={"headingOne" + index}
                        >
                          <button
                            type="button"
                            className="btn"
                            data-toggle="collapse"
                            data-target={"#collapseOne" + index}
                          >
                            <span className="title-4">
                              <img
                                src="/assets/images/dots.svg"
                                alt="a"
                                className="img-fluid"
                              />{" "}
                              {plan.name}
                            </span>
                            <span className="title-6">
                              <Link
                                to=""
                                id={"dropdownMenuButton" + index}
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img
                                  src="/assets/images/dot-single.svg"
                                  alt="a"
                                  className="img-fluid mr-2"
                                />
                              </Link>
                              {isAdmin && (
                                <>
                                  <div
                                    className="dropdown-menu"
                                    aria-labelledby={
                                      "dropdownMenuButton" + index
                                    }
                                  >
                                    <ul>
                                      <li>
                                        <Link
                                          to=""
                                          data-toggle="modal"
                                          data-target="#updatePlan"
                                          onClick={() => {
                                            setPlan(plan);
                                          }}
                                        >
                                          Edit Plan
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          to=""
                                          data-toggle="modal"
                                          data-target="#manage"
                                          onClick={() => {
                                            setPlan(plan);
                                            readUsersByPlan(plan);
                                          }}
                                        >
                                          Manage Access
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          to=""
                                          data-toggle="modal"
                                          data-target="#deletePlan"
                                          onClick={() => {
                                            setPlan(plan);
                                          }}
                                        >
                                          Delete
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </>
                              )}
                              <i className="fa fa-angle-right"> </i>
                            </span>
                          </button>
                        </div>
                        <div
                          id={"collapseOne" + index}
                          className="collapse"
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div className="parent-card-body">
                            <div className="faq_txt_wrap">
                              <div className="bs-example">
                                <div
                                  className="accordion"
                                  id="accordionExamples"
                                >
                                  {plan.category?.map((category, inx) => (
                                    <div className="card-inner" key={inx}>
                                      <div
                                        className="card-header-inner"
                                        id={"headingOnes" + inx}
                                      >
                                        <button
                                          type="button"
                                          className="btn"
                                          data-toggle="collapse"
                                          data-target={"#collapseOnes" + inx}
                                        >
                                          <span className="title-4">
                                            <i className="fa fa-plus"> </i>
                                            <img
                                              src="/assets/images/dots.svg"
                                              alt="a"
                                              className="img-fluid"
                                            />{" "}
                                            {category.name}
                                          </span>
                                          <span className="title-6">
                                            <a
                                              href="#"
                                              id="dropdownMenuButton"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            >
                                              <img
                                                src="/assets/images/dot-single.svg"
                                                alt="a"
                                                className="img-fluid"
                                              />
                                            </a>
                                            {isAdmin && (
                                              <>
                                                <div
                                                  className="dropdown-menu"
                                                  aria-labelledby="dropdownMenuButton"
                                                >
                                                  <ul>
                                                    <li>
                                                      <Link
                                                        to=""
                                                        onClick={() => {
                                                          setCategory(category);
                                                        }}
                                                        data-toggle="modal"
                                                        data-target="#updateCategory"
                                                      >
                                                        Edit Category
                                                      </Link>
                                                    </li>
                                                    <li>
                                                      <Link
                                                        to=""
                                                        onClick={() => {
                                                          setCategory(category);
                                                        }}
                                                        data-toggle="modal"
                                                        data-target="#deleteCategory"
                                                      >
                                                        Delete
                                                      </Link>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </>
                                            )}
                                          </span>
                                        </button>
                                      </div>
                                      <div
                                        id={"collapseOnes" + inx}
                                        className="collapse"
                                        aria-labelledby="headingOnes"
                                        data-parent="#accordionExamples"
                                      >
                                        <div className="card-body-inner">
                                          <div className="pointer-main-wrap">
                                            {category.content?.map(
                                              (content, ind) => (
                                                <div className="card-header-inner">
                                                  <button
                                                    type="button"
                                                    className="btn"
                                                  >
                                                    <Link
                                                      to={`/content/${
                                                        content._id
                                                      }`}
                                                      className="title-4 text-primary"
                                                    >
                                                      <img
                                                        src="/assets/images/dots.svg"
                                                        alt="a"
                                                        className="img-fluid"
                                                      />
                                                      {content.name}
                                                    </Link>
                                                    <span className="title-6">
                                                      <img
                                                        src="/assets/images/eye-icon.svg"
                                                        alt="a"
                                                        className="img-fluid mr-2"
                                                      />
                                                    </span>
                                                  </button>
                                                </div>
                                              )
                                            )}
                                          </div>
                                          <div className="addmore-point">
                                            {isAdmin && (
                                              <Link
                                                to=""
                                                data-toggle="modal"
                                                data-target="#addPointer"
                                                onClick={() => {
                                                  setCategory(category);
                                                  setPlan(plan);
                                                }}
                                              >
                                                <i className="fa fa-plus" /> Add
                                                Pointer
                                              </Link>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}

                                  <div className="card-inner mt-2">
                                    {isAdmin && (
                                      <div>
                                        <button
                                          className="btn btn-outline-secondary w-100 "
                                          style={{
                                            border: "2px dotted #e6e6e6",
                                          }}
                                          data-toggle="modal"
                                          data-target="#addcategory"
                                          onClick={() => {
                                            setPlan(plan);
                                          }}
                                        >
                                          New Category
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* main section end */}
      {/* modal section start */}
      <div className="modal-section">
        <div
          className="modal fade"
          id="exampleModal5"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="modal_text modal_text_2">
                  <h4>SOP Access</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nulla auctor. Sit amet, consectetur adipiscing consectetur
                    adipiscing elit.
                  </p>
                  <form action="">
                    <h6>Sales</h6>
                    <div className="form-group">
                      <label htmlFor="">TEAMMATES</label>
                      <input
                        type="text"
                        placeholder="Select Members"
                        className="form-control"
                      />
                      <img
                        src="/assets/images/plus.svg"
                        alt="+"
                        className="img-fluid pls"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      />
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <div className="d-flex">
                          <span>
                            <img
                              src="/assets/images/avatar.png"
                              alt="a"
                              className="img-fluid"
                              style={{ width: 20 }}
                            />
                            Aryan Tanwar
                          </span>
                          <img
                            src="/assets/images/close.svg"
                            alt="a"
                            className="img-fluid"
                          />
                        </div>
                        <div className="d-flex">
                          <span>
                            <img
                              src="/assets/images/avatar.png"
                              alt="a"
                              className="img-fluid"
                              style={{ width: 20 }}
                            />
                            Aryan Tanwar
                          </span>
                          <img
                            src="/assets/images/close.svg"
                            alt="a"
                            className="img-fluid"
                          />
                        </div>
                        <div className="d-flex">
                          <span>
                            <img
                              src="/assets/images/avatar.png"
                              alt="a"
                              className="img-fluid"
                              style={{ width: 20 }}
                            />
                            Aryan Tanwar
                          </span>
                          <img
                            src="/assets/images/close.svg"
                            alt="a"
                            className="img-fluid"
                          />
                        </div>
                        <div className="d-flex">
                          <span>
                            <img
                              src="/assets/images/avatar.png"
                              alt="a"
                              className="img-fluid"
                              style={{ width: 20 }}
                            />
                            Aryan Tanwar
                          </span>
                          <img
                            src="/assets/images/close.svg"
                            alt="a"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <h6>Marketing</h6>
                    <div className="form-group">
                      <label htmlFor="">TEAMMATES</label>
                      <input
                        type="text"
                        placeholder="Select Members"
                        className="form-control"
                      />
                      <img
                        src="/assets/images/plus.svg"
                        alt="+"
                        className="img-fluid pls"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      />
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <div className="d-flex">
                          <span>
                            <img
                              src="/assets/images/avatar.png"
                              alt="a"
                              className="img-fluid"
                              style={{ width: 20 }}
                            />
                            Aryan Tanwar
                          </span>
                          <img
                            src="/assets/images/close.svg"
                            alt="a"
                            className="img-fluid"
                          />
                        </div>
                        <div className="d-flex">
                          <span>
                            <img
                              src="/assets/images/avatar.png"
                              alt="a"
                              className="img-fluid"
                              style={{ width: 20 }}
                            />
                            Aryan Tanwar
                          </span>
                          <img
                            src="/assets/images/close.svg"
                            alt="a"
                            className="img-fluid"
                          />
                        </div>
                        <div className="d-flex">
                          <span>
                            <img
                              src="/assets/images/avatar.png"
                              alt="a"
                              className="img-fluid"
                              style={{ width: 20 }}
                            />
                            Aryan Tanwar
                          </span>
                          <img
                            src="/assets/images/close.svg"
                            alt="a"
                            className="img-fluid"
                          />
                        </div>
                        <div className="d-flex">
                          <span>
                            <img
                              src="/assets/images/avatar.png"
                              alt="a"
                              className="img-fluid"
                              style={{ width: 20 }}
                            />
                            Aryan Tanwar
                          </span>
                          <img
                            src="/assets/images/close.svg"
                            alt="a"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <h6>Design</h6>
                    <div className="form-group">
                      <label htmlFor="">TEAMMATES</label>
                      <input
                        type="text"
                        placeholder="Select Members"
                        className="form-control"
                      />
                      <img
                        src="/assets/images/plus.svg"
                        alt="+"
                        className="img-fluid pls"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      />
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <div className="d-flex">
                          <span>
                            <img
                              src="/assets/images/avatar.png"
                              alt="a"
                              className="img-fluid"
                              style={{ width: 20 }}
                            />
                            Aryan Tanwar
                          </span>
                          <img
                            src="/assets/images/close.svg"
                            alt="a"
                            className="img-fluid"
                          />
                        </div>
                        <div className="d-flex">
                          <span>
                            <img
                              src="/assets/images/avatar.png"
                              alt="a"
                              className="img-fluid"
                              style={{ width: 20 }}
                            />
                            Aryan Tanwar
                          </span>
                          <img
                            src="/assets/images/close.svg"
                            alt="a"
                            className="img-fluid"
                          />
                        </div>
                        <div className="d-flex">
                          <span>
                            <img
                              src="/assets/images/avatar.png"
                              alt="a"
                              className="img-fluid"
                              style={{ width: 20 }}
                            />
                            Aryan Tanwar
                          </span>
                          <img
                            src="/assets/images/close.svg"
                            alt="a"
                            className="img-fluid"
                          />
                        </div>
                        <div className="d-flex">
                          <span>
                            <img
                              src="/assets/images/avatar.png"
                              alt="a"
                              className="img-fluid"
                              style={{ width: 20 }}
                            />
                            Aryan Tanwar
                          </span>
                          <img
                            src="/assets/images/close.svg"
                            alt="a"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal-bttn">
                      <a href="#" data-dismiss="modal">
                        Cancel
                      </a>
                      <a href="#">Update</a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NewModal id="add" title="Create Plan">
          <div className="modal-body">
            <form onSubmit={addPlan}>
              {message}
              <div className="form-group">
                <label>Name</label>
                <input type="text" className="form-control" name="name" />
              </div>

              <div className="form-group d-flex justify-content-end">
                <button type="submit" className="btn btn-primary">
                  Create {loader}
                </button>
              </div>
            </form>
          </div>
        </NewModal>
        <NewModal id="addcategory" title="Create Category">
          <div className="modal-body">
            <form onSubmit={addCategory}>
              {message}
              <div className="form-group">
                <label>Name</label>
                <input type="text" className="form-control" name="name" />
              </div>

              <div className="form-group d-flex justify-content-end">
                <button type="submit" className="btn btn-primary">
                  Create {loader}
                </button>
              </div>
            </form>
          </div>
        </NewModal>

        <NewModal id="addPointer" title="Create Content">
          <div className="modal-body">
            <form onSubmit={addContent}>
              {message}
              <div className="form-group">
                <label>Name</label>
                <input type="text" className="form-control" name="name" />
              </div>

              <div className="form-group d-flex justify-content-end">
                <button type="submit" className="btn btn-primary">
                  Create {loader}
                </button>
              </div>
            </form>
          </div>
        </NewModal>
        <NewModal id="updatePlan" title="Update Plan">
          <div className="modal-body">
            <form onSubmit={updatePlan}>
              {message}
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  defaultValue={plan.name}
                />
              </div>

              <div className="form-group d-flex justify-content-end">
                <button type="submit" className="btn btn-primary">
                  Update {loader}
                </button>
              </div>
            </form>
          </div>
        </NewModal>
        <NewModal id="updateCategory" title="Update Category">
          <div className="modal-body">
            <form onSubmit={updateCategory}>
              {message}
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  defaultValue={category.name}
                />
              </div>

              <div className="form-group d-flex justify-content-end">
                <button type="submit" className="btn btn-primary">
                  Update {loader}
                </button>
              </div>
            </form>
          </div>
        </NewModal>
        <NewModal id="deletePlan" title="Delete Action Plan">
          <div className="modal-body">
            <form onSubmit={deletePlan}>
              {message}
              <div className="form-group">
                <label htmlFor="gb">
                  Are you sure you want to delete this Action Plan?
                </label>
              </div>
              <div className="form-group d-flex justify-content-end">
                <button type="submit" className="btn btn-danger">
                  Delete {loader}
                </button>
              </div>
            </form>
          </div>
        </NewModal>
        <NewModal id="deleteCategory" title="Delete Category">
          <div className="modal-body">
            <form onSubmit={deleteCategory}>
              {message}
              <div className="form-group">
                <label htmlFor="gb">
                  Are you sure you want to delete this Category?
                </label>
              </div>
              <div className="form-group d-flex justify-content-end">
                <button type="submit" className="btn btn-danger">
                  Delete {loader}
                </button>
              </div>
            </form>
          </div>
        </NewModal>
        {/* manage access modal */}
        <NewModal id="manage" title="Manage Access">
          <div className="modal-body">
            {message}
            <form onSubmit={addUserToPlan}>
              <div className="form-group">
                <label htmlFor="gb">Add user to this action plan</label>
                <select name="user" id="" className="form-control">
                  <option defaultValue="">Please choose the user</option>
                  {users.map((user) => (
                    <option key={user._id} value={user._id}>
                      {user.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group d-flex justify-content-end">
                <button type="submit" className="btn btn-dark">
                  Add {loader}
                </button>
              </div>
            </form>

            {/* users list */}

            <h4>Users</h4>
            {/* table  */}
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {planUsers.map((user) => (
                  <tr key={user._id}>
                    <td>{user.name}</td>
                    <td>
                      <button
                        className="btn btn-sm btn-rounded-circle btn-danger mx-2 pt-1"
                        onClick={() => removeUserFromPlan(user._id)}
                      >
                        <i className="fa fa-trash" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* end of table */}
          </div>
        </NewModal>
      </div>
    </MainLayout>
  );
};

export default ActionPlan;
