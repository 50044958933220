import React from "react";
import Sidebar from "../../../components/Sidebar";
import MainLayout from "../../../layout/MainLayout";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { server, config } from "../../../env";
import { useEffect, useState } from "react";
import Select from "react-select";
import Modal from "../../../components/Modal";

const NewTask = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [goals, setGoals] = useState([]);
  const [images, setImages] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [checklist, setChecklist] = useState([]);

  useEffect(() => {
    readGoals();
    readProject();
  }, []);

  const readGoals = async () => {
    await axios
      .get(`${server}/goal/getAll/${id}`, config)
      .then((res) => {
        setGoals(res.data.goals);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createTask = async (e) => {
    e.preventDefault();

    const params = new FormData();
    const teammates = [];
    if (selectedOption) {
      for (let i = 0; i < selectedOption.length; i++) {
        teammates.push(selectedOption[i].value);
      }
    }
    params.append("teammates", teammates);
    params.append("name", e.target.name.value);
    params.append("description", e.target.description.value);
    params.append("goal", e.target.goal.value);
    params.append("project", id);
    params.append("hypothesis", e.target.hypothesis.value);
    params.append("startDate", e.target.startDate.value);
    params.append("endDate", e.target.endDate.value);
    params.append("lever", e.target.lever.value);
    for (let i = 0; i < e.target.images.files.length; i++) {
      params.append("pic", e.target.images.files[i]);
    }
    params.append("checklist", JSON.stringify(checklist));

    await axios
      .post(`${server}/task/create`, params, config)
      .then((res) => {
        navigate(`/projects/${id}/tasks`);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const readProject = async () => {
    await axios
      .get(`${server}/project/readOne/${id}`, config)
      .then((res) => {
        const projectUsers = res.data.users;
        const options = [];
        projectUsers.forEach((user) => {
          options.push({ value: user._id, label: user.name });
        });
        setOptions(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <MainLayout>
      <section className="mainContent">
        <div className="row">
          <Sidebar />
          <div className="goalNameSection UntitledIdea pt-4 ps-5">
            <div className="row px-3 pt-4">
              <p className="p-0 mb-3 text-muted">
                Project &gt; Idea
                <span className="text-dark"> &gt; Untitled Task </span>
              </p>
              <form onSubmit={createTask}>
                <div className="col-lg-11 ps-0 mb-3">
                  <input
                    type="text"
                    className="form-control text-dark fw-semibold py-2 fs-3"
                    placeholder="Add a title"
                    name="name"
                    required
                  />
                </div>
                <div className="form-floating col-lg-11 ps-0 mb-3">
                  <select
                    className="form-select selectOpt"
                    id="floatingSelect"
                    aria-label="Floating label select example"
                    name="lever"
                  >
                    <option selected value="">
                      Select Lever
                    </option>
                    <option value="Acquisition">Acquisition</option>
                    <option value="Activation">Activation</option>
                    <option value="Retention">Retention</option>
                  </select>
                  <label htmlFor="floatingSelect" className="pt-3">
                    LEVER
                  </label>
                </div>
                <div className="form-floating col-lg-11 ps-0 mb-3">
                  <select
                    className="form-select selectOpt"
                    id="floatingSelect"
                    aria-label="Floating label select example"
                    name="goal"
                  >
                    <option selected value="">
                      Select Goal
                    </option>
                    {goals.map((goal) => {
                      return (
                        <option key={goal._id} value={goal._id}>
                          {goal.name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="floatingSelect" className="pt-3">
                    GOAL
                  </label>
                </div>
                <div className="form-floating col-lg-11 ps-0 mb-3">
                  <select
                    className="form-select selectOpt"
                    id="floatingSelect"
                    aria-label="Floating label select example"
                  >
                    <option selected="">Select Key Result</option>
                    <option value={1}>One</option>
                    <option value={2}>Two</option>
                    <option value={3}>Three</option>
                  </select>
                  <label htmlFor="floatingSelect" className="pt-3">
                    KEY RESULT
                  </label>
                </div>

                <div className="form-floating form-floating-custom col-lg-11 ps-0 mb-3 position-relative">
                  <input
                    type="text"
                    className="form-control bg-transparent"
                    id="floatingInputValue"
                    disabled
                  />
                  <label htmlFor="floatingInputValue">TEAMMATES</label>
                  {/* <input type="file" name="myfile" class="fileField"/> */}
                  <div className="p-0 m-0 position-absolute d-flex gap-1 teamMatesBadge">
                    {selectedOption?.map((option) => (
                      <p className="m-0 p-0 border-0 rounded-1 px-2">
                        <img
                          src="/assets/account.png"
                          alt=""
                          className="avatar"
                        />
                        &nbsp; {option.label}
                      </p>
                    ))}
                  </div>
                  <i
                    className="fa-solid fa-plus bg-dark bg-opacity-50 p-1 rounded-1 plusIcon"
                    data-bs-toggle="modal"
                    data-bs-target="#addTeamMateModal"
                  />
                </div>
                <div className="row col-lg-11 g-2 p-0 m-0 mb-3">
                  <div className="col-md ps-0 pe-2">
                    <div className="form-floating form-floating-custom">
                      <input
                        type="date"
                        className="form-control"
                        id="floatingInputGrid"
                        placeholder="START DATE"
                        name="startDate"
                      />
                      <label htmlFor="floatingInputGrid">Select Date</label>
                      {/* <i class="fa-solid fa-calendar-days text-muted dateIcon"></i> */}
                    </div>
                  </div>
                  <div className="col-md ps-0 pe-3">
                    <div className="form-floating form-floating-custom">
                      <input
                        type="date"
                        className="form-control"
                        id="floatingInputGrid"
                        placeholder="END DATE"
                        name="endDate"
                      />
                      <label htmlFor="floatingInputGrid">Select Date</label>
                      {/* <i class="fa-solid fa-calendar-days text-muted dateIcon"></i> */}
                    </div>
                  </div>
                </div>
                <div className="form-floating col-lg-11 ps-0 mb-3">
                  <textarea
                    className="form-control modelTextarea"
                    placeholder="Enter a description"
                    id="floatingTextarea2"
                    name="description"
                  />
                  <label htmlFor="floatingTextarea2" className="pb-2">
                    DESCRIPTION
                  </label>
                </div>
                <div className="form-floating col-lg-11 ps-0 mb-3">
                  <textarea
                    className="form-control modelTextarea"
                    placeholder="Describe your goal"
                    id="floatingTextarea2"
                    name="hypothesis"
                  />
                  <label htmlFor="floatingTextarea2" className="pb-2">
                    HYPOTHESIS
                  </label>
                </div>
                <div className="col-lg-11 m-0 ps-0 mb-3">
                  <div className="p-0 pt-2 pb-4 px-3 m-0 border rounded-1 checklist">
                    <p className="p-0 m-0 mb-3 checklist-label">CHECKLIST</p>
                    {checklist.map((list) => (
                      <div className="form-check ">
                        <input
                          className="form-check-input mt-2 border border-2 border-secondary"
                          type="checkbox"
                          id="cancelBtn"
                          checked={list.status}
                          onChange={() => {
                            checklist[
                              checklist.indexOf(list)
                            ].status = !list.status;
                            setChecklist([...checklist]);
                          }}
                        />

                        <label
                          className="form-check-label"
                          htmlFor="flexCheckChecked"
                          id="textTocancel"
                        >
                          {list.name}
                        </label>
                        <i
                          className="ms-3 fa-solid fa-trash-alt text-danger right-0 top-0 mt-2 mr-2"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            checklist.splice(checklist.indexOf(list), 1);
                            setChecklist([...checklist]);
                          }}
                        />
                      </div>
                    ))}
                    <div className="p-0 m-0 w-25 pe-5 mt-4">
                      <span className="p-0 py-2 px-3 m-0 bg-secondary bg-opacity-25 rounded-1">
                        Add a subtask{" "}
                        <i
                          className="fa-solid fa-plus bg-secondary bg-opacity-75 text-white p-1 ms-2 rounded-1"
                          data-bs-toggle="modal"
                          data-bs-target="#addSubTaskModal"
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-11 p-0 pt-2 pb-4 px-3 m-0 mt-2 mb-4 border dashed">
                  <p className="p-0 m-0 mb-3 text-muted">FILES</p>
                  <div className="p-0 m-0 d-flex gap-2">
                    {images.map((image, index) => (
                      <div className="p-0 m-0 position-relative">
                        <img
                          src={URL.createObjectURL(image)}
                          className="img-thumbnail"
                          alt="thumbnail"
                          style={{
                            width: "100px",
                          }}
                        />
                        <img
                          src="/assets/whiteDeleteIcon.svg"
                          className="position-absolute openDialogIcon"
                          alt="delete"
                          onClick={() => {
                            setImages(images.filter((_, i) => i !== index));
                          }}
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    ))}

                    <div className="m-0 position-relative uploadBox">
                      <div className="p-0 m-0 text-center">
                        <img src="/assets/uploadIcon.svg" />
                        <p className="p-0 m-0 mt-2">Upload</p>
                        <input
                          type="file"
                          className="fileField"
                          name="images"
                          multiple
                          onChange={(e) => {
                            var files = e.target.files;
                            var previewData = [];
                            for (let i = 0; i < files.length; i++) {
                              previewData.push(
                                Object.assign(files[i], {
                                  preview: URL.createObjectURL(files[i]),
                                })
                              );
                            }
                            setImages(previewData);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-11 p-0 m-0 mt-1 mb-3">
                  <button
                    type="submit"
                    className="btn btn-primary border-1 rounded-1 px-5 py-2 me-2"
                  >
                    Create
                  </button>
                  <button className="btn btn-outline-danger rounded-1 px-5 py-2">
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Modal id="addTeamMateModal" title="Manage Teammates">
        <div className="modal-body">
          <Select
            defaultValue={selectedOption}
            onChange={setSelectedOption}
            options={options}
            isMulti={true}
          />
        </div>
      </Modal>
      <Modal id="addSubTaskModal" title="Add subtask modal">
        <div className="modal-body">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setChecklist(
                checklist.concat([
                  {
                    name: e.target.checklist.value,
                    status: false,
                  },
                ])
              );
            }}
          >
            <div className="form-group">
              <label htmlFor="subTaskName">Subtask Name</label>
              <input
                type="text"
                className="form-control"
                id="subTaskName"
                placeholder="Enter subtask name"
                name="checklist"
              />
            </div>
            <div className="d-flex justify-content-end">
              <button className="btn btn-primary mt-3" type="submit">
                Add
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </MainLayout>
  );
};

export default NewTask;
