import MainLayout from "../../layout/MainLayout";
import SettingSidebar from "../../components/SettingSidebar";
import { useState, useEffect } from "react";
import { server, config } from "../../env";
import axios from "axios";
import Alert from "../../components/Alert";

const GeneralDetails = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const edit = async (e) => {
    e.preventDefault();

    const data = {
      name,
      email,
      phone,
    };

    await axios
      .patch(server + "/auth/update", data, config)
      .then((res) => {
        setMessage(<Alert className="success" message={res.data.message} />);
      })
      .catch((err) => {
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  const checkUserLogin = async () => {
    await axios
      .get(server + "/auth/access", config)
      .then((rsp) => {
        setName(rsp.data.name);
        setEmail(rsp.data.email);
        setPhone(rsp.data?.phone);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    checkUserLogin();
  }, []);

  return (
    <MainLayout>
      <section className="mainContent">
        <div className="row">
          <SettingSidebar />

          <div className="projectNameSection userManagementSection pt-5 ps-5">
            <div className="row mb-5 mt-1">
              <div className="p-0 headingSide col-md-6 col-sm-12">
                <h1 className="ps-0">General Details</h1>
                <p className="p-0 m-0 text-muted">
                  Update your photo and personal details here
                </p>
                {message}
              </div>

              <div className="p-0 buttonsSide col-md-6 col-sm-12 d-flex justify-content-end align-items-center">
                <button
                  className="btn btn-primary btn-lg"
                  onClick={(e) => {
                    edit(e);
                  }}
                >
                  Save
                </button>
              </div>
            </div>
            <secton className="model-section">
              <div className="row align-items-start gap-3 mb-5">
                <div className="col-lg-7 p-0 bg-white">
                  <h5 className="p-0 p-1 ps-2">Personal Information</h5>
                  <hr className="border-secondary border-2 opacity-25 p-0 m-0 mb-3" />
                  <div className="p-0 px-3 m-0 w-100">
                    <div className="row g-2">
                      <div className="col-md">
                        <div className="form-floating form-floating-custom  ps-0 mb-3">
                          <input
                            type="text"
                            className="form-control border bg-light "
                            placeholder="Enter first name"
                            id="floatingInputValue"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                          <label
                            htmlFor="floatingSelect"
                            className="pt-3 text-dark fw-semibold"
                          >
                            Name
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-floating settingsInput form-floating-custom ps-0 mb-3">
                      <input
                        type="email"
                        className="form-control border border-transparent bg-light "
                        placeholder="Enter valid email address"
                        id="floatingInputValue"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <label
                        htmlFor="floatingSelect"
                        className="pt-3 text-dark fw-semibold"
                      >
                        Email Address
                      </label>
                    </div>
                    <div className="form-floating settingsInput form-floating-custom ps-0 mb-3">
                      <input
                        type="text"
                        className="form-control border border-transparent bg-light "
                        placeholder="Your 10 digit phone number"
                        id="floatingInputValue"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                      <label
                        htmlFor="floatingSelect"
                        className="pt-3 text-dark fw-semibold"
                      >
                        Phone Number
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </secton>
          </div>
        </div>
      </section>
    </MainLayout>
  );
};

export default GeneralDetails;
