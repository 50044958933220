import React from "react";
import Sidebar from "../../../components/Sidebar";
import MainLayout from "../../../layout/MainLayout";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { server, config } from "../../../env";
import { useEffect, useState } from "react";
import Loader from "../../../components/Loader";
import Alert from "../../../components/Alert";
import { ReactSortable } from "react-sortablejs";

const Tasks = () => {
  const { id } = useParams();
  const [projectName, setProjectName] = useState("");
  const [One, setOne] = useState([]);
  const [Two, setTwo] = useState([]);
  const [Three, setThree] = useState([]);

  useEffect(() => {
    const readName = () => {
      axios
        .get(`${server}/project/readName/${id}`, config)
        .then((res) => {
          setProjectName(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    readName();
    readTasks();
  }, []);

  const readTasks = () => {
    axios
      .get(`${server}/task/readAll/${id}`, config)
      .then((res) => {
        // filter tasks by status
        setOne(res.data.tasks.filter((task) => task.status === "Up Next"));
        setTwo(res.data.tasks.filter((task) => task.status === "In Progress"));
        setThree(res.data.tasks.filter((task) => task.status === "Done"));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // update status of task
  const updateStatus = (status, taskId) => {
    axios
      .put(`${server}/task/status/${taskId}`, { status }, config)
      .then((res) => {
        readTasks();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // delete task
  const deleteTask = async (taskId) => {
    try {
      await axios.delete(`${server}/task/delete/${taskId}`, config);
      readTasks();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <MainLayout>
      <div className="mainContent">
        <div className="row">
          <Sidebar />
          <div className="goalNameSection projectNameSection pt-4 ps-5">
            {/* Heading Section Start */}
            <div className="row mb-5 mt-1">
              <div className="p-0 headingSide col-md-6 col-sm-12">
                <Link to="/projects">
                  <p className="ps-0 mb-1 backBtn">
                    <i className="fa-solid fa-arrow-left-long" />
                    &nbsp; All Projects
                  </p>
                </Link>

                <h1 className="ps-0">{projectName}</h1>
              </div>
              <div className="p-0 buttonsSide col-md-6 col-sm-12 d-flex justify-content-end align-items-center">
                <Link
                  to={`/projects/${id}/tasks/new`}
                  className="btn btn-primary me-4 rounded-1 px-4 py-2"
                >
                  <i className="fa-solid fa-plus bg-light bg-opacity-25 p-1 rounded-1 newModel" />{" "}
                  Add Task
                </Link>
              </div>
            </div>
            {/* Heading Section End */}
            {/* Tasks Section Start */}
            <div className="row">
              <div className="col ps-0 mb-3">
                <span className="p-0 m-0 fw-semibold fs-5">Up Next</span>
                <span className="px-2 m-0 ms-2 border border-secondary rounded-4 border-opacity-75 text-muted">
                  {One.length}
                </span>
                <hr className="text-secondary border-2 opacity-75 mt-2" />
                {/* Task Box Start */}
                <ReactSortable
                  list={One}
                  setList={setOne}
                  animation={150}
                  delayOnTouchStart={true}
                  delay={0}
                  delayOnTouchMove={true}
                  delayOnTouchEnd={true}
                  delayOnMouseMove={true}
                  delayOnMouseDown={true}
                  group="kanban"
                  onAdd={(evt) => {
                    updateStatus("Up Next", evt.item.dataset.id);
                  }}
                >
                  {One.map((task) => (
                    <div
                      className="px-3 py-2 m-0 mb-2 border border-secondary border-opacity-50 rounded-3 d-flex justify-content-between bg-white "
                      style={{
                        cursor: "grab",
                      }}
                      key={task._id}
                    >
                      <div className="p-0 m-0 w-75">
                        {task.lever === "Acquisition" ? (
                          <span className="px-1 m-0 bg-warning text-warning bg-opacity-25 rounded-1">
                            {task.lever}
                          </span>
                        ) : task.lever === "Activation" ? (
                          <span className="px-1 m-0 bg-primary text-primary bg-opacity-25 rounded-1">
                            {task.lever}
                          </span>
                        ) : (
                          <span className="px-1 m-0 bg-danger text-danger bg-opacity-25 rounded-1">
                            {task.lever}
                          </span>
                        )}
                        <p className="p-0 m-0 mt-2 mb-2">{task.name}</p>
                        <p className="p-0 m-0 mb-2">
                          <img src="/assets/account.png" className="avatar" />
                          &nbsp; {task.owner?.name}
                        </p>
                      </div>
                      <div className="p-0 m-0 w-25 d-flex flex-column align-items-end">
                        <div className="dropdown">
                          <span
                            className="p-0 m-0"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="fa-solid fa-ellipsis" />
                          </span>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <Link
                                className="dropdown-item"
                                to={`/task/${task._id}`}
                              >
                                View
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={`/task/${task._id}/edit`}
                                className="dropdown-item"
                              >
                                Edit
                              </Link>
                            </li>
                            <li>
                              <Link
                                to=""
                                className="dropdown-item text-danger"
                                onClick={() => {
                                  deleteTask(task._id);
                                }}
                              >
                                Delete
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <p className="p-0 m-0 mt-3">
                          {new Date(task.createdAt).toLocaleDateString()}
                        </p>
                      </div>
                    </div>
                  ))}
                </ReactSortable>

                {/* Task Box End */}
              </div>
              {/* First Column End */}
              {/* Second Column Starting */}
              <div className="col mb-3">
                <span className="p-0 m-0 fw-semibold fs-5">In-Progress</span>
                <span className="px-2 m-0 ms-2 border border-secondary rounded-4 border-opacity-75 text-muted">
                  {Two.length}
                </span>
                <hr className="text-warning border-2 opacity-100 mt-2" />

                <ReactSortable
                  list={Two}
                  setList={setTwo}
                  animation={150}
                  delayOnTouchStart={true}
                  delay={0}
                  delayOnTouchMove={true}
                  delayOnTouchEnd={true}
                  delayOnMouseMove={true}
                  delayOnMouseDown={true}
                  group="kanban"
                  onAdd={(evt) => {
                    updateStatus("In Progress", evt.item.dataset.id);
                  }}
                >
                  {Two.map((item) => (
                    <div
                      className="px-3 py-2 m-0 mb-2 border border-secondary border-opacity-50 rounded-3 d-flex justify-content-between"
                      key={item._id}
                      style={{
                        cursor: "grab",
                      }}
                    >
                      <div className="p-0 m-0 w-75">
                        {item.lever === "Acquisition" ? (
                          <span className="px-1 m-0 bg-warning text-warning bg-opacity-25 rounded-1">
                            {item.lever}
                          </span>
                        ) : item.lever === "Activation" ? (
                          <span className="px-1 m-0 bg-primary text-primary bg-opacity-25 rounded-1">
                            {item.lever}
                          </span>
                        ) : (
                          <span className="px-1 m-0 bg-danger text-danger bg-opacity-25 rounded-1">
                            {item.lever}
                          </span>
                        )}
                        <p className="p-0 m-0 mt-2 mb-2">{item.name}</p>
                        <p className="p-0 m-0 mb-2">
                          <img src="/assets/account.png" className="avatar" />
                          &nbsp; {item.owner?.name}
                        </p>
                      </div>
                      <div className="p-0 m-0 w-25 d-flex flex-column align-items-end">
                        <div className="dropdown">
                          <span
                            className="p-0 m-0"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="fa-solid fa-ellipsis" />
                          </span>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <Link
                                className="dropdown-item"
                                to={`/task/${item._id}`}
                              >
                                View
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={`/task/${item._id}/edit`}
                                className="dropdown-item"
                              >
                                Edit
                              </Link>
                            </li>
                            <li>
                              <Link
                                to=""
                                className="dropdown-item text-danger"
                                onClick={() => {
                                  deleteTask(item._id);
                                }}
                              >
                                Delete
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <p className="p-0 m-0 mt-3">
                          {new Date(item.createdAt).toLocaleDateString()}
                        </p>
                      </div>
                    </div>
                  ))}
                </ReactSortable>
              </div>
              <div className="col mb-3">
                <span className="p-0 m-0 fw-semibold fs-5">
                  Ready To Analyze
                </span>
                <span className="px-2 m-0 ms-2 border border-secondary rounded-4 border-opacity-75 text-muted">
                  {Three.length}
                </span>
                <hr className="text-success border-2 opacity-100 mt-2" />
                <ReactSortable
                  list={Three}
                  setList={setThree}
                  animation={150}
                  delayOnTouchStart={true}
                  delay={0}
                  delayOnTouchMove={true}
                  delayOnTouchEnd={true}
                  delayOnMouseMove={true}
                  delayOnMouseDown={true}
                  group="kanban"
                  onAdd={(evt) => {
                    updateStatus("Done", evt.item.dataset.id);
                  }}
                >
                  {Three.map((task) => (
                    <div
                      className="px-3 py-2 m-0 mb-2 border border-secondary border-opacity-50 rounded-3 d-flex justify-content-between"
                      key={task._id}
                    >
                      <div className="p-0 m-0 w-75">
                        {task.lever === "Acquisition" ? (
                          <span className="px-1 m-0 bg-warning text-warning bg-opacity-25 rounded-1">
                            {task.lever}
                          </span>
                        ) : task.lever === "Activation" ? (
                          <span className="px-1 m-0 bg-primary text-primary bg-opacity-25 rounded-1">
                            {task.lever}
                          </span>
                        ) : (
                          <span className="px-1 m-0 bg-danger text-danger bg-opacity-25 rounded-1">
                            {task.lever}
                          </span>
                        )}
                        <p className="p-0 m-0 mt-2 mb-2">{task.name}</p>
                        <p className="p-0 m-0 mb-2">
                          <img src="/assets/account.png" className="avatar" />
                          &nbsp; {task.owner?.name}
                        </p>
                      </div>
                      <div className="p-0 m-0 w-25 d-flex flex-column align-items-end">
                        <div className="dropdown">
                          <span
                            className="p-0 m-0"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="fa-solid fa-ellipsis" />
                          </span>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <Link
                                className="dropdown-item"
                                to={`/task/${task._id}`}
                              >
                                View
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={`/task/${task._id}/edit`}
                                className="dropdown-item"
                              >
                                Edit
                              </Link>
                            </li>
                            <li>
                              <Link
                                to=""
                                className="dropdown-item text-danger"
                                onClick={() => {
                                  deleteTask(task._id);
                                }}
                              >
                                Delete
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <p className="p-0 m-0 mt-3">
                          {new Date(task.createdAt).toLocaleDateString()}
                        </p>
                      </div>
                    </div>
                  ))}
                </ReactSortable>
              </div>
            </div>
            {/* Tasks Section End */}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Tasks;
