import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import { server, config } from "../env";
import Avatar from "react-avatar";
const activeStyle = { borderBottom: "2px solid blue" };

const Navbar = () => {
  const [name, setName] = useState("");

  useEffect(() => {
    axios
      .get(`${server}/auth/access`, config)
      .then((res) => {
        setName(res.data.name);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="row ">
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid px-5 py-2">
          <Link className="navbar-brand" to="/">
            <img src="/assets/logo.png" alt="logo" />
            <span className="navbar-brand brandName mb-0 h1" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {/* Left Menu Start */}
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 ms-4">
              <li className="nav-item mx-3">
                <NavLink
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                  className="nav-link"
                  aria-current="page"
                  to="/"
                >
                  Dashboard
                </NavLink>
              </li>
              <li className="nav-item mx-3">
                <NavLink
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                  className="nav-link"
                  to="/projects"
                >
                  Project
                </NavLink>
              </li>
              {/* <li className="nav-item mx-3">
                <NavLink
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                  className="nav-link"
                  to="/workload"
                >
                  Workload
                </NavLink>
              </li>
              <li className="nav-item mx-3">
                <NavLink
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                  className="nav-link"
                  to="/chat"
                >
                  Chat
                </NavLink>
              </li> */}
              <li className="nav-item mx-3">
                <NavLink
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                  className="nav-link"
                  to="/tools/models"
                >
                  Models
                </NavLink>
              </li>
            </ul>
            {/* Left Menu End */}
            {/* Right Menu Start */}
            <ul className="navbar-nav mb-2 mb-lg-0 rightUl">
              <li className="nav-item mx-2">
                <NavLink
                  className="nav-link"
                  aria-current="page"
                  to="/actionplans"
                >
                  <img src="/assets/book.png" alt="Book" />
                </NavLink>
              </li>
              <li className="nav-item mx-3">
                <Link
                  className="nav-link"
                  aria-current="page"
                  to="/settings/general-details"
                >
                  <img src="/assets/Settings.png" alt="Settings" />
                </Link>
              </li>
              <li className="nav-item mx-2">
                <a className="nav-link" aria-current="page" href="#">
                  <Avatar name={name} round size="30" />
                </a>
              </li>
            </ul>
            {/* Right Menu End */}
          </div>
        </div>
      </nav>
      <hr className="border-secondary border-2 opacity-25" />
    </div>
  );
};

export default Navbar;
