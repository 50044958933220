import MainLayout from "../../layout/MainLayout";
import SettingSidebar from "../../components/SettingSidebar";
import { useState, useEffect } from "react";

const Workspace = () => {
  const [currency, setCurrency] = useState(
    localStorage.getItem("currency") || "₹"
  );

  useEffect(() => {
    localStorage.setItem("currency", currency);
  }, [currency, setCurrency]);

  return (
    <MainLayout>
      <section className="mainContent">
        <div className="row">
          <SettingSidebar />

          <div className="projectNameSection userManagementSection pt-5 ps-5">
            {/* Heading Section Start */}
            <div className="row mb-5 mt-1">
              <div className="p-0 headingSide col-sm-12">
                <h1 className="ps-0">Workspace</h1>
                <p className="p-0 m-0 text-muted">Customize your workspace</p>
              </div>
            </div>
            {/* Heading Section End */}
            {/* Dropdown Section Start */}
            <secton className="model-section">
              <div className="container">
                <div className="row mb-5">
                  <div className="col-lg-12 py-2 px-3 d-flex justify-content-between align-items-center m-0 bg-white">
                    <div className="p-0 m-0">
                      <p className="p-0 m-0 fs-5">Currency</p>
                      <p className="p-0 m-0 text-muted">Select a currency</p>
                    </div>
                    <div className="p-0 m-0">
                      <select
                        className="form-select border-1 border-dark bg-light rounded-1 fw-semibold"
                        id="floatingSelect"
                        aria-label="Floating label select example"
                        value={currency}
                        onChange={(e) => setCurrency(e.target.value)}
                      >
                        <option value="$">USD($)</option>
                        <option value="₹">INR(₹)</option>
                        <option value="£">GBP(£)</option>
                        <option value="€">Euro(€)</option>
                        <option value="¥">JPY(¥)</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </secton>
            {/* Dropdown Section End */}
          </div>
        </div>
      </section>
    </MainLayout>
  );
};

export default Workspace;
