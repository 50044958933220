const Cookies = require("js-cookie");
var hostname = window.location.href;

module.exports = {
  server: hostname.includes("localhost")
    ? "http://localhost:8000"
    : "https://api.scalezmedia.com",

  // server: "http://api.scalezmedia.com",

  config: {
    headers: {
      Authorization: "Bearer " + Cookies.get("footprint"),
    },
  },

  // recaptcha keys
  //   RECAPTCHA_KEY: "6LflHO8cAAAAACke9WElixl6di6nEUL9pQCcxws-",
  checkAccess: (err) => {
    if (err.response) {
      if (err.response.status === 401) {
        Cookies.remove("footprint");
        window.location.href = "/";
      }
    }
    return true;
  },
};
