import React, { useEffect, useState } from "react";
import MainLayout from "../../layout/MainLayout";
import axios from "axios";
import { server, config, checkAccess } from "../../env";

const Dashboard = () => {
  const [data, setData] = useState({});
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    const readDashboard = async () => {
      await axios
        .get(server + "/dashboard/read", config)
        .then((rsp) => {
          setData(rsp.data);
          setTasks(rsp.data.tasks);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    readDashboard();
  }, []);

  return (
    <MainLayout>
      <section class="welcome-heading">
        <div class="container">
          <div class="row">
            <h1 class="ps-4">Welcome {data.name} </h1>
            <p class="ps-4">{data.projects?.length} Projects</p>
          </div>
        </div>
      </section>

      <section className="tasks-section">
        <div className="container">
          <div className="row ps-4 pe-4">
            {/* Your Tasks Start */}
            <div className="col-lg-6 ps-0 pe-5 tasks">
              <div className="header w-100 d-flex justify-content-between align-items-center mb-3">
                <h3>Your Tasks ({tasks.length})</h3>
                <a href="#" className="text-decoration-none text-reset">
                  <i className="fa-solid fa-angle-right fs-4" />
                </a>
              </div>
              <div className="content">
                {/* Table Starting */}
                <table className="table table-striped table-borderless p-2">
                  <thead>
                    <tr>
                      <td className="text-secondary">Name</td>
                      <td className="text-secondary">Due date</td>
                      <td className="text-secondary">Project</td>
                      <td className="text-secondary">Lever</td>
                    </tr>
                  </thead>
                  <tbody className="border">
                    {tasks.map((task, index) => (
                      <tr key={index}>
                        <td>{task.name}</td>
                        <td>{new Date(task.endDate).toLocaleDateString()}</td>
                        <td>{task.project?.name}</td>
                        <td>
                          {task.lever === "Activation" ? (
                            <span className="text-primary px-1 bg-primary bg-opacity-25 rounded-1">
                              {task.lever}
                            </span>
                          ) : task.lever === "Acquisition" ? (
                            <span className="text-warning px-1 bg-warning bg-opacity-25 rounded-1">
                              {task.lever}
                            </span>
                          ) : task.lever === "Revenue" ? (
                            <span className="text-success px-1 bg-success bg-opacity-25 rounded-1">
                              {task.lever}
                            </span>
                          ) : task.lever === "Referral" ? (
                            <span className="text-white px-1 bg-dark bg-opacity-25 rounded-1">
                              {task.lever}
                            </span>
                          ) : task.lever === "Retention" ? (
                            <span className="text-primary px-1 bg-primary bg-opacity-25 rounded-1">
                              {task.lever}
                            </span>
                          ) : (
                            <span className="text-primary px-1 bg-primary bg-opacity-25 rounded-1">
                              {task.lever}
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* Table Ending */}
              </div>
            </div>
            {/* Your Tasks End */}
            {/* Your Projects Start */}
            <div className="col-lg-6 ps-5 pe-0 projects">
              <div className="header w-100 d-flex justify-content-between align-items-center mb-3">
                <h3>Your Projects ({data?.projects?.length})</h3>
                <a href="#" className="text-decoration-none text-reset">
                  <i className="fa-solid fa-angle-right fs-4" />
                </a>
              </div>
              <div className="content">
                {/* Table Starting */}
                <table className="table table-striped table-borderless p-2">
                  <thead>
                    <tr>
                      <td className="text-secondary">Name</td>
                      <td className="text-secondary">Created</td>
                      <td className="text-secondary">Your tasks</td>
                      <td className="text-secondary">Status</td>
                    </tr>
                  </thead>
                  <tbody className="border">
                    {data?.projects?.map((project, index) => (
                      <tr key={index}>
                        <td>{project.name}</td>
                        <td>
                          {new Date(project.createdAt).toLocaleDateString()}
                        </td>
                        <td>{project.tasks}</td>
                        <td>
                          {project.status ? (
                            <span className="text-primary p-1 bg-primary bg-opacity-25 rounded-1">
                              Active
                            </span>
                          ) : (
                            <span className="text-danger p-1 bg-danger bg-opacity-25 rounded-1">
                              Not Active
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* Table Ending */}
              </div>
            </div>
            {/* Right Projects End */}
          </div>
        </div>
      </section>
    </MainLayout>
  );
};

export default Dashboard;
