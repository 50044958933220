import React from "react";
import Sidebar from "../../../components/Sidebar";
import MainLayout from "../../../layout/MainLayout";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { server, config } from "../../../env";
import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import Alert from "../../../components/Alert";

const Learning = () => {
  const [learnings, setLearnings] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [favouriteChecked, setFavouriteChecked] = useState(false);
  const [userId, setUserId] = useState("");
  const [sort, setSort] = useState("desc");
  const [learning, setLearning] = useState({});
  const [message, setMessage] = useState("");

  const { id } = useParams();

  const getLearnings = async () => {
    var url;

    if (!favouriteChecked) {
      url = `${server}/learning/readAll/${id}?sort=${sort}`;
    } else {
      url = `${server}/learning/readAll/${id}?sort=${sort}&favourite=true`;
    }

    await axios
      .get(url, config)
      .then((res) => {
        setUserId(res.data.user);
        setLearnings(res.data.learnings);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getLearnings();
  }, [favouriteChecked, setFavouriteChecked, sort, setSort]);

  useEffect(() => {
    const readName = () => {
      axios
        .get(`${server}/project/readName/${id}`, config)
        .then((res) => {
          setProjectName(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    readName();
  }, []);

  const handleFavourite = async (id) => {
    await axios
      .post(server + "/learning/addFavourite/" + id, {}, config)
      .then((res) => {
        console.log(res);
        getLearnings();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteLearning = async (id) => {
    await axios
      .delete(server + "/learning/delete/" + id, config)
      .then((res) => {
        console.log(res);
        getLearnings();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const move = async () => {
    await axios
      .post(server + "/learning/move/" + learning._id, {}, config)
      .then((res) => {
        setMessage(<Alert className="success" message={res.data.message} />);
        getLearnings();
      })
      .catch((err) => {
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
        console.log(err);
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  return (
    <MainLayout>
      <section className="mainContent">
        <div className="row">
          <Sidebar />
          <div className="goalNameSection pt-4 px-5">
            {/* Heading Section Start */}
            <div className="row mb-5 mt-1">
              <div className="p-0 headingSide col-md-6 col-sm-12">
                <Link to="/projects">
                  <p className="ps-0 mb-1 backBtn">
                    <i className="fa-solid fa-arrow-left-long" />
                    &nbsp; All Projects
                  </p>
                </Link>
                <h1 className="ps-0">{projectName}</h1>
                <p className="p-0 m-0 mb-4 text-muted">
                  {learnings.length} Learnings
                </p>
                <div
                  className={
                    favouriteChecked
                      ? "border border-warning border-1 p-1 text-warning fs-5"
                      : "border border-1 p-1 text-muted fs-5"
                  }
                  style={{
                    width: "180px",
                  }}
                >
                  <span className="p-0 m-0 me-2">Favourites only</span>
                  <input
                    className="form-check-input "
                    type="checkbox"
                    id="favouriteBtn"
                    checked={favouriteChecked}
                    onChange={() => {
                      setFavouriteChecked(!favouriteChecked);
                    }}
                  />
                </div>
              </div>
              <div className="p-0 buttonsSide col-md-6 col-sm-12 d-flex flex-column justify-content-end align-items-end">
                <div className="p-0 d-flex justify-content-start mb-2 sortTag">
                  {/* <div className="py-2 px-3 me-4 border border-primary rounded-1">
                    <span className="p-0 m-0 text-primary me-2">
                      Highest Score
                    </span>
                    <span className="p-0 m-0">
                      <img src="/assets/darkCrossIcon.svg" />
                    </span>
                  </div> */}
                </div>
                <div className="p-0 d-flex justify-content-end gap-3 w-100">
                  <div className="col-lg-4 p-0 m-0">
                    <select
                      className="form-select py-2"
                      aria-label="Default select example"
                      value={sort}
                      onChange={(e) => {
                        setSort(e.target.value);
                      }}
                    >
                      <option value="updated">Recently Updated</option>
                      <option value="desc">Newest Learning</option>
                      <option value="asc">Oldest Learning</option>
                      <option value="highest">Highest Score</option>
                    </select>
                  </div>
                  <button className="btn btn-outline-secondary me-4 rounded-1 py-2 px-3">
                    <i className="fa-solid fa-arrow-up-from-bracket rounded-1" />
                    &nbsp;&nbsp; Export
                  </button>
                </div>
              </div>
            </div>
            {/* Heading Section End */}
            <div className="row">
              {learnings.map((learning) => (
                <div className="col-lg-5 pt-3 pb-0 px-0 m-0 me-3 mb-3 border border-secondary border-opacity-50 rounded-3 h-100">
                  {/* Card Header */}
                  <div className="header px-3 d-flex justify-content-between align-items-center mb-3">
                    {/* left side */}
                    <div className="p-0 m-0 d-flex align-items-center">
                      <Avatar name={learning.owner?.name} round size="45" />
                      <div className="p-0 m-0 ps-2">
                        <p className="p-0 m-0 fs-5 fw-semibold">
                          {learning.owner?.name}
                        </p>
                        <p className="p-0 m-0 text-muted">
                          {learning.teammates.length > 0 && "assigned to"}{" "}
                          {learning.teammates[0]?.name}
                        </p>
                      </div>
                    </div>
                    {/* Right side */}
                    {learning.score === "" || !learning.score ? (
                      ""
                    ) : (
                      <div className="p-2 m-0 bg-secondary bg-opacity-75 rounded-1 text-white">
                        {learning.score}
                      </div>
                    )}
                  </div>
                  {/* Card Body */}
                  <div className="body px-3 d-flex gap-3 mb-3 ">
                    {/* left side */}
                    <div className="p-0 m-0 d-flex flex-column gap-4 pt-2">
                      <span
                        onClick={() => {
                          handleFavourite(learning._id);
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        {/* if learning's favourites array has this user */}

                        {learning.favourites.includes(userId) ? (
                          <img src="/assets/yellowStar.svg" />
                        ) : (
                          <img src="/assets/startIcon.svg" />
                        )}
                      </span>
                    </div>
                    {/* Right Side */}
                    <div className="p-0 m-0">
                      <p className="p-0 m-0 mb-2 fs-4 fw-semibold">
                        {learning.name}
                      </p>
                      <p className="p-0 m-0 mb-3">{learning.result}</p>
                      {learning.worked ? (
                        <button className="btn btn-success p-2 m-0 rounded-1">
                          <i className="fa-solid fa-circle-check text-white me-1" />
                          <span className="p-0 m-0">success</span>
                        </button>
                      ) : (
                        <button className="btn btn-danger p-2 m-0 rounded-1">
                          <i className="fa-solid fa-circle-check text-white me-1" />
                          <span className="p-0 m-0">Failure</span>
                        </button>
                      )}
                    </div>
                  </div>
                  <div
                    className={
                      "footer d-flex justify-content-between  bg-opacity-25 py-2 px-3 rounded-bottom " +
                      "bg-primary"
                    }
                  >
                    <p className="p-0 m-0 text-primary">{learning.lever}</p>
                    {/* Dropdown start */}
                    <div className="dropdown">
                      <button
                        id="dropdownMenuButton1"
                        className="border-0 bg-transparent"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="fa-solid fa-ellipsis-vertical" />
                      </button>
                      <ul
                        className="dropdown-menu p-0"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <a className="dropdown-item" href="#">
                            View Learning
                          </a>
                        </li>
                        <li
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          <a
                            className="dropdown-item"
                            href="javascript:void(0)"
                            onClick={() => {
                              setLearning(learning);
                            }}
                          >
                            Move back to tasks
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item bg-danger text-white"
                            href="javascript:void(0)"
                            onClick={() => {
                              deleteLearning(learning._id);
                            }}
                          >
                            Delete Learning
                          </a>
                        </li>
                      </ul>
                      {/* Modal Code Start */}
                      <div
                        className="modal fade"
                        id="exampleModal"
                        tabIndex={-1}
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content border-0 rounded-2 p-3">
                            <div className="modal-header flex-column align-items-start border-0 pb-0">
                              <h3 id="exampleModalLabel">Move back to tasks</h3>
                              <p className="text-muted">
                                All the learning data will be removed
                              </p>
                              {message}
                            </div>
                            <div className="modal-body pb-0 pt-0" />
                            <div className="modal-footer justify-content-start border-0 ">
                              <button
                                type="button"
                                className="btn btn-primary rounded-1 px-4"
                                onClick={() => {
                                  move();
                                }}
                              >
                                Move
                              </button>
                              <button
                                type="button"
                                className="btn btn-outline-danger rounded-1 px-4"
                                data-bs-dismiss="modal"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Modal Code End */}
                    </div>
                    {/* Dropdown End */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  );
};

export default Learning;
