import React from "react";
import { useRoutes } from "react-router-dom";
import Dashboard from "../screens/dashboard/Dashboard";
import Tools from "../screens/tools/Tools";
import Audience from "../sources/Tools/Audience";
import LeadGeneration from "../sources/Tools/LeadGeneration";
import LifetimeCustomer from "../sources/Tools/LifeTimeCustomer";
import ClientRevenue from "../sources/Tools/ClientRevenue";
import CustomerAcquisition from "../sources/Tools/CustomerAcquisition";
import Models from "../sources/Tools/models/Models";
import Project from "../screens/projects/Project";
import ModelName from "../sources/Tools/models/ModelName";
import NewModel from "../sources/Tools/models/NewModel";
import Goals from "../screens/projects/goals/Goals";
import Ideas from "../screens/projects/ideas/Ideas";
import NewIdea from "../screens/projects/ideas/New";
import IdeaName from "../screens/projects/ideas/IdeaName";
import EditIdea from "../screens/projects/ideas/EditIdea";
import Tasks from "../screens/projects/tasks/Tasks";
import NewTask from "../screens/projects/tasks/NewTask";
import Learning from "../screens/projects/learnings/Learning";
import LearningName from "../screens/projects/learnings/LearningName";
import EditLearning from "../screens/projects/learnings/EditLearning";
import TaskName from "../screens/projects/tasks/TaskName";
import EditTask from "../screens/projects/tasks/EditTask";
import GeneralDetails from "../screens/setting/GeneralDetails";
import Workspace from "../screens/setting/Workspace";
import Health from "../screens/projects/health/Health";
import UserManagement from "../screens/setting/UserManagement";
import GoalName from "../screens/projects/goals/GoalName";
import ActionPlan from "../sources/Actionplans/ActionPlan";
import Content from "../sources/Actionplans/Content";

const DashboardRoutes = () => {
  return useRoutes([
    {
      path: "",
      element: <Dashboard />,
    },
    {
      path: "tools",
      children: [
        {
          path: "",
          element: <Models />,
        },
        {
          path: "models",
          children: [
            {
              path: "",
              element: <Models />,
            },
            {
              path: "new",
              element: <NewModel />,
            },
            {
              path: ":id",
              element: <ModelName />,
            },
          ],
        },
      ],
    },
    {
      path: "projects",
      children: [
        { path: "", element: <Project /> },
        {
          path: ":id/goals",
          element: <Goals />,
        },
        {
          path: ":id/ideas",
          children: [
            {
              path: "",
              element: <Ideas />,
            },
            {
              path: "new",
              element: <NewIdea />,
            },
          ],
        },
        {
          path: ":id/tasks",
          children: [
            {
              path: "",
              element: <Tasks />,
            },
            {
              path: "new",
              element: <NewTask />,
            },
          ],
        },
        {
          path: ":id/learnings",
          element: <Learning />,
        },
        {
          path: ":id/health/",
          element: <Health />,
        },
      ],
    },
    {
      path: "idea/:id",
      children: [
        {
          path: "",
          element: <IdeaName />,
        },
        {
          path: "edit",
          element: <EditIdea />,
        },
      ],
    },
    {
      path: "task/:id",
      children: [
        {
          path: "",
          element: <TaskName />,
        },
        {
          path: "edit",
          element: <EditTask />,
        },
      ],
    },
    {
      path: "learning/:id",
      children: [
        {
          path: "",
          element: <LearningName />,
        },
        {
          path: "edit",
          element: <EditLearning />,
        },
      ],
    },
    {
      path: "goal/:id",
      element: <GoalName />,
    },
    {
      path: "settings",
      children: [
        {
          path: "general-details",
          element: <GeneralDetails />,
        },
        {
          path: "user-management",
          element: <UserManagement />,
        },
        {
          path: "workspace",
          element: <Workspace />,
        },
      ],
    },

    {
      path: "actionplans",
      element: <ActionPlan />,
    },
    {
      path: "content/:id",
      element: <Content />,
    },
  ]);
};

export default DashboardRoutes;
