import React, { useState } from "react";
import Sidebar from "../../../components/Sidebar";
import MainLayout from "../../../layout/MainLayout";
import { useParams, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { server, config } from "../../../env";

const IdeaName = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [idea, setIdea] = useState({});
  const [isNominated, setIsNominated] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    await axios
      .get(server + "/idea/read/" + id, config)
      .then((res) => {
        setIdea(res.data);
        setIsNominated(res.data.isNominated);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // move to task
  const moveToTask = async (e) => {
    e.preventDefault();
    await axios
      .post(server + "/idea/moveToTask/" + id, idea, config)
      .then((res) => {
        navigate(`/projects/${idea.project}/tasks`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const nominateIdea = async (e) => {
    e.preventDefault();
    await axios
      .put(server + "/idea/nominate/" + id, {}, config)
      .then((res) => {
        fetchData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <MainLayout>
      <section className="mainContent">
        <div className="row">
          <Sidebar />
          <div className="goalNameSection UntitledIdea pt-4 ps-5">
            <div className="row px-3 pt-4 pe-5 mb-3">
              <div className="p-0 headingSide col-md-6 col-sm-12">
                <Link to={"/projects/" + idea.project + "/ideas"}>
                  <p className="ps-0 mb-2 backBtn">
                    <i className="fa-solid fa-arrow-left-long" />
                    &nbsp; All Ideas
                  </p>
                </Link>
                <h1 className="ps-0 pt-3">{idea.name}</h1>
              </div>
              <div className="p-0 buttonsSide col-md-6 col-sm-12 d-flex flex-column justify-content-center align-items-end">
                <div className="p-0 d-flex justify-content-start w-75" />
                <div className="p-0 d-flex align-items-center gap-3">
                  <button className="btn border-0 p-0">
                    <div
                      onClick={(e) => {
                        nominateIdea(e);
                      }}
                    >
                      {isNominated ? (
                        <img src="/assets/nominatedBtn.svg" alt="sds" />
                      ) : (
                        <img src="/assets/nominateBtn.svg" alt="sds" />
                      )}
                    </div>
                  </button>
                  <button className="btn border-0 p-0">
                    <img
                      src="/assets/moveToTaskBtn.svg"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      alt="sds"
                    />
                  </button>
                  {/* Modal Code Start */}
                  <div
                    className="modal fade"
                    id="exampleModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content border-0 rounded-2 p-3">
                        <div className="modal-header flex-column align-items-start border-0 pb-0">
                          <h3 id="exampleModalLabel">Move to tasks</h3>
                          <p className="text-muted">
                            Are you sure you want this idea in tasks
                          </p>
                        </div>
                        <div className="modal-body pb-0 pt-0" />
                        <div className="modal-footer justify-content-start border-0 ">
                          <button
                            type="button"
                            className="btn btn-primary rounded-1 px-4"
                            onClick={(e) => {
                              moveToTask(e);
                            }}
                          >
                            Move
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-danger rounded-1 px-4"
                            data-bs-dismiss="modal"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Modal Code End */}
                  <Link
                    to={"/idea/" + id + "/edit"}
                    className="btn btn-primary rounded-0 px-4"
                  >
                    Edit
                  </Link>
                </div>
              </div>
            </div>
            <div className="row px-3 pt-3 pe-5">
              <p className="p-0 m-0 mb-2 checklist-label">Lever</p>
              <div className="p-0 m-0 mb-4">
                {idea.lever === "Acquisition" ? (
                  <button className="btn border-0 text-warning bg-warning bg-opacity-25 rounded-1">
                    {idea.lever}
                  </button>
                ) : idea.lever === "Activation" ? (
                  <button className="btn border-0 text-primary bg-primary bg-opacity-25 rounded-1">
                    {idea.lever}
                  </button>
                ) : (
                  <button className="btn border-0 text-danger bg-danger bg-opacity-25 rounded-1">
                    {idea.lever}
                  </button>
                )}
              </div>
              <p className="p-0 m-0 checklist-label">Goal</p>
              <p className="p-0 fs-5 mb-4">{idea.goal?.name}</p>
              <p className="p-0 m-0 mb-2 checklist-label">Members</p>
              <div className="form-floating form-floating-custom ps-0 position-relative">
                <div className="p-0 m-0 position-absolute start-0 d-flex gap-1">
                  {idea.teammates?.map((teammate) => (
                    <p className="m-0 p-0 border-0 rounded-1 px-1">
                      <img
                        src="/assets/account.png"
                        alt=""
                        className="avatar"
                      />
                      &nbsp; {teammate.name}
                    </p>
                  ))}
                </div>
              </div>
              <div className="p-0 pt-4 m-0 mb-4 mt-4 d-flex justify-content-between">
                <div className="col-lg-5 p-0 m-0">
                  <p className="p-0 m-0 checklist-label">START DATE</p>
                  <p className="p-0 fs-5 m-0">
                    {idea.startDate &&
                      new Date(idea.startDate).toLocaleDateString()}
                  </p>
                </div>
                <div className="col-lg-5 p-0 m-0">
                  <p className="p-0 m-0 checklist-label">Due Date</p>
                  <p className="p-0 fs-5 m-0">
                    {idea.endDate &&
                      new Date(idea.endDate).toLocaleDateString()}
                  </p>
                </div>
              </div>
              <p className="p-0 m-0 mb-2 checklist-label">DESCRIPTION</p>
              <p className="p-0 fs-5 w-75 mb-4">{idea.description}</p>
              <p className="p-0 m-0 mb-2 checklist-label">HYPOTHESIS</p>
              <p className="p-0 fs-5 w-75 mb-4">{idea.hypothesis}</p>
              <p className="p-0 m-0 checklist-label">CHECKLIST</p>
              <div className="p-0 m-0 mt-1 mb-3">
                {idea.checklist?.map((checklist) => (
                  <div className="form-check mb-1">
                    <input
                      className="form-check-input border border-2 border-secondary"
                      type="checkbox"
                      checked={checklist.status}
                      disabled
                      style={{
                        opacity: "1",
                      }}
                    />
                    <label
                      className="form-check-label text-muted"
                      htmlFor="flexCheckChecked"
                      style={{
                        fontSize: "1rem",
                        opacity: "1",
                      }}
                    >
                      {checklist.name}
                    </label>
                  </div>
                ))}
              </div>
              <div className="row mt-1">
                <p className="p-0 m-0 mb-1 fs-6">I.C.E Score</p>
              </div>
              <div className="row mb-5">
                <div className="col-lg-1 py-2 px-0 me-3">
                  <p className="p-0 mb-0 text-muted">IMPACT</p>
                  <p className="p-0 mb-0 fs-5">{idea.impact}</p>
                </div>
                <div className="col-lg-1 py-2 px-0 mx-3">
                  <p className="p-0 mb-0 text-muted">CONFIDENCE</p>
                  <p className="p-0 mb-0 fs-5">{idea.confidence}</p>
                </div>
                <div className="col-lg-1 py-2 px-0 mx-3">
                  <p className="p-0 mb-0 text-muted">EASE</p>
                  <p className="p-0 mb-0 fs-5">{idea.ease}</p>
                </div>
                <div className="col-lg-1 py-2 px-0">
                  <p className="p-0 mb-0 fs-5 scoreBox text-center text-white rounded-1 py-2 mt-1">
                    {idea.score}
                  </p>
                </div>
              </div>
              <div className="col-lg-11 p-0 pt-2 pb-4 m-0 mt-2 mb-4">
                <p className="p-0 m-0 mb-3">FILES</p>
                <div className="p-0 m-0 d-flex gap-2">
                  {idea.files?.map((file, index) => (
                    <div className="p-0 m-0 position-relative">
                      <img
                        src={server + "/" + file}
                        className="img-thumbnail"
                        alt="sds"
                        style={{
                          width: "100px",
                          height: "100%",
                        }}
                      />
                      <div className="dropdown">
                        <img
                          src="/assets/opendialogBtn.svg"
                          className="position-absolute openDialogIcon"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        />
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <a className="dropdown-item" href="#">
                              View
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              Download
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  );
};

export default IdeaName;
