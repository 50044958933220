const Chart = window.Chart;
function PieChart(dom, data) {
  new Chart(dom, {
    type: "doughnut",
    options: {
      responsive: true,

      legend: {
        position: "top",
      },
      animation: {
        animateScale: true,
        animateRotate: true,
      },
    },

    data: {
      labels: ["Active Screens", "Inactive Screens"],
      datasets: [
        {
          label: "Status",
          backgroundColor: ["#20C997", "#DC3545"],
          data: [data.online_screens, data.offline_screens],
        },
      ],
    },
  });
  return Chart;
}

function LineChart(dom, payload, name) {
  new Chart(dom, {
    type: "line",
    data: {
      labels: payload.labels,
      datasets: [
        {
          label: name || "",
          data: payload.data,
          fill: true,
          borderColor: "blue",
        },
      ],
    },
  });
  return Chart;
}

module.exports = { PieChart, LineChart };
