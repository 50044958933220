import React from "react";
import MainLayout from "../../../layout/MainLayout";
import Sidebar from "../../../components/Sidebar";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { server, config } from "../../../env";
import { useEffect, useState } from "react";
import Loader from "../../../components/Loader";
import Alert from "../../../components/Alert";
import { LineChart } from "../../../tools/Chart";

const GoalName = () => {
  const { id } = useParams();
  const [goal, setGoal] = useState({});
  const [ideas, setIdeas] = useState([]);
  const [learnings, setLearnings] = useState([]);
  const [keyresult, setKeyresult] = useState({});
  const [keyresults, setKeyresults] = useState([]);
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState("");
  const [project, setProject] = useState();
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    readGoal();
    readTable();
    setProject(localStorage.getItem("project"));
  }, []);

  useEffect(() => {
    readGraph();
  }, [keyresults]);

  const readGraph = () => {
    for (let i = 0; i < keyresults.length; i++) {
      const keyresult = keyresults[i];
      const metrics = keyresult.metrics;

      var data = [];
      var labels = [];

      for (let j = 0; j < metrics.length; j++) {
        const metric = metrics[j];

        data.push(metric.value);
        labels.push(new Date(metric.date).toLocaleDateString());
      }

      // display graph
      const e = document.getElementById("myChart" + i);
      var canvas = document.createElement("CANVAS");
      while (e.firstChild) {
        e.removeChild(e.firstChild);
      }
      e.appendChild(canvas);
      LineChart(canvas, { labels, data }, keyresult.name);
    }
  };

  const readGoal = async () => {
    await axios
      .get(server + "/goal/read/" + id, config)
      .then((res) => {
        setGoal(res.data.goal);
        setKeyresults(res.data.goal.keyresults);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const readTable = async () => {
    await axios
      .get(server + "/goal/readTables/" + id, config)
      .then((res) => {
        setIdeas(res.data.ideas);
        setLearnings(res.data.learnings);
        setTasks(res.data.tasks);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // update metric
  const updateMetric = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    const data = {
      date: e.target.date.value,
      value: e.target.value.value,
    };

    await axios
      .post(
        server + "/goal/updateMetric/" + goal._id + "/" + keyresult._id,
        data,
        config
      )
      .then((res) => {
        setMessage(<Alert className="success" message={res.data.message} />);
        setLoader("");
        readGoal();
      })
      .catch((err) => {
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
        setLoader("");
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  return (
    <MainLayout>
      <section className="mainContent">
        <div className="row">
          <Sidebar />
          <div className="goalNameSection pt-4 ps-5 pe-5">
            {/* Header Section Start */}
            <div className="row px-3 mb-4">
              <div className="p-0 headingSide col-md-6">
                <p className="p-0 mb-1 text-muted">
                  Project &gt; Goals
                  <span className="text-dark"> &gt; {goal.name} </span>
                </p>
                <h1 className="ps-0">{goal.name}</h1>
              </div>
              <div className="col-md-6 p-0 buttonsSide d-flex flex-column justify-content-end align-items-end gap-3">
                <Link
                  to={`/projects/${goal.project}/goals`}
                  className="btn bg-success bg-opacity-25 text-success rounded-1 py-2 px-4"
                >
                  Done
                </Link>
                <span className="fs-5">
                  {new Date(goal.startDate).toDateString() +
                    " - " +
                    new Date(goal.endDate).toDateString()}
                </span>
              </div>
            </div>
            <div className="row px-3 mb-5">
              <h5 className="p-0 mb-3 text-secondary">DESCRIPTION</h5>
              <span className="p-0 pe-4">{goal.description}</span>
            </div>
            {/* Header End */}
            {/* Metric Goals section Start */}
            <div className="row px-3 mb-5">
              <h5 className="p-0 mb-5 text-secondary ">METRIC GOALS</h5>
              {goal?.keyresults?.map((keyresult, index) => (
                <div className="d-flex justify-content-center mb-3" key={index}>
                  <div className="card col-10 shadow-sm  ">
                    <div className="card-body">
                      <div className="metricGoal col-sm-12 px-5 mb-4 d-flex justify-content-between align-items-center">
                        <h5 className="p-0 m-0">{keyresult.name}</h5>
                        <a href="#" className="p-0 m-0 editIcon">
                          <img src="/assets/editIcon.png" />
                        </a>
                      </div>

                      <div id={"myChart" + index} />

                      <div className="col-lg-12 pe-5 mb-5 d-flex justify-content-around align-items-center">
                        <div className="p-0 px-2 d-flex flex-column align-items-center justify-content-center">
                          <h1 className="p-0 mb-0 text-warning">
                            {keyresult.startValue}{" "}
                            {keyresult.value === "number"
                              ? ""
                              : keyresult.value === "percentage"
                              ? "%"
                              : keyresult.value === "currency"
                              ? "$"
                              : ""}
                          </h1>
                          <p className="p-0 mb-2 text-warning text-center">
                            Start Value
                          </p>
                          <button className="metricGoal-btn btn bg-primary bg-opacity-25 text-primary">
                            View All Values
                          </button>
                        </div>
                        <div className="p-0 px-2 d-flex flex-column align-items-center justify-content-center">
                          <h1 className="p-0 mb-0 text-primary">
                            {
                              keyresult?.metrics[keyresult.metrics.length - 1]
                                ?.value
                            }
                            {keyresult.value === "number"
                              ? ""
                              : keyresult.value === "percentage"
                              ? "%"
                              : keyresult.value === "currency"
                              ? "$"
                              : ""}
                          </h1>
                          <p className="p-0 mb-2 text-primary text-center">
                            Current Value
                          </p>
                          <button
                            className="metricGoal-btn btn bg-primary bg-opacity-25 text-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            onClick={() => {
                              setKeyresult(keyresult);
                            }}
                          >
                            Update Value
                          </button>
                        </div>
                        <div className="p-0 px-2 d-flex flex-column align-items-center justify-content-center">
                          <h1 className="p-0 mb-0 text-success">
                            {keyresult.targetValue}{" "}
                            {keyresult.value === "number"
                              ? ""
                              : keyresult.value === "percentage"
                              ? "%"
                              : keyresult.value === "currency"
                              ? "$"
                              : ""}
                          </h1>
                          <p className="p-0 mb-2 text-success text-center">
                            Target Value
                          </p>
                          <button className="metricGoal-btn btn opacity-0">
                            &nbsp;
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* Metric Goals section end */}
            {/* Tables Section Start */}
            <div className="row px-3 pt-4">
              {/* 1st Table Starting */}
              <div className="col-12 mb-3 px-4 d-flex justify-content-between align-items-center">
                <h5 className="p-0">IDEAS</h5>
                <Link
                  to={`/projects/${project}/ideas`}
                  className="btn border border-secondary rounded-1 px-3 me-5"
                >
                  View Ideas
                </Link>
              </div>
              <div className="px-4 table-responsive">
                <table className="table table-borderless p-2 dataTable">
                  <thead>
                    <tr>
                      <td scope="col" className="text-secondary">
                        KEY
                      </td>
                      <td scope="col" className="text-secondary">
                        NAME
                      </td>
                      <td scope="col" className="text-secondary">
                        LEVER
                      </td>
                      <td scope="col" className="text-secondary">
                        CREATOR
                      </td>
                      <td scope="col" className="text-secondary">
                        SCORE
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {ideas?.map((idea) => (
                      <tr key={idea._id}>
                        <td scope="row">{idea._id}</td>
                        <td>{idea.name}</td>
                        <td>
                          <button className="btn border-0 text-success bg-success bg-opacity-25 rounded-1">
                            {idea.lever}
                          </button>
                        </td>
                        <td>{idea.owner.name}</td>
                        <td>
                          {(
                            (idea.impact + idea.confidence + idea.ease) /
                            3
                          ).toFixed(2)}
                          ({idea.impact}/{idea.confidence}/{idea.ease})
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* Table Ending */}
              </div>
              {/* 1st Table Ending */}
              {/* 2nd Table Starting */}
              <div className="col-12 mb-3 px-4 d-flex justify-content-between align-items-center">
                <h5 className="p-0">TESTS</h5>
                <Link
                  to={`/projects/${project}/tasks`}
                  className="btn border border-secondary rounded-1 px-3 me-5"
                >
                  View Tests
                </Link>
              </div>
              <div className="px-4 table-responsive">
                <table className="table table-borderless p-2 dataTable">
                  <thead>
                    <tr>
                      <td scope="col" className="text-secondary">
                        KEY
                      </td>
                      <td scope="col" className="text-secondary">
                        NAME
                      </td>
                      <td scope="col" className="text-secondary">
                        LEVER
                      </td>
                      <td scope="col" className="text-secondary">
                        CREATOR
                      </td>
                      <td scope="col" className="text-secondary">
                        SCORE
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {tasks.map((task, index) => (
                      <tr key={index}>
                        <td scope="row">{task._id}</td>
                        <td>{task.name}</td>
                        <td>
                          <button className="btn border-0 text-success bg-success bg-opacity-25 rounded-1">
                            {task.lever}
                          </button>
                        </td>
                        <td>{task.owner.name}</td>
                        <td>{task.score}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* Table Ending */}
              </div>
              {/* 2nd Table Ending */}
              {/* 3rd Table Starting */}
              <div className="col-12 mb-3 px-4 d-flex justify-content-between align-items-center">
                <h5 className="p-0">LEARNINGS</h5>
                <Link
                  to={`/projects/${project}/learnings`}
                  className="btn border border-secondary rounded-1 px-3 me-5"
                >
                  View Learning
                </Link>
              </div>
              <div className="px-4 table-responsive">
                <table className="table table-borderless p-2 dataTable">
                  <thead>
                    <tr>
                      <td scope="col" className="text-secondary">
                        KEY
                      </td>
                      <td scope="col" className="text-secondary">
                        NAME
                      </td>
                      <td scope="col" className="text-secondary">
                        LEVER
                      </td>
                      <td scope="col" className="text-secondary">
                        CREATOR
                      </td>
                      <td scope="col" className="text-secondary">
                        SCORE
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {learnings?.map((learning) => (
                      <tr key={learning._id}>
                        <td scope="row">{learning._id}</td>
                        <td>{learning.name}</td>
                        <td>
                          <button className="btn border-0 text-success bg-success bg-opacity-25 rounded-1">
                            {learning.lever}
                          </button>
                        </td>
                        <td>{learning.owner.name}</td>
                        <td>
                          {(learning.impact +
                            learning.confidence +
                            learning.ease) /
                            3}
                          ({learning.impact}/{learning.confidence}/
                          {learning.ease})
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* Table Ending */}
              </div>
              {/* 3rd Table Ending */}
            </div>
            {/* Tables Section End */}
          </div>
        </div>
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content rounded-1 p-3">
              <div className="modal-header flex-column align-items-start border-0 pb-0">
                <h3 id="exampleModalLabel">Add New Value</h3>
                <p className="text-muted">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                  auctor. Sit amet, consectetur adipiscing consectetur
                  adipiscing elit.
                </p>
              </div>
              {message}
              <form onSubmit={updateMetric}>
                <div className="modal-body pb-0">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInputValue"
                      placeholder="Enter Current Value"
                      name="value"
                    />
                    <label htmlFor="floatingInputValue">CURRENT VALUE</label>
                  </div>
                  <div className="form-floating mb-3 dateField">
                    <input
                      type="date"
                      className="form-control"
                      id="floatingInputGrid"
                      placeholder=" DATE"
                      name="date"
                    />
                    <label htmlFor="floatingInputGrid">Select Date</label>
                  </div>
                </div>
                <div className="modal-footer border-0">
                  <button
                    type="reset"
                    className="btn btn-outline-danger rounded-1"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary rounded-1 customBtn"
                  >
                    Add Value {loader}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  );
};

export default GoalName;
