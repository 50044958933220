export default function DCF({
  starting_date,
  cash_in_bank,
  initial_no_of_customers,
  tripwire_offer_price,
  oto_1_price,
  oto_1_conv_rate,
  oto_2_price,
  oto_2_conv_rate,
  avg_ord_val,
  realisation_rate,
  units_per_order,
  blended_cogs,
  outbound_salary,
  contacts_per_month_per_sdr,
  number_of_sdrs,
  contact_to_lead_conversion_rate,
  lead_to_customer_conversion_rate_outbound,
  shipping_cost_per_order,
  monthly_ad_spend,
  cpm,
  ctr,
  lpv_rate,
  lead_gen_rate,
  conv_rate,
  organic_views_per_month,
  organic_view_to_lead_conversion_rate,
  lead_to_customer_conversion_rate_organic,
  returning_cust_rate,
  time_to_return,
  cost_to_market_return,
  referers_out_of_customers,
  invitees_per_referal,
  invitees_conv_rate,
  refund_rate,
  fixed_loss_per_refund,
  payment_processor_fees,
  merchant_account_fees,
  fixed_cost_per_month,
  fixed_cost_increase_per_100_cust,
  upfront_investment_cost,
  debt,
  debt_interest_rate,
  tax_rate,
  number_of_shares,
  projection_period,
  discount_rate,
  perpetual_growth_rate,
}) {
  const opVals = [];

  for (let month = 0; month < projection_period; month++) {
    // FINANCIAL DATA
    const realised_revenue = (avg_ord_val / 100) * realisation_rate;
    const cogs_per_order = (avg_ord_val * blended_cogs) / 100;

    // Outbound
    const sdr_salaries = number_of_sdrs * outbound_salary;
    const contacts = number_of_sdrs * contacts_per_month_per_sdr;
    const outbound_leads = contacts * (contact_to_lead_conversion_rate / 100);
    const outbound_customers =
      outbound_leads * (lead_to_customer_conversion_rate_outbound / 100);
    const outbound_revenue = outbound_customers * realised_revenue;
    const outbound_gross_profit = outbound_revenue * (1 - blended_cogs / 100);
    const outbound_ROI =
      outbound_gross_profit === 0 ? 0 : outbound_gross_profit / sdr_salaries;

    // Inbound
    const advertising_spend = monthly_ad_spend;
    const reach = (1000 * monthly_ad_spend) / cpm;
    const link_clicks = (reach * ctr) / 100;
    const page_views = (link_clicks * lpv_rate) / 100;
    const inbound_leads = (page_views * lead_gen_rate) / 100;
    const inbound_customers = (inbound_leads * conv_rate) / 100;
    const inbound_revenue = inbound_customers * realised_revenue;
    const ROAS = inbound_revenue > 0 ? inbound_revenue / monthly_ad_spend : 0;
    const gross_profit = inbound_revenue * (1 - blended_cogs / 100);
    const ROI = gross_profit / monthly_ad_spend;

    // Organic
    const organic_leads =
      organic_views_per_month * (organic_view_to_lead_conversion_rate / 100);
    const organic_customers =
      organic_leads * (lead_to_customer_conversion_rate_organic / 100);
    const organic_revenue = organic_customers * realised_revenue;
    const organic_gross_profit = organic_revenue * (1 - blended_cogs / 100);

    // Total
    const marketing_spend = sdr_salaries + advertising_spend;
    const marketing_customers_acquired =
      outbound_customers + inbound_customers + organic_customers;
    const marketing_revenue = marketing_customers_acquired * realised_revenue;
    const marketing_gross_profit = marketing_revenue * (1 - blended_cogs / 100);
    const marketing_ROI = marketing_gross_profit / marketing_spend;

    // VIRALITY METRICS
    const total_referrers =
      marketing_customers_acquired * (referers_out_of_customers / 100);
    const total_invitees = total_referrers * invitees_per_referal;
    const referal_customers = total_invitees * (invitees_conv_rate / 100);
    const referal_revenue = referal_customers * realised_revenue;

    // RETENTION METRICS
    let total_customer_base =
      month === 0
        ? initial_no_of_customers + marketing_customers_acquired
        : opVals[month - 1].total_customer_base + marketing_customers_acquired;
    const returning_customers =
      total_customer_base * (returning_cust_rate / 100);
    const monthly_returning_revenue =
      (returning_customers * realised_revenue) / time_to_return;
    const cost_to_market_return_curr =
      monthly_returning_revenue * (cost_to_market_return / 100);
    const refund_cost = avg_ord_val + fixed_loss_per_refund;
    const total_refund_orders =
      (returning_customers + marketing_customers_acquired + referal_customers) *
      (refund_rate / 100);
    const total_refund_loss = total_refund_orders * refund_cost;

    // TOTAL MARKETING METRICS
    const total_gross_revenue =
      marketing_revenue +
      monthly_returning_revenue +
      referal_revenue -
      total_refund_loss;
    const total_gross_profit_before_adspend =
      total_gross_revenue - total_gross_revenue * (blended_cogs / 100);
    const gross_profit_margin_before_adspend =
      (total_gross_profit_before_adspend / total_gross_revenue) * 100;
    const total_gross_profit_after_adspend =
      total_gross_revenue -
      total_gross_revenue * (blended_cogs / 100) -
      marketing_spend;
    const gross_profit_margin_after_adspend =
      (total_gross_profit_after_adspend / total_gross_revenue) * 100;
    const gross_proft_before_ads_per_customer = total_gross_profit_before_adspend / (marketing_customers_acquired + returning_customers);
    const gross_proft_after_ads_per_customer = total_gross_profit_after_adspend / (marketing_customers_acquired + returning_customers);

    // ADMIN
    const payment_processor_fees_per_order =
      realised_revenue * (payment_processor_fees / 100);
    const merchant_account_fees_per_order =
      (realised_revenue * merchant_account_fees) / 100;
    const total_payment_processor_fees =
      (marketing_customers_acquired + returning_customers + referal_customers) *
      payment_processor_fees_per_order;
    const total_merchant_account_fees =
      (marketing_customers_acquired + returning_customers + referal_customers) *
      merchant_account_fees_per_order;
    const total_fixed_cost =
      marketing_customers_acquired * (fixed_cost_increase_per_100_cust / 100) +
      fixed_cost_per_month;
    const upfront_investment_cost_curr =
      month === 0 ? upfront_investment_cost : 0;

    // VALUATION
    const earnings_before_tax =
      total_gross_profit_after_adspend -
      total_payment_processor_fees -
      total_merchant_account_fees -
      total_fixed_cost -
      upfront_investment_cost_curr;
    const cash_taxes =
      earnings_before_tax > 0 ? (earnings_before_tax * tax_rate) / 100 : 0;
    const debt_interest_payable = (debt * debt_interest_rate) / 1200;
    const plus_cash =
      month === 0 ? cash_in_bank : opVals[month - 1].cash_in_bank_curr;
    const less_debt = debt;
    const earnings_after_tax =
      earnings_before_tax - cash_taxes - debt_interest_payable;
    const avg_terminal_value =
      (earnings_after_tax * (100 + perpetual_growth_rate)) /
      (discount_rate - perpetual_growth_rate);

    // const enterprise_value = DCF.oValues.map((val, i) => val.earnings_after_tax / ((1 + discount_rate / 100) ** (i / 12))).reduce((a, b) => a + b, 0) + earnings_after_tax / (1 + discount_rate) ** (month / 12);
    const enterprise_value = earnings_after_tax;
    const equity_value =
      enterprise_value !== 0 ? enterprise_value + plus_cash - less_debt : 0;
    const equity_value_per_share = equity_value / number_of_shares;

    // OVERALL
    const cash_in_bank_curr =
      month === 0
        ? cash_in_bank + earnings_after_tax
        : opVals[month - 1].cash_in_bank_curr + earnings_after_tax;
    const cash_ROI_compared_to_day_1 =
      cash_in_bank > 0 ? cash_in_bank_curr / cash_in_bank : 0;
    const cash_payback_period =
      cash_ROI_compared_to_day_1 < 1 ? "False" : "True";

    opVals.push({
      realised_revenue,
      cogs_per_order,

      sdr_salaries,
      contacts,
      outbound_leads,
      outbound_customers,
      outbound_revenue,
      outbound_gross_profit,
      outbound_ROI,

      advertising_spend,
      reach,
      link_clicks,
      page_views,
      inbound_leads,
      inbound_customers,
      inbound_revenue,
      ROAS,
      gross_profit,
      ROI,

      organic_leads,
      organic_customers,
      organic_revenue,
      organic_gross_profit,

      marketing_spend,
      marketing_customers_acquired,
      marketing_revenue,
      marketing_gross_profit,
      marketing_ROI,

      total_referrers,
      total_invitees,
      referal_customers,
      referal_revenue,

      total_customer_base,
      returning_customers,
      monthly_returning_revenue,
      cost_to_market_return_curr,
      refund_cost,
      total_refund_orders,
      total_refund_loss,

      total_gross_revenue,
      total_gross_profit_before_adspend,
      gross_profit_margin_before_adspend,
      total_gross_profit_after_adspend,
      gross_profit_margin_after_adspend,
      gross_proft_before_ads_per_customer,
      gross_proft_after_ads_per_customer,

      payment_processor_fees_per_order,
      merchant_account_fees_per_order,
      total_payment_processor_fees,
      total_merchant_account_fees,
      total_fixed_cost,
      upfront_investment_cost_curr,

      earnings_before_tax,
      cash_taxes,
      debt_interest_payable,
      enterprise_value,
      plus_cash,
      less_debt,
      equity_value,
      equity_value_per_share,
      earnings_after_tax,
      avg_terminal_value,

      cash_in_bank_curr,
      cash_ROI_compared_to_day_1,
      cash_payback_period,
    });
  }
  console.log(opVals);
  return opVals;
}
