import React, { useState, useEffect } from "react";
import MainLayout from "../../../layout/MainLayout";
import { Link } from "react-router-dom";
import axios from "axios";
import { server, config } from "../../../env";

const Models = () => {
  const [models, setModels] = useState([]);

  useEffect(() => {
    readModels();
  }, []);

  const readModels = async () => {
    await axios
      .get(server + "/model/read", config)
      .then((res) => {
        setModels(res.data.models);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteModel = async (id) => {
    await axios
      .delete(server + "/model/delete/" + id, config)
      .then((res) => {
        readModels();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <MainLayout>
      <section className="model-heading tools-heading">
        <div className="container">
          <div className="row ps-4 pad">
            <div className="p-0 headingSide col-md-6 col-sm-12">
              {/* <Link to="/tools">
                <p className="ps-0 mb-1 backBtn">
                  <i className="fa-solid fa-arrow-left-long" />
                  &nbsp; All Tools
                </p>
              </Link> */}
              <h1 className="ps-0">Models</h1>
            </div>
            <div className="p-0 buttonsSide col-md-6 col-sm-12 d-flex justify-content-end align-items-center">
              <button
                type="button"
                className="btn btn-outline-primary me-4 rounded-1 py-2"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Compare
              </button>
              <Link
                to="/tools/models/new"
                className="btn btn-primary me-4 rounded-1 py-2"
              >
                <i className="fa-solid fa-plus bg-light bg-opacity-25 p-1 rounded-1 newModel" />{" "}
                New Model
              </Link>
              {/* Modal Code Start */}
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content rounded-1 p-3">
                    <div className="modal-header flex-column align-items-start border-0 pb-0">
                      <h3 id="exampleModalLabel">Compare Models</h3>
                      <p className="text-muted">Select 2 Models to compare</p>
                    </div>
                    <div className="modal-body pb-0">
                      <select
                        className="form-select form-select-lg mb-3"
                        aria-label=".form-select-lg example"
                      >
                        <option selected="">Slot A</option>
                        <option value={1}>One</option>
                        <option value={2}>Two</option>
                        <option value={3}>Three</option>
                      </select>
                      <select
                        className="form-select form-select-lg mb-2"
                        aria-label=".form-select-lg example"
                      >
                        <option selected="">Slot B</option>
                        <option value={1}>One</option>
                        <option value={2}>Two</option>
                        <option value={3}>Three</option>
                      </select>
                    </div>
                    <div className="modal-footer justify-content-start border-0">
                      <button
                        type="button"
                        className="btn btn-primary rounded-1 customBtn"
                      >
                        Compare
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-danger rounded-1"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* Modal Code End */}
            </div>
          </div>
        </div>
      </section>

      <section className="model-section">
        <div className="container">
          <div className="row ps-4 pad">
            <div className="col-12 p-0">
              {/* Table Starting */}
              <table className="table table-borderless p-2 modelsTab">
                <thead>
                  <tr>
                    <td scope="col" className="text-secondary">
                      ID
                    </td>
                    <td scope="col" className="text-secondary">
                      Title
                    </td>

                    <td scope="col" className="text-secondary">
                      Created
                    </td>
                    <td scope="col" className="text-secondary">
                      Creator
                    </td>
                    <td scope="col" className="text-secondary">
                      &nbsp;
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {models.map((model) => (
                    <>
                      <tr className="border" key={model}>
                        <td scope="row">{model.uuid}</td>
                        <td>{model.name}</td>
                        <td>
                          {new Date(model.createdAt).toLocaleDateString()}
                        </td>
                        <td>
                          <img
                            src="/assets/account.png"
                            alt=""
                            className="avatar"
                          />
                          &nbsp; {model.creator?.name}
                        </td>

                        <td>
                          {/* Dropdown start */}
                          <div className="dropdown">
                            <button
                              id="dropdownMenuButton1"
                              className="border-0 bg-transparent"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="fa-solid fa-ellipsis-vertical" />
                            </button>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li>
                                <Link
                                  to={`/tools/models/${model._id}`}
                                  className="dropdown-item"
                                >
                                  Open
                                </Link>
                              </li>

                              <li>
                                <Link
                                  to=""
                                  className="dropdown-item text-danger"
                                  onClick={() => deleteModel(model._id)}
                                >
                                  Delete
                                </Link>
                              </li>
                            </ul>
                          </div>
                          {/* Dropdown End */}
                        </td>
                      </tr>
                      <tr className="lh-1">
                        <td className="p-0">
                          <br />
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
              {/* Table Ending */}
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  );
};

export default Models;
