import { Routes, Route } from "react-router-dom";
import DashboardRoutes from "./routes/DashboardRoutes";
import AuthRoutes from "./routes/AuthRoutes";
import { server, config } from "./env";
import axios from "axios";
import { useEffect, useState } from "react";
import PageLoader from "./components/PageLoader";

function App() {
  const [isLogin, setIsLogin] = useState(false);
  const [isAllLoaded, setIsAllLoaded] = useState(false);

  useEffect(() => {
    checkUserLogin();
  }, []);

  const checkUserLogin = async (token) => {
    await axios
      .get(server + "/auth/access", config)
      .then((rsp) => {
        setIsLogin(true);
        setIsAllLoaded(true);
      })
      .catch((err) => {
        setIsAllLoaded(true);
        setIsLogin(false);
      });
  };

  return isAllLoaded ? (
    isLogin ? (
      <Routes>
        <Route path="*" element={<DashboardRoutes />} />
      </Routes>
    ) : (
      <Routes>
        <Route path="*" element={<AuthRoutes />} />
      </Routes>
    )
  ) : (
    <PageLoader />
  );
}

export default App;
