import React, { useState, useEffect } from "react";
import Sidebar from "../../../components/Sidebar";
import MainLayout from "../../../layout/MainLayout";
import axios from "axios";
import { server, config } from "../../../env";
import { PieChart } from "react-chartkick";
import { LineChart } from "../../../tools/Chart";

const Health = () => {
  const project = localStorage.getItem("project");
  const [span, setSpan] = useState(1);
  const [learnings, setLearnings] = useState({});

  useEffect(() => {
    readIdeas();
    readLearnings();
    readGoals();
    readLearningChart();
  }, [span]);

  const readIdeas = async () => {
    await axios
      .get(`${server}/health/readIdeaGraph/${project}/?span=${span}`, config)
      .then((res) => {
        const e = document.getElementById("ideaChart");
        var canvas = document.createElement("CANVAS");
        while (e.firstChild) {
          e.removeChild(e.firstChild);
        }
        e.appendChild(canvas);
        LineChart(canvas, res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const readLearnings = async () => {
    await axios
      .get(
        `${server}/health/readLearningGraph/${project}/?span=${span}`,
        config
      )
      .then((res) => {
        const e = document.getElementById("learningChart");
        var canvas = document.createElement("CANVAS");
        while (e.firstChild) {
          e.removeChild(e.firstChild);
        }
        e.appendChild(canvas);
        LineChart(canvas, res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const readGoals = async () => {
    await axios
      .get(`${server}/health/readGoalGraph/${project}/?span=${span}`, config)
      .then((res) => {
        const e = document.getElementById("goalChart");
        var canvas = document.createElement("CANVAS");
        while (e.firstChild) {
          e.removeChild(e.firstChild);
        }
        e.appendChild(canvas);
        LineChart(canvas, res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const readLearningChart = async () => {
    await axios
      .get(
        `${server}/health/readLearningChart/${project}/?span=${span}`,
        config
      )
      .then((res) => {
        setLearnings(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <MainLayout>
      <section className="mainContent">
        <div className="row">
          <Sidebar />
          <div className="goalNameSection UntitledIdea pt-4 px-5">
            <div className="row px-3 pt-3 mb-4">
              <div className="p-0 headingSide col-md-6 col-sm-12">
                <p className="ps-0 mb-3 text-muted">Projects</p>
                <h1 className="ps-0">Growth Health</h1>
              </div>
              <div className="p-0 buttonsSide col-md-6 col-sm-12 d-flex justify-content-end align-items-center">
                <div className="col-lg-4 p-0 m-0">
                  <select
                    className="form-select py-2 border-secondary border-opacity-75"
                    aria-label="Default select example"
                    onChange={(e) => setSpan(e.target.value)}
                    name="span"
                  >
                    <option selected value="1">
                      Past 1 Month
                    </option>
                    <option value="3">Past 3 Months</option>
                    <option value="6">Past 6 Months</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row px-3 mb-5">
              <div className="col ps-0 pt-2">
                <p className="p-0 fs-5 fw-semibold m-0">
                  Ideas Created &amp; Tests Started
                </p>
                <p className="p-0 text-muted m-0 mb-2">Past {span} months</p>
                <div id="ideaChart"></div>
              </div>
              <div className="col ps-0 pt-2">
                <p className="p-0 fs-5 fw-semibold m-0">Learnings Acquired</p>
                <p className="p-0 text-muted m-0 mb-2">Past {span} months</p>
                <div id="learningChart"></div>
              </div>
            </div>
            <div className="row px-3">
              <div className="col ps-0 pt-2">
                <p className="p-0 fs-5 fw-semibold m-0">
                  Learnings by Growth Lever
                </p>
                <p className="p-0 text-muted m-0 mb-2">Past {span} months</p>
                <PieChart
                  data={{
                    acquisition: learnings.acquisition,
                    activation: learnings.activation,
                    retention: learnings.retention,
                  }}
                />
              </div>
              <div className="col ps-0 pt-2">
                <p className="p-0 fs-5 fw-semibold m-0">Goals Created</p>
                <p className="p-0 text-muted m-0 mb-2">Past {span} months</p>
                <div id="goalChart"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  );
};

export default Health;
