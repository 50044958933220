// import Logo from "../../asets/images/logo.svg";
import Alert from "../../components/Alert";
import Loader from "../../components/Loader";
import { server } from "../../env";
import axios from "axios";
import Cookies from "js-cookie";
import { useState } from "react";

const Login = () => {
  const [loader, setLoader] = useState("");
  const [alert, setAlert] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoader(<Loader />);

    // await this.recaptcha.execute();

    const params = {
      email,
      password,
      // "g-recaptcha-response": this.state.reCaptcha,
    };

    await axios
      .post(server + "/auth/login", params)
      .then((rsp) => {
        Cookies.set("footprint", rsp.data.accessToken);
        setLoader("");
        setAlert(<Alert className="success" message={rsp.data.message} />);
        window.location.href = "/";
      })
      .catch((err) => {
        if (err.response) {
          setLoader("");
          setAlert(
            <Alert className="danger" message={err.response.data.message} />
          );
        }
        setLoader("");
      });
  };

  return (
    <div className="container-fluid ">
      <div className="row justify-content-center align-items-center loginCover">
        <div className="col-sm-6 col-md-6 col-lg-4 p-3 m-0 d-flex flex-column align-items-center">
          <img src="/assets/bigLogo.png" alt="logo" className="mb-2" />
          <p className="fs-3 pt-4 pb-4 m-0">Scalez Platform 1.0</p>
          {alert}
          <div className="col-sm-11 form-floating mb-3">
            <input
              type="email"
              className="form-control"
              id="floatingInputValue"
              placeholder="Enter a valid email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label htmlFor="floatingInputValue">EMAIL ADDRESS</label>
          </div>
          <div className="col-sm-11 form-floating mb-3">
            <input
              type="password"
              className="form-control"
              id="floatingInputValue"
              placeholder="Enter Password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <label htmlFor="floatingInputValue">PASSWORD</label>
          </div>
          <button
            className="col-sm-11 btn btn-primary py-2 mt-1"
            type="submit"
            onClick={(e) => handleSubmit(e)}
          >
            Log In {loader}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
