import React from "react";
import MainLayout from "../../../layout/MainLayout";
import Sidebar from "../../../components/Sidebar";
import { Link } from "react-router-dom";
import axios from "axios";
import { server, config } from "../../../env";
import { useEffect, useState } from "react";
import Loader from "../../../components/Loader";
import Alert from "../../../components/Alert";
import Keyresult from "./component/Keyresult";
import Modal from "../../../components/Modal";
import Select from "react-select";
import Avatar from "react-avatar";

const Goals = () => {
  const [projectName, setProjectName] = useState("");
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [goals, setGoals] = useState([]);
  const [keyresult, setKeyresult] = useState(false);
  const [goal, setGoal] = useState({});
  const [goal1, setGoal1] = useState({});
  const [goalIndex, setGoalIndex] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const [alert, setAlert] = useState("");

  // fetch state from parent component

  // function accordion(e, accordion) {
  //   let accordionId = document.getElementById(accordion);
  //   if (e.classList.contains("fa-plus")) {
  //     e.classList.remove("fa-plus");
  //     e.classList.add("fa-minus");
  //     accordionId.style.opacity = "1";
  //     accordionId.style.height = "auto";
  //   } else {
  //     e.classList.remove("fa-minus");
  //     e.classList.add("fa-plus");
  //     accordionId.style.opacity = "0";
  //     accordionId.style.height = "0";
  //   }
  // }

  useEffect(() => {
    const id = localStorage.getItem("project");
    const readName = () => {
      axios
        .get(`${server}/project/readName/${id}`, config)
        .then((res) => {
          setProjectName(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    readName();
    readGoals();
    readProject();
  }, []);

  const readProject = async () => {
    const id = localStorage.getItem("project");
    await axios
      .get(`${server}/project/readOne/${id}`, config)
      .then((res) => {
        const projectUsers = res.data.users;
        const options = [];
        projectUsers.forEach((user) => {
          options.push({ value: user._id, label: user.name });
        });
        setOptions(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createGoal = async (e) => {
    const id = localStorage.getItem("project");
    e.preventDefault();
    setLoader(true);

    const data = {
      name: e.target.name.value,
      description: e.target.description.value,
      startDate: e.target.startDate.value,
      endDate: e.target.endDate.value,
      project: id,
    };

    await axios
      .post(`${server}/goal/create`, data, config)
      .then((res) => {
        setLoader(false);
        setMessage(<Alert className="success" message={res.data.message} />);
        readGoals();
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  const readGoals = async () => {
    const id = localStorage.getItem("project");
    await axios
      .get(`${server}/goal/getAll/${id}`, config)
      .then((res) => {
        setGoals(res.data.goals);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // update status
    const updateStatus = async (e) => {
      const data = {
        status: goal1.status,
      };

      await axios
        .put(`${server}/goal/status/${goal1.id}`, data, config)
        .then((res) => {
          readGoals();
        })
        .catch((err) => {
          console.log(err);
        });
    };
    updateStatus();
  }, [goal1]);

  // delete goal
  const deleteGoal = async (goalId) => {
    await axios
      .delete(server + "/goal/delete/" + goalId, config)
      .then((res) => {
        readGoals();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addMember = async (e) => {
    e.preventDefault();

    const teammates = [];
    if (selectedOption) {
      for (let i = 0; i < selectedOption?.length; i++) {
        teammates.push(selectedOption[i].value);
      }
    }
    const data = {
      member: teammates,
    };
    await axios
      .post(`${server}/goal/addMember/${goal1._id}`, data, config)
      .then((res) => {
        setAlert(<Alert className="success" message={res.data.message} />);
        readGoals();
      })
      .catch((err) => {
        console.log(err);
        setAlert(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setAlert("");
    }, 3000);
  };

  return (
    <MainLayout>
      <section className="mainContent">
        <div className="row">
          <Sidebar />
          <div className="goalNameSection projectNameSection pt-4 ps-5">
            {/* Heading Section Start */}
            <div className="row mb-5 mt-1">
              <div className="p-0 headingSide col-md-6 col-sm-12">
                <Link to="/projects">
                  <p className="ps-0 mb-1 backBtn">
                    <i className="fa-solid fa-arrow-left-long" />
                    &nbsp; All Projects
                  </p>
                </Link>
                <h1 className="ps-0">{projectName}</h1>
              </div>
              <div className="p-0 buttonsSide col-md-6 col-sm-12 d-flex justify-content-end align-items-center">
                <button
                  className="btn btn-primary me-4 rounded-1 px-4 py-2"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <i className="fa-solid fa-plus bg-light bg-opacity-25 p-1 rounded-1 newModel" />
                  Add Goal
                </button>
                {/* Modal Code Start */}
                <div
                  className="modal fade"
                  id="exampleModal"
                  tabIndex={-1}
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content border-0 rounded-2 p-3">
                      <div className="modal-header flex-column align-items-start border-0 pb-3">
                        <h3 id="exampleModalLabel">New Goal</h3>
                        <p className="text-muted">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Nulla auctor. Sit amet, consectetur adipiscing
                          consectetur adipiscing elit.
                        </p>
                      </div>
                      {message}
                      <form onSubmit={createGoal}>
                        <div className="modal-body pb-0 pt-0">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              id="floatingInputValue"
                              placeholder="Name Your Goal"
                              name="name"
                              required
                            />
                            <label htmlFor="floatingInputValue">
                              GOAL NAME
                            </label>
                          </div>
                          <div className="form-floating mb-3">
                            <textarea
                              className="form-control modelTextarea"
                              placeholder="Describe your goal"
                              id="floatingTextarea2"
                              name="description"
                              required
                            />
                            <label htmlFor="floatingTextarea2" className="pb-2">
                              DESCRIPTION
                            </label>
                          </div>
                          <div className="row g-2">
                            <div className="col-md">
                              <div className="form-floating">
                                <input
                                  type="date"
                                  className="form-control"
                                  id="floatingInputGrid"
                                  placeholder="Select Date"
                                  name="startDate"
                                  required
                                />
                                <label htmlFor="floatingInputGrid">
                                  START DATE
                                </label>
                                {/* <i class="fa-solid fa-calendar-days text-muted dateIcon"></i> */}
                              </div>
                            </div>
                            <div className="col-md">
                              <div className="form-floating">
                                <input
                                  type="date"
                                  className="form-control"
                                  id="floatingInputGrid"
                                  placeholder="Select Date"
                                  name="endDate"
                                  required
                                />
                                <label htmlFor="floatingInputGrid">
                                  END DATE
                                </label>
                                {/* <i class="fa-solid fa-calendar-days text-muted dateIcon"></i> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer justify-content-end border-0">
                          <button
                            type="button"
                            className="btn btn-outline-danger rounded-1 px-4"
                            data-bs-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary rounded-1 px-4"
                          >
                            Create {loader && <Loader />}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                {/* Modal Code End */}
              </div>
            </div>
            {/* Heading Section End */}
            {goals.map((goal, index) => {
              const percentage =
                goal?.keyresults?.reduce(
                  (acc, cur) =>
                    acc +
                    ((cur.metrics.at(-1)?.value - cur?.startValue) /
                      (cur?.targetValue - cur?.startValue)) *
                      100,
                  0
                ) / goal?.keyresults?.length || 0;
              return (
                <div className="row goalsTable">
                  <div className="col-12 p-0  ps-3">
                    <table className="table table-borderless table-responsive p-2 modelsTab goalsTable-Tab m-0">
                      {index === 0 && (
                        <thead>
                          <tr>
                            <td scope="col" className="text-secondary">
                              Goal
                            </td>
                            <td scope="col" className="text-secondary">
                              Date
                            </td>
                            <td scope="col" className="text-secondary">
                              Member
                            </td>
                            <td scope="col" className="text-secondary">
                              Progress & update{" "}
                              <img src="/assets/upDownIcon.png" alt="" />
                            </td>
                            <td scope="col" className="text-secondary">
                              Status
                            </td>
                            <td scope="col" className="text-secondary">
                              &nbsp;
                            </td>
                          </tr>
                        </thead>
                      )}

                      <tbody className="position-relative ">
                        <tr className="border border-dark border-opacity-25 align-middle">
                          <td className="w-25 ps-4">{goal.name}</td>
                          <td>{new Date(goal.createdAt).toDateString()}</td>
                          <td>
                            {goal?.members?.map((member) => (
                              <Avatar
                                name={member.name}
                                round
                                size="30"
                                className="me-1"
                              />
                            ))}
                          </td>
                          <td
                            style={{
                              width: "250px",
                            }}
                          >
                            <p
                              className="p-0 m-0 mb-3 pb-2 text-warning border-0 border-bottom border-3 border-warning"
                              style={
                                percentage > 100
                                  ? { width: "100%" }
                                  : { width: percentage + "%" }
                              }
                            >
                              {percentage?.toFixed(2)}%
                            </p>
                            <span>
                              Updated on{" "}
                              {new Date(goal.updatedAt).toDateString()}
                            </span>
                          </td>
                          <td>
                            <select
                              className={
                                goal.status === "On Track"
                                  ? "form-select px-2 py-2 border-primary text-primary"
                                  : goal.status === "At Risk"
                                  ? "form-select px-2 py-2 border-danger text-danger"
                                  : goal.status === "Has Issues"
                                  ? "form-select px-2 py-2 border-warning text-warning"
                                  : goal.status === "Done"
                                  ? "form-select px-2 py-2 border-secondary text-secondary"
                                  : "form-select px-2 py-2 border-primary text-primary"
                              }
                              aria-label="Default select example"
                              name="status"
                              value={goal.status}
                              onChange={(e) => {
                                setGoal1({
                                  id: goal._id,
                                  status: e.target.value,
                                });
                              }}
                            >
                              <option value="On Track" className="text-danger">
                                On Track
                              </option>
                              <option value="At Risk" className="text-danger">
                                At Risk
                              </option>
                              <option
                                value="Has Issues"
                                className="text-warning"
                              >
                                Has Issues
                              </option>
                              <option value="Done" className="text-secondary">
                                Done
                              </option>
                            </select>
                          </td>
                          <td>
                            {/* Dropdown start */}
                            <div className="dropdown">
                              <button
                                id="dropdownMenuButton1"
                                className="border-0 bg-transparent"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fa-solid fa-ellipsis-vertical" />
                              </button>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                <li>
                                  <Link
                                    to={`/goal/${goal._id}`}
                                    className="dropdown-item"
                                  >
                                    Open
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to=""
                                    className="dropdown-item"
                                    data-bs-toggle="modal"
                                    data-bs-target="#manage"
                                    onClick={() => {
                                      setGoal1(goal);
                                      const selectedOptions = [];
                                      goal.members.forEach((member) => {
                                        selectedOptions.push({
                                          value: member._id,
                                          label: member.name,
                                        });
                                      });
                                      setSelectedOption(selectedOptions);
                                    }}
                                  >
                                    Assign User
                                  </Link>
                                </li>

                                <li>
                                  <Link
                                    className="dropdown-item text-danger"
                                    to=""
                                    onClick={() => {
                                      deleteGoal(goal._id);
                                    }}
                                  >
                                    Delete
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            {/* Dropdown End */}
                          </td>
                        </tr>
                        <tr className="sideIcon">
                          <td>
                            <i
                              className={
                                goalIndex === index
                                  ? "p-1 border border-dark border-opacity-25 rounded-1 bg-light fa-solid fa-minus"
                                  : "p-1 border border-dark border-opacity-25 rounded-1 bg-light fa-solid fa-plus"
                              }
                              id="accordionBtn"
                              onClick={() => {
                                setGoal(goal);
                                setGoalIndex(
                                  goalIndex !== index ? index : null
                                );
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="p-0">
                            <br />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {goalIndex === index && <Keyresult goal={goal} />}
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <Modal id="manage" title="Manage Access">
        <div className="modal-body">
          {alert}
          <Select
            value={selectedOption}
            onChange={setSelectedOption}
            options={options}
            isMulti={true}
          />
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button type="button" className="btn btn-primary" onClick={addMember}>
            Save changes
          </button>
        </div>
      </Modal>
    </MainLayout>
  );
};

export default Goals;
