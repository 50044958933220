import React from "react";
import Sidebar from "../../../components/Sidebar";
import MainLayout from "../../../layout/MainLayout";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { server, config } from "../../../env";
import { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import Select from "react-select";

const EditTask = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [goals, setGoals] = useState([]);
  const [task, setTask] = useState({});
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [projectId, setProjectId] = useState(localStorage.getItem("project"));
  const [checklist, setChecklist] = useState([]);

  useEffect(() => {
    readTask();
    readProject();
  }, []);

  const readGoals = async () => {
    const id = localStorage.getItem("project");
    await axios
      .get(`${server}/goal/getAll/${id}`, config)
      .then((res) => {
        setGoals(res.data.goals);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const readTask = async () => {
    await axios
      .get(server + "/task/read/" + id, config)
      .then((res) => {
        setChecklist(res.data.task.checklist);
        const startDate = new Date(res.data.task.startDate);
        const endDate = new Date(res.data.task.endDate);
        const startDateString = startDate.toISOString().split("T")[0];
        const endDateString = endDate.toISOString().split("T")[0];
        res.data.task.startDate = startDateString;
        res.data.task.endDate = endDateString;
        setTask(res.data.task);
        readGoals(res.data.task.project);
        const selectedOptions = [];
        res.data?.task?.teammates.forEach((teammate) => {
          selectedOptions.push({
            value: teammate._id,
            label: teammate.name,
          });
        });
        setSelectedOption(selectedOptions);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTask({ ...task, [name]: value });
  };
  const readProject = async () => {
    const projectId = localStorage.getItem("project");

    await axios
      .get(`${server}/project/readOne/${projectId}`, config)
      .then((res) => {
        const projectUsers = res.data.users;
        const options = [];
        projectUsers.forEach((user) => {
          options.push({ value: user._id, label: user.name });
        });
        setOptions(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateTask = async (e) => {
    e.preventDefault();

    const id = localStorage.getItem("project");

    const teammates = [];
    selectedOption.forEach((teammate) => {
      teammates.push(teammate.value);
    });
    const data = {
      ...task,
      teammates: teammates,
    };

    await axios
      .put(server + "/task/update/" + task._id, data, config)
      .then((res) => {
        navigate(`/projects/${id}/tasks`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <MainLayout>
      <section className="mainContent">
        <div className="row">
          <Sidebar />
          <div className="goalNameSection UntitledIdea pt-4 ps-5">
            <div className="row px-3 pt-4">
              <Link to={`/projects/${projectId}/tasks`}>
                <p className="p-0 mb-3 backBtn">
                  <i className="fa-solid fa-arrow-left-long" />
                  &nbsp; All Tasks
                </p>
              </Link>
              <div className="row mb-3">
                <div className="form-floating form-floating-custom col-lg-6 ps-0">
                  <input
                    type="text"
                    className="form-control bg-transparent"
                    value={task.name}
                    name="name"
                    onChange={handleChange}
                    id="floatingInputValue"
                  />
                  <label htmlFor="floatingSelect" className="pt-3">
                    Name
                  </label>
                </div>
                <div className="col-lg-5 p-0 m-0 d-flex align-items-center justify-content-end">
                  <button className="btn btn-outline-danger py-2 px-4 me-2 rounded-1">
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary py-2 px-4 rounded-1"
                    onClick={(e) => {
                      updateTask(e);
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
              <div className="form-floating col-lg-11 ps-0 mb-3">
                <select
                  className="form-select selectOpt"
                  id="floatingSelect"
                  aria-label="Floating label select example"
                  name="lever"
                  value={task.lever}
                  onChange={handleChange}
                  required
                >
                  <option selected>Select Lever</option>
                  <option value="Acquisition">Acquisition</option>
                  <option value="Activation">Activation</option>
                  <option value="Retention">Retention</option>
                </select>
                <label htmlFor="floatingSelect" className="pt-3">
                  LEVER
                </label>
              </div>
              <div className="form-floating form-floating-custom col-lg-11 ps-0 mb-3">
                <select
                  name="goal"
                  value={task.goal?._id}
                  onChange={handleChange}
                  id=""
                  className="form-control bg-transparent"
                >
                  <option>Select Goal</option>
                  {goals.map((goal) => (
                    <option value={goal._id}>{goal.name}</option>
                  ))}
                </select>
                <label htmlFor="floatingSelect" className="pt-3">
                  Goal
                </label>
              </div>
              <div className="form-floating form-floating-custom col-lg-11 ps-0 mb-3 position-relative">
                <input
                  type="text"
                  className="form-control bg-transparent"
                  id="floatingInputValue"
                  disabled=""
                />
                <label htmlFor="floatingInputValue">Members</label>
                {/* <input type="file" name="myfile" class="fileField"/> */}
                <div className="p-0 m-0 position-absolute d-flex gap-1 teamMatesBadge">
                  {selectedOption?.map((option) => (
                    <p className="m-0 p-0 border-0 rounded-1 px-2">
                      <img
                        src="/assets/account.png"
                        alt=""
                        className="avatar"
                      />
                      &nbsp; {option.label}
                    </p>
                  ))}

                  <i
                    className="fa-solid fa-edit fa-lg text-primary right-0 top-0 mt-2 mr-2"
                    data-bs-toggle="modal"
                    data-bs-target="#addTeamMateModal"
                  />
                </div>
              </div>
              <div className="row col-lg-11 g-2 p-0 m-0 mb-3">
                <div className="col-md ps-0 pe-2">
                  <div className="form-floating form-floating-custom">
                    <input
                      type="date"
                      className="form-control"
                      id="floatingInputGrid"
                      placeholder="START DATE"
                      defaultValue={task.startDate}
                      name="startDate"
                    />
                    <label htmlFor="floatingInputGrid">Start date</label>
                    {/* <i class="fa-solid fa-calendar-days text-muted dateIcon"></i> */}
                  </div>
                </div>
                <div className="col-md ps-0 pe-3">
                  <div className="form-floating form-floating-custom">
                    <input
                      type="date"
                      className="form-control"
                      id="floatingInputGrid"
                      placeholder="END DATE"
                      defaultValue={task.endDate}
                      name="endDate"
                    />
                    <label htmlFor="floatingInputGrid">Due Date</label>
                    {/* <i class="fa-solid fa-calendar-days text-muted dateIcon"></i> */}
                  </div>
                </div>
              </div>
              <div className="form-floating col-lg-11 ps-0 mb-3">
                <textarea
                  className="form-control modelTextarea mt-2"
                  placeholder="Describe your goal"
                  id="floatingTextarea2"
                  defaultValue={task.description}
                  name="description"
                />
                <label htmlFor="floatingTextarea2" className="pb-2">
                  DESCRIPTION
                </label>
              </div>
              <div className="form-floating col-lg-11 ps-0 mb-3">
                <textarea
                  className="form-control modelTextarea mt-2"
                  placeholder="Describe your goal"
                  id="floatingTextarea2"
                  defaultValue={task.hypothesis}
                  name="hypothesis"
                />
                <label htmlFor="floatingTextarea2" className="pb-2">
                  Hypothesis
                </label>
              </div>
              <div className="col-lg-11 m-0 ps-0 mb-3">
                <div className="p-0 pt-2 pb-4 px-3 m-0 border rounded-1 checklist">
                  <p className="p-0 m-0 mb-3 checklist-label">CHECKLIST</p>
                  {checklist?.map((list) => (
                    <div className="form-check ">
                      <input
                        className="form-check-input border border-2 border-secondary"
                        type="checkbox"
                        id="cancelBtn"
                        checked={list.status}
                        onChange={() => {
                          checklist[
                            checklist.indexOf(list)
                          ].status = !list.status;
                          setChecklist([...checklist]);
                        }}
                      />

                      <label
                        className="form-check-label"
                        htmlFor="flexCheckChecked"
                        id="textTocancel"
                      >
                        {list.name}
                      </label>
                      {/* /deleteIcon */}
                      <i
                        className="ms-3 fa-solid fa-trash-alt text-danger right-0 top-0 mt-2 mr-2"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          checklist.splice(checklist.indexOf(list), 1);
                          setChecklist([...checklist]);
                        }}
                      />
                    </div>
                  ))}
                  <div className="p-0 m-0 w-25 pe-5 mt-4">
                    <span className="p-0 py-2 px-3 m-0 bg-secondary bg-opacity-25 rounded-1">
                      Add a subtask{" "}
                      <i
                        className="fa-solid fa-plus bg-secondary bg-opacity-75 text-white p-1 ms-2 rounded-1"
                        data-bs-toggle="modal"
                        data-bs-target="#addSubTaskModal"
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-11 p-0 pt-2 pb-4 px-3 m-0 mt-2 mb-4 border dashed">
                <p className="p-0 m-0 mb-3 text-muted">FILES</p>
                <div className="p-0 m-0 d-flex gap-2">
                  {task.files?.map((file, index) => (
                    <div className="p-0 m-0 position-relative">
                      <img
                        src={server + "/" + file}
                        className="img-thumbnail"
                        alt="sds"
                        style={{
                          width: "100px",
                          height: "100%",
                        }}
                      />
                      <div className="dropdown">
                        <img
                          src="/assets/opendialogBtn.svg"
                          className="position-absolute openDialogIcon"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        />
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <a className="dropdown-item" href="#">
                              View
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              Download
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  ))}

                  <div className="m-0 position-relative uploadBox">
                    <div className="p-0 m-0 text-center">
                      <img src="/assets/uploadIcon.svg" />
                      <p className="p-0 m-0 mt-2">Upload</p>
                      <input type="file" name="myfile" className="fileField" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal id="addTeamMateModal" title="Manage Teammates">
        <div className="modal-body">
          <Select
            value={selectedOption}
            onChange={setSelectedOption}
            options={options}
            isMulti={true}
          />
        </div>
      </Modal>
      <Modal id="addSubTaskModal" title="Add subtask modal">
        <div className="modal-body">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setChecklist(
                checklist.concat([
                  {
                    name: e.target.checklist.value,
                    status: false,
                  },
                ])
              );
            }}
          >
            <div className="form-group">
              <label htmlFor="subTaskName">Subtask Name</label>
              <input
                type="text"
                className="form-control"
                id="subTaskName"
                placeholder="Enter subtask name"
                name="checklist"
              />
            </div>
            <div className="d-flex justify-content-end">
              <button className="btn btn-primary mt-3" type="submit">
                Add
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </MainLayout>
  );
};

export default EditTask;
