import React, { useState, useEffect } from "react";
import MainLayout from "../../layout/MainLayout";
import SettingSidebar from "../../components/SettingSidebar";
import axios from "axios";
import { server, config } from "../../env";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";
import Modal from "../../components/Modal";

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [write, setWrite] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [projects, setProjects] = useState([]);
  const [userProjects, setUserProjects] = useState([]);
  const [user, setUser] = useState({});

  useEffect(() => {
    getUsers();
    getProjects();
  }, []);

  const getUsers = async () => {
    await axios.get(server + "/management/readUsers", config).then((res) => {
      setUsers(res.data);
    });
  };

  const createUser = async (e) => {
    e.preventDefault();
    setLoader(true);
    const data = {
      name: e.target.name.value,
      email: e.target.email.value,
      password: e.target.password.value,
      access: {
        read: true,
        write: write,
      },
      organization: e.target.organization.value,
    };
    await axios
      .post(server + "/management/createUser", data, config)
      .then((res) => {
        setLoader(false);
        setMessage(<Alert className="success" message={res.data.message} />);
        getUsers();
      })
      .catch((err) => {
        setLoader(false);
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  const getProjects = async () => {
    await axios
      .get(server + "/project/read", config)
      .then((rsp) => {
        setProjects(rsp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const readUserProjects = async (data) => {
    console.log(user);
    await axios
      .get(server + "/management/readUserProjects/" + data._id, config)
      .then((res) => {
        setUserProjects(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addUserInProject = async (e) => {
    e.preventDefault();

    const data = {
      userId: user._id,
      projectId: e.target.project.value,
    };

    await axios
      .post(server + "/management/addUserInProject", data, config)
      .then((res) => {
        setMessage(<Alert className="success" message={res.data.message} />);
        readUserProjects();
      })
      .catch((err) => {
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  return (
    <MainLayout>
      <section className="mainContent">
        <div className="row">
          <SettingSidebar />
          <div className="projectNameSection userManagementSection pt-5 ps-5">
            {/* Heading Section Start */}
            <div className="row mb-5 mt-1">
              <div className="p-0 headingSide col-md-6 col-sm-12">
                <h1 className="ps-0">User Management</h1>
                <p className="p-0 m-0 text-muted">
                  Add or remove team members and clients
                </p>
              </div>
              <div className="p-0 buttonsSide col-md-6 col-sm-12 d-flex justify-content-end align-items-center">
                <button
                  className="btn btn-primary me-4 rounded-1 px-4 py-2"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <i className="fa-solid fa-plus p-1 rounded-1 newModel" />
                  Create User
                </button>
                {/* Modal Code Start */}
                <div
                  className="modal fade"
                  id="exampleModal"
                  tabIndex={-1}
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content border-0 rounded-2 p-3">
                      <div className="modal-header flex-column align-items-start border-0 pb-3">
                        <h3 id="exampleModalLabel">New Member</h3>
                        <p className="text-muted">Add a new team memeber</p>
                      </div>
                      {message}
                      <form onSubmit={createUser}>
                        <div className="modal-body pb-0 pt-0">
                          <div className="form-group mb-3">
                            <label htmlFor="floatingInputValue">Name</label>
                            <input
                              type="text"
                              className="form-control bg-light"
                              id="floatingInputValue"
                              placeholder="Name"
                              name="name"
                            />
                          </div>
                          <div className="form-group  mb-3">
                            <label htmlFor="floatingInputValue">Email</label>
                            <input
                              type="email"
                              className="form-control bg-light"
                              id="floatingInputValue"
                              placeholder="User email"
                              name="email"
                            />
                          </div>
                          <div className="form-group  mb-3">
                            <label htmlFor="">Password</label>
                            <div className="input-group">
                              <input
                                type={showPassword ? "text" : "password"}
                                className="form-control bg-light"
                                id="floatingInputValue"
                                placeholder="Password"
                                name="password"
                              />
                              <span
                                className="input-group-text"
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {showPassword ? (
                                  <i className="fa-solid fa-eye" />
                                ) : (
                                  <i className="fa-solid fa-eye-slash" />
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="form-group  mb-3">
                            <label htmlFor="floatingInputValue">
                              Organization Name
                            </label>
                            <input
                              type="text"
                              className="form-control bg-light"
                              id="floatingInputValue"
                              placeholder="Organization Name"
                              name="organization"
                            />
                          </div>

                          <p className="p-0">Access</p>
                          <div className="d-flex justify-content-center gap-4 mb-2">
                            <div className=" py-2 px-3 d-flex gap-4 justify-content-between align-items-center border rounded-1 bg-light">
                              <p className="p-0 m-0">Read</p>
                              <input
                                className="form-check-input ms-2"
                                type="checkbox"
                                id="highlightBtn"
                                checked
                              />
                            </div>
                            <div className=" py-2 px-3 d-flex gap-4 justify-content-between align-items-center border rounded-1 bg-light">
                              <p className="p-0 m-0">Write</p>
                              <input
                                className="form-check-input ms-2"
                                type="checkbox"
                                id="highlightBtn"
                                checked={write}
                                onChange={() => setWrite(!write)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer justify-content-start border-0 ">
                          <button
                            type="submit"
                            className="btn bg-primary text-white  border-0 rounded-1 px-4"
                          >
                            Invite {loader && <Loader />}
                          </button>
                          <button
                            type="reset"
                            className="btn btn-outline-danger rounded-1 px-4"
                            data-bs-dismiss="modal"
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                {/* Modal Code End */}
              </div>
            </div>
            {/* Heading Section End */}
            {/* Table + Client Section Start */}
            <secton className="model-section">
              <div className="container">
                <div className="row mb-5">
                  <div className="col-lg-12 p-0">
                    <p className="p-0 fw-semibold">
                      Total Users ({users.length})
                    </p>
                  </div>
                  <div className="col-12 p-0 table-responsive">
                    {/* Table Starting */}
                    <table className="table table-borderless table-hover p-2 m-0 bg-white">
                      <thead className="border-0 border-bottom sticky-top bg-white">
                        <tr>
                          <td className="text-secondary">Name</td>
                          <td className="text-secondary">Organization</td>
                          <td className="text-secondary">Email</td>
                          <td className="text-secondary">Access</td>
                          <td className="text-secondary">Action</td>
                        </tr>
                      </thead>
                      <tbody className="border-0">
                        {users.map((user, index) => (
                          <>
                            <tr
                              key={index}
                              style={{
                                height: "60px",
                              }}
                            >
                              <td>{user.name}</td>
                              <td>{user.organization}</td>
                              <td>{user.email}</td>
                              <td>
                                {user.access.write ? (
                                  <>
                                    <span class="badge rounded-pill text-bg-success me-2">
                                      Write
                                    </span>
                                    <span class="badge rounded-pill text-bg-info text-white">
                                      Read
                                    </span>
                                  </>
                                ) : (
                                  <span class="badge rounded-pill text-bg-info text-white">
                                    Read
                                  </span>
                                )}
                              </td>
                              <td>
                                <button
                                  className="btn btn-sm btn-warning text-white me-2"
                                  data-bs-toggle="modal"
                                  data-bs-target="#manage"
                                  onClick={() => {
                                    // set user state and call readuserproject function
                                    setUser(user);
                                    readUserProjects(user);
                                  }}
                                >
                                  Manage
                                </button>
                                <button
                                  className="btn btn-sm btn-danger text-white"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete"
                                >
                                  {/* trash icon */}
                                  <i className="fa-solid fa-trash" />
                                </button>
                              </td>
                            </tr>
                            {/* <tr className="lh-1">
                              <td className="p-0">
                                <br />
                              </td>
                            </tr> */}
                          </>
                        ))}
                      </tbody>
                    </table>
                    {/* Table Ending */}
                  </div>
                </div>
              </div>
            </secton>
            {/* Table + Client Section End */}
          </div>
        </div>
      </section>
      <Modal id="manage" title="Manage Permissions">
        <div className="modal-body">
          <form onSubmit={addUserInProject}>
            {message}
            <div className="form-group">
              <label htmlFor="project">Project</label>
              <select name="project" className="form-control">
                <option value="">Select Project</option>
                {projects.map((project, index) => (
                  <option value={project._id}>{project.name}</option>
                ))}
              </select>
            </div>

            <div className="form-group text-end mt-3">
              <button className="btn btn-info text-white" type="submit">
                <i className="fa-solid fa-plus" /> Add {loader}
              </button>
            </div>
          </form>
          {/* list of projects */}
          <h5>Projects Assigned</h5>
          <div className="row">
            <div className="col-12">
              <div className="table-responsive ">
                <table className="table table-borderless table-hover p-2 m-0 bg-white">
                  <thead className="border-0 border-bottom sticky-top bg-white">
                    <tr>
                      <td className="text-secondary">Project</td>
                      <td className="text-secondary">Access</td>
                      <td className="text-secondary">Action</td>
                    </tr>
                  </thead>
                  <tbody className="border-0">
                    {userProjects?.map((project, index) => (
                      <tr key={index}>
                        <td>{project.name}</td>
                        <td>write</td>
                        <td>
                          <button className="btn btn-sm btn-danger text-white">
                            <i className="fa-solid fa-trash" /> Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </MainLayout>
  );
};

export default UserManagement;
