import React, { useEffect, useState } from "react";
import MainLayout from "../../layout/MainLayout";
import axios from "axios";
import { server, config } from "../../env";
import { Link } from "react-router-dom";
import Alert from "../../components/Alert";
import Loader from "../../components/Loader";
import Avatar from "react-avatar";

const Project = () => {
  const [projects, setProjects] = useState([]);
  const [loader, setLoader] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    getProjects();
  }, []);

  const getProjects = async (search) => {
    var url = "";
    if (search) {
      url = `${server}/project/read?search=${search}`;
    } else {
      url = server + "/project/read";
    }

    await axios
      .get(url, config)
      .then((rsp) => {
        setProjects(rsp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createProject = async (e) => {
    e.preventDefault();

    setLoader(<Loader />);
    const data = {
      name: e.target.name.value,
      description: e.target.description.value,
      startDate: e.target.startDate.value,
      endDate: e.target.endDate.value,
    };

    await axios
      .post(server + "/project/create", data, config)
      .then((rsp) => {
        setMessage(<Alert className="success" message={rsp.data.message} />);
        getProjects();
        setLoader("");
      })
      .catch((err) => {
        if (err.response) {
          setMessage(
            <Alert className="danger" message={err.response.data.message} />
          );
          setLoader("");
        }
      });
  };

  // mark completed
  const markCompleted = async (id) => {
    await axios
      .post(server + "/project/markAsCompleted/" + id, {}, config)
      .then((rsp) => {
        getProjects();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // delete project
  const deleteProject = async (id) => {
    await axios
      .delete(server + "/project/delete/" + id, config)
      .then((rsp) => {
        getProjects();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <MainLayout>
      {/* Model Heading Start */}
      <section className="model-heading tools-heading allProjectsHeading">
        <div className="container">
          <div className="row ps-4 pad">
            <div className="p-0 headingSide col-md-6 col-sm-12">
              <h1 className="ps-0">All Projects</h1>
              <p className="ps-0 mb-1 text-muted">{projects.length} Projects</p>
            </div>
            <div className="p-0 buttonsSide col-md-6 col-sm-12 d-flex flex-column justify-content-center align-items-end">
              <div className="p-0 mb-3">
                {/* <a href="">
                  <span className="me-4">
                    <img src="/assets/listIcon.png" />
                  </span>
                </a>
                <a href="">
                  <span className="me-4">
                    <img src="/assets/listIcon2.png" />
                  </span>
                </a> */}
              </div>
              <div className="p-0 d-flex align-items-center">
                <button type="button" className="btn btnOne border-0 me-1 px-0">
                  <div className="input-group">
                    <span
                      className="input-group-text border-end-0 bg-transparent"
                      id="basic-addon1"
                    >
                      <i className="fa-solid fa-magnifying-glass text-muted" />
                    </span>
                    <input
                      type="text"
                      className="form-control w-25 border-start-0 searchBar py-2"
                      placeholder="Select Project"
                      name="search"
                      onChange={(e) => {
                        getProjects(e.target.value);
                      }}
                    />
                  </div>
                </button>
                <button
                  className="btn btn-primary me-4 rounded-1 py-2 px-3"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <i className="fa-solid fa-plus bg-light bg-opacity-25 p-1 rounded-1 newModel me-2" />
                  Add Project
                </button>
                {/* Modal Code Start */}
                <div
                  className="modal fade"
                  id="exampleModal"
                  tabIndex={-1}
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <form onSubmit={createProject}>
                      <div className="modal-content border-0 rounded-2 p-3">
                        <div className="modal-header flex-column align-items-start border-0 pb-3">
                          <h3 id="exampleModalLabel">New Project</h3>

                          {message}
                        </div>
                        <div className="modal-body pb-0">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              id="floatingInputValue"
                              placeholder="Select growth"
                              name="name"
                            />
                            <label htmlFor="floatingInputValue">
                              PROJECT NAME
                            </label>
                          </div>
                          <div className="form-floating mb-3">
                            <textarea
                              className="form-control modelTextarea"
                              placeholder="Describe your goal"
                              id="floatingTextarea2"
                              name="description"
                            />
                            <label htmlFor="floatingTextarea2" className="pb-2">
                              DESCRIPTION
                            </label>
                          </div>
                          <div className="row g-2">
                            <div className="col-md">
                              <div className="form-floating">
                                <input
                                  type="date"
                                  className="form-control"
                                  id="floatingInputGrid"
                                  placeholder="START DATE"
                                  name="startDate"
                                />
                                <label htmlFor="floatingInputGrid">
                                  Start Date
                                </label>
                                {/* <i class="fa-solid fa-calendar-days text-muted dateIcon"></i> */}
                              </div>
                            </div>
                            <div className="col-md">
                              <div className="col-md">
                                <div className="form-floating">
                                  <input
                                    type="date"
                                    className="form-control"
                                    id="floatingInputGrid"
                                    placeholder="END DATE"
                                    name="endDate"
                                  />
                                  <label htmlFor="floatingInputGrid">
                                    End Date
                                  </label>
                                  {/* <i class="fa-solid fa-calendar-days text-muted dateIcon"></i> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer justify-content-end border-0">
                          <button
                            type="button"
                            className="btn btn-outline-danger rounded-1"
                            data-bs-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary rounded-1 customBtn"
                          >
                            Create {loader}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                {/* Modal Code End */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Model Heading End */}
      {/* Table Section Start */}
      <section className="model-section allProjects">
        <div className="container">
          <div className="row ps-4 pad">
            <div className="p-0 ">
              {/* Table Starting */}
              <table className="table table-borderless table-responsive modelsTab">
                <thead>
                  <tr>
                    <td scope="col" className="text-secondary">
                      Name
                    </td>
                    <td scope="col" className="text-secondary">
                      Created On &nbsp;
                      <i className="fa-solid fa-arrow-up" />
                    </td>
                    <td scope="col" className="text-secondary">
                      Owner
                    </td>
                    <td scope="col" className="text-secondary">
                      Members
                    </td>
                    <td scope="col" className="text-secondary">
                      Total Tasks &nbsp;
                      <i className="fa-solid fa-arrow-up" />
                    </td>
                    <td scope="col" className="text-secondary">
                      Status
                    </td>
                    <td scope="col" className="text-secondary">
                      &nbsp;
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {projects.map((project, index) => (
                    <>
                      <tr className="border">
                        <td scope="row">{project.name}</td>
                        <td>{new Date(project.createdAt).toDateString()}</td>
                        <td>
                          <Avatar
                            name={project?.owner?.name}
                            round
                            size="20"
                            className="me-1"
                          />
                          {project?.owner?.name}
                        </td>
                        <td>
                          {project?.users?.map((member, index) => (
                            <Avatar
                              name={member.name}
                              round
                              size="30"
                              className="me-1"
                            />
                          ))}
                        </td>
                        <td>04</td>
                        <td>
                          {project?.status ? (
                            <button className="btn w-75 text-primary border-0 bg-primary bg-opacity-25 rounded-1">
                              Active
                            </button>
                          ) : (
                            <button className="btn w-75 text-success border-0 bg-success bg-opacity-25 rounded-1">
                              Completed
                            </button>
                          )}
                        </td>
                        <td>
                          {/* Dropdown start */}
                          <div className="dropdown">
                            <button
                              id="dropdownMenuButton1"
                              className="border-0 bg-transparent"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="fa-solid fa-ellipsis-vertical" />
                            </button>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={() => {
                                    localStorage.setItem(
                                      "project",
                                      project._id
                                    );
                                  }}
                                  to={"/projects/" + project._id + "/goals"}
                                  state={{ project: project }}
                                >
                                  Open
                                </Link>
                              </li>
                              {project.status ? (
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to=""
                                    onClick={() => markCompleted(project._id)}
                                  >
                                    Mark Complete
                                  </Link>
                                </li>
                              ) : (
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to=""
                                    onClick={() => markCompleted(project._id)}
                                  >
                                    Mark Active
                                  </Link>
                                </li>
                              )}

                              <li>
                                <Link
                                  to=""
                                  className="dropdown-item text-danger"
                                  onClick={() => deleteProject(project._id)}
                                >
                                  Delete
                                </Link>
                              </li>
                            </ul>
                          </div>
                          {/* Dropdown End */}
                        </td>
                      </tr>
                      <tr className="lh-1">
                        <td className="p-0">
                          <br />
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
              {/* Table Ending */}
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  );
};

export default Project;
