import React from "react";
import Sidebar from "../../../components/Sidebar";
import MainLayout from "../../../layout/MainLayout";
import { Link } from "react-router-dom";
import axios from "axios";
import { server, config } from "../../../env";
import { useParams, useNavigate } from "react-router-dom";
import Avatar from "react-avatar";

const Ideas = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const [projectName, setProjectName] = React.useState("");
  const [ideas, setIdeas] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    const readName = () => {
      axios
        .get(`${server}/project/readName/${id}`, config)
        .then((res) => {
          setProjectName(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    readName();
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const res = await axios.get(`${server}/idea/getall/${id}`, config);
      setIdeas(res.data);
      setLoading(false);
    } catch (err) {
      setError(true);
    }
  };

  const deleteIdea = async (id) => {
    try {
      await axios.delete(`${server}/idea/delete/${id}`, config);
      fetchData();
    } catch (err) {
      console.log(err);
    }
  };

  const duplicateIdea = async (id) => {
    await axios
      .post(`${server}/idea/duplicate/${id}`, {}, config)
      .then((res) => {
        fetchData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <MainLayout>
      <section className="mainContent">
        <div className="row">
          <Sidebar />
          <div className="goalNameSection projectNameSection pt-4 ps-5">
            {/* Heading Section Start */}
            <div className="row mb-5 mt-1">
              <div className="p-0 headingSide col-md-6 col-sm-12">
                <Link to="/projects">
                  <p className="ps-0 mb-1 backBtn">
                    <i className="fa-solid fa-arrow-left-long" />
                    &nbsp; All Projects
                  </p>
                </Link>
                <h1 className="ps-0">{projectName}</h1>
                <p className="p-0 m-0 text-muted">{ideas.length} Ideas</p>
              </div>
              <div className="p-0 buttonsSide col-md-6 col-sm-12 d-flex justify-content-end align-items-center">
                <Link
                  to={"/projects/" + id + "/ideas/new"}
                  className="btn btn-primary me-4 rounded-1 px-4 py-2"
                >
                  <i className="fa-solid fa-plus bg-light bg-opacity-25 p-1 rounded-1 newModel" />
                  Add Idea
                </Link>
              </div>
            </div>
            {/* Heading Section End */}
            {/* Table Section Start */}
            <section className="model-section">
              <div className="container">
                <div className="row">
                  <div className="col-12 p-0 modelsTab">
                    {/* Table Starting */}
                    <table className="table table-borderless p-2 m-0">
                      <thead>
                        <tr>
                          <td scope="col" className="text-secondary">
                            Idea
                          </td>
                          <td scope="col" className="text-secondary">
                            Date
                          </td>
                          <td scope="col" className="text-secondary">
                            Creator
                          </td>
                          <td scope="col" className="text-secondary">
                            Member
                          </td>
                          <td scope="col" className="text-secondary">
                            Lever
                          </td>
                          <td scope="col" className="text-secondary">
                            ICE Score
                          </td>
                          <td scope="col" className="text-secondary">
                            &nbsp;
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {ideas.map((idea, index) => (
                          <>
                            <tr className="border" key={index}>
                              <td scope="row" className="w-25">
                                {idea.name}
                              </td>
                              <td>
                                {new Date(idea.createdAt).toLocaleDateString()}
                              </td>
                              <td>
                                <Avatar
                                  name={idea?.owner?.name}
                                  round
                                  size="30"
                                />
                                &nbsp; {idea.owner?.name}
                              </td>
                              <td>
                                {idea.teammates?.map((teammate, index) => (
                                  <Avatar
                                    name={teammate.name}
                                    round
                                    size="30"
                                    className="me-1"
                                  />
                                ))}

                                {idea.teammates?.length === 0 && (
                                  <p className="text-muted">No Members</p>
                                )}
                              </td>
                              <td>
                                {idea.lever === "Acquisition" ? (
                                  <button className="btn border-0 text-warning bg-warning bg-opacity-25 rounded-1">
                                    {idea.lever}
                                  </button>
                                ) : idea.lever === "Activation" ? (
                                  <button className="btn border-0 text-primary bg-primary bg-opacity-25 rounded-1">
                                    {idea.lever}
                                  </button>
                                ) : (
                                  <button className="btn border-0 text-danger bg-danger bg-opacity-25 rounded-1">
                                    {idea.lever}
                                  </button>
                                )}
                              </td>
                              <td>
                                <button className="btn border-0 px-1 text-white bg-secondary bg-opacity-50 rounded-1">
                                  {idea.score}
                                </button>
                              </td>
                              <td>
                                {/* Dropdown start */}
                                <div className="dropdown">
                                  <button
                                    id="dropdownMenuButton1"
                                    className="border-0 bg-transparent"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="fa-solid fa-ellipsis-vertical" />
                                  </button>
                                  <ul
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton1"
                                  >
                                    <li className="py-0">
                                      <Link
                                        to={"/idea/" + idea._id}
                                        className="dropdown-item"
                                      >
                                        Open
                                      </Link>
                                    </li>
                                    <li className="py-0">
                                      <Link
                                        to={"/idea/" + idea._id + "/edit"}
                                        className="dropdown-item"
                                      >
                                        Edit
                                      </Link>
                                    </li>
                                    <li className="py-0">
                                      <Link
                                        to=""
                                        className="dropdown-item"
                                        onClick={() => {
                                          duplicateIdea(idea._id);
                                        }}
                                      >
                                        Duplicate
                                      </Link>
                                    </li>
                                    <li className="py-0">
                                      <Link
                                        to=""
                                        className="dropdown-item text-danger"
                                        onClick={() => deleteIdea(idea._id)}
                                      >
                                        Delete
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                                {/* Dropdown End */}
                              </td>
                            </tr>
                            <tr className="lh-1">
                              <td className="p-0">
                                <br />
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>
                    {/* Table Ending */}
                  </div>
                </div>
              </div>
            </section>
            {/* Table Section End */}
          </div>
        </div>
      </section>
    </MainLayout>
  );
};

export default Ideas;
