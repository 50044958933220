import React from "react";
import Sidebar from "../../../components/Sidebar";
import MainLayout from "../../../layout/MainLayout";

const EditLearning = () => {
  return (
    <MainLayout>
      <section className="mainContent">
        <div className="row">
          <Sidebar />
          <div className="goalNameSection UntitledIdea pt-4 ps-5">
            <div className="row px-3 pt-4">
              <p className="p-0 mb-3 backBtn">
                <i className="fa-solid fa-arrow-left-long" />
                &nbsp; All Learnings
              </p>
              <div className="row mb-3">
                <div className="form-floating form-floating-custom col-lg-6 ps-0">
                  <input
                    type="text"
                    className="form-control bg-transparent"
                    defaultValue="Retargeting ads"
                    id="floatingInputValue"
                  />
                  <label htmlFor="floatingSelect" className="pt-3">
                    Name
                  </label>
                </div>
                <div className="col-lg-5 p-0 m-0 d-flex align-items-center justify-content-end">
                  <button className="btn btn-outline-danger py-2 px-4 me-2 rounded-1">
                    Cancel
                  </button>
                  <button className="btn btn-primary py-2 px-4 rounded-1">
                    save
                  </button>
                </div>
              </div>
              <div className="form-floating col-lg-11 ps-0 mb-3">
                <select
                  className="form-select selectOpt"
                  id="floatingSelect"
                  aria-label="Floating label select example"
                >
                  <option selected="">Success</option>
                  <option value={1}>One</option>
                  <option value={2}>Two</option>
                  <option value={3}>Three</option>
                </select>
                <label htmlFor="floatingSelect" className="pt-3">
                  Did it worked?
                </label>
              </div>
              <div className="form-floating col-lg-11 ps-0 mb-3">
                <select
                  className="form-select selectOpt"
                  id="floatingSelect"
                  aria-label="Floating label select example"
                >
                  <option selected="">Acquisition</option>
                  <option value={1}>One</option>
                  <option value={2}>Two</option>
                  <option value={3}>Three</option>
                </select>
                <label htmlFor="floatingSelect" className="pt-3">
                  Lever
                </label>
              </div>
              <div className="form-floating form-floating-custom col-lg-11 ps-0 mb-3">
                <input
                  type="text"
                  className="form-control bg-transparent"
                  defaultValue="Goal 3"
                  id="floatingInputValue"
                />
                <label htmlFor="floatingSelect" className="pt-3">
                  Goal
                </label>
              </div>
              <div className="form-floating form-floating-custom col-lg-11 ps-0 mb-3 position-relative">
                <input
                  type="text"
                  className="form-control bg-transparent"
                  id="floatingInputValue"
                  disabled=""
                />
                <label htmlFor="floatingInputValue">Members</label>
                {/* <input type="file" name="myfile" class="fileField"/> */}
                <div className="p-0 m-0 position-absolute d-flex gap-1 teamMatesBadge">
                  <p className="m-0 p-0 border-0 rounded-1 px-2">
                    <img
                      src="/assets/account.png"
                      alt=""
                      className="avatar"
                    />
                    &nbsp; Aryan
                  </p>
                  <p className="m-0 p-0 border-0 rounded-1 px-2">
                    <img
                      src="/assets/account.png"
                      alt=""
                      className="avatar"
                    />
                    &nbsp; Aryan
                  </p>
                  <p className="m-0 p-0 border-0 rounded-1 px-2">
                    <img
                      src="/assets/account.png"
                      alt=""
                      className="avatar"
                    />
                    &nbsp; Aryan
                  </p>
                </div>
                <i className="fa-solid fa-plus bg-dark bg-opacity-50 p-1 rounded-1 plusIcon" />
              </div>
              <div className="row col-lg-11 g-2 p-0 m-0 mb-3">
                <div className="col-md ps-0 pe-2">
                  <div className="form-floating form-floating-custom">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInputGrid"
                      placeholder="START DATE"
                      defaultValue="12/03/2022"
                      onfocus="(this.type='date')"
                    />
                    <label htmlFor="floatingInputGrid">Start date</label>
                    {/* <i class="fa-solid fa-calendar-days text-muted dateIcon"></i> */}
                  </div>
                </div>
                <div className="col-md ps-0 pe-3">
                  <div className="form-floating form-floating-custom">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInputGrid"
                      placeholder="END DATE"
                      defaultValue="16/03/2022"
                      onfocus="(this.type='date')"
                    />
                    <label htmlFor="floatingInputGrid">Due Date</label>
                    {/* <i class="fa-solid fa-calendar-days text-muted dateIcon"></i> */}
                  </div>
                </div>
              </div>
              <div className="form-floating col-lg-11 ps-0 mb-3">
                <textarea
                  className="form-control modelTextarea mt-2"
                  placeholder="Describe your goal"
                  id="floatingTextarea2"
                  defaultValue={
                    "Nullam eget posuere odio, varius rutrum sapien. Duis neque libero, blandit sed ligula vitae, elementum tincidunt purus. Etiam feugiat laoreet diam eget mollis. Proin lectus mi, iaculis sed sollicitudin a, suscipit id diam. Sed at ligula Duis neque libero, blandit sed ligula vitae,"
                  }
                />
                <label htmlFor="floatingTextarea2" className="pb-2">
                  DESCRIPTION
                </label>
              </div>
              <div className="form-floating col-lg-11 ps-0 mb-3">
                <textarea
                  className="form-control modelTextarea mt-2"
                  placeholder="Describe your goal"
                  id="floatingTextarea2"
                  defaultValue={
                    "Nullam eget posuere odio, varius rutrum sapien. Duis neque libero, blandit sed ligula vitae, elementum tincidunt purus. Etiam feugiat laoreet diam eget mollis. Proin lectus mi, iaculis sed sollicitudin a, suscipit id diam. Sed at ligula Duis neque libero, blandit sed ligula vitae,"
                  }
                />
                <label htmlFor="floatingTextarea2" className="pb-2">
                  Hypothesis
                </label>
              </div>
              <div className="col-lg-11 m-0 ps-0 mb-3">
                <div className="p-0 pt-2 pb-4 px-3 m-0 border rounded-1 checklist">
                  <p className="p-0 m-0 mb-3 checklist-label">CHECKLIST</p>
                  <div className="form-check mb-2">
                    <input
                      className="form-check-input border border-2 border-secondary"
                      type="checkbox"
                      defaultChecked=""
                    />
                    <label
                      className="form-check-label text-decoration-line-through"
                      htmlFor="flexCheckChecked"
                    >
                      Pointer 1{" "}
                      <img
                        src="/assets/crossIcon.svg"
                        className="p-0 m-0 ms-2"
                      />
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input border border-2 border-secondary"
                      type="checkbox"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckChecked"
                    >
                      Pointer 1
                    </label>
                  </div>
                  <div className="p-0 m-0 w-25 pe-5 mt-4">
                    <span className="p-0 py-2 px-3 m-0 bg-secondary bg-opacity-25 rounded-1">
                      Add A Subtask{" "}
                      <i className="fa-solid fa-plus bg-secondary bg-opacity-75 text-white p-1 ms-2 rounded-1" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="row gap-3 mb-3">
                <div className="col-lg-3 d-flex justify-content-between align-items-center py-2 px-3 border border-secondary rounded-1 border-opacity-25">
                  <div className="p-0 m-0">
                    <p className="p-0 mb-0 text-muted">IMPACT</p>
                    <p className="p-0 mb-0 fs-5">Select score (1-10)</p>
                  </div>
                  <div className="p-0 m-0">123</div>
                </div>
                <div className="col-lg-3 d-flex justify-content-between align-items-center py-2 px-3 border border-secondary rounded-1 border-opacity-25">
                  <div className="p-0 m-0">
                    <p className="p-0 mb-0 text-muted">CONFIDENCE</p>
                    <p className="p-0 mb-0 fs-5">Select score (1-10)</p>
                  </div>
                  <div className="p-0 m-0">123</div>
                </div>
                <div className="col-lg-3 d-flex justify-content-between align-items-center py-2 px-3 border border-secondary rounded-1 border-opacity-25">
                  <div className="p-0 m-0">
                    <p className="p-0 mb-0 text-muted">EASE</p>
                    <p className="p-0 mb-0 fs-5">Select score (1-10)</p>
                  </div>
                  <div className="p-0 m-0">123</div>
                </div>
                <div className="col-lg-1 py-2 px-2 ">
                  <p className="p-0 mb-0 text-danger">SCORE</p>
                  <p className="p-0 mb-0 fs-5 text-danger">0.0</p>
                </div>
              </div>
              <div className="col-lg-11 p-0 pt-2 pb-4 px-3 m-0 mt-2 mb-4 border dashed">
                <p className="p-0 m-0 mb-3 text-muted">FILES</p>
                <div className="p-0 m-0 d-flex gap-2">
                  <div className="p-0 m-0 position-relative">
                    <img
                      src="/assets/rectangleFile.svg"
                      className="img-thumbnail"
                    />
                    <img
                      src="/assets/whiteDeleteIcon.svg"
                      className="position-absolute openDialogIcon"
                    />
                  </div>
                  <div className="p-0 m-0 position-relative">
                    <img
                      src="/assets/rectangleFile.svg"
                      className="img-thumbnail"
                    />
                    <img
                      src="/assets/whiteDeleteIcon.svg"
                      className="position-absolute openDialogIcon"
                    />
                  </div>
                  <div className="p-0 m-0 position-relative">
                    <img
                      src="/assets/rectangleFile.svg"
                      className="img-thumbnail"
                    />
                    <img
                      src="/assets/whiteDeleteIcon.svg"
                      className="position-absolute openDialogIcon"
                    />
                  </div>
                  <div className="m-0 position-relative uploadBox">
                    <div className="p-0 m-0 text-center">
                      <img src="/assets/uploadIcon.svg" />
                      <p className="p-0 m-0 mt-2">Upload</p>
                      <input type="file" name="myfile" className="fileField" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  );
};

export default EditLearning;
