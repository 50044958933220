import React from "react";
import axios from "axios";
import { server, config } from "../../../../env";
import { useEffect, useState } from "react";
import Loader from "../../../../components/Loader";
import Alert from "../../../../components/Alert";
import { useNavigate, Link } from "react-router-dom";
import Modal from "../../../../components/Modal";
import Select from "react-select";
import Avatar from "react-avatar";

const Keyresult = (props) => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState("");
  const [goal, setGoal] = useState({});
  const [keyresult, setKeyresult] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState(props.goal.members);

  useEffect(() => {
    const members = props.goal.members;

    const membersOptions = members.map((member) => {
      return {
        value: member._id,
        label: member.name,
      };
    });

    setOptions(membersOptions);
  }, []);

  // create keyresult
  const createKeyresult = async (e) => {
    e.preventDefault();

    const data = {
      name: e.target.name.value,
      value: e.target.value.value,
      startValue: e.target.startValue.value,
      targetValue: e.target.targetValue.value,
      startDate: e.target.startDate.value,
      endDate: e.target.endDate.value,
    };

    await axios
      .post(`${server}/goal/createKeyResult/${props.goal._id}`, data, config)
      .then((res) => {
        setMessage(<Alert className="success" message={res.data.message} />);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });
  };
  // update metric
  const updateMetric = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    const data = {
      date: e.target.date.value,
      value: e.target.value.value,
    };

    await axios
      .post(
        server + "/goal/updateMetric/" + goal._id + "/" + keyresult._id,
        data,
        config
      )
      .then((res) => {
        setMessage(<Alert className="success" message={res.data.message} />);
        setLoader("");
      })
      .catch((err) => {
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
        setLoader("");
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  // add users to keyresult
  const addUsers = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    const data = {
      users: selectedOption,
    };

    await axios
      .post(
        server + "/goal/addUsers/" + goal._id + "/" + keyresult._id,
        data,
        config
      )
      .then((res) => {
        setMessage(<Alert className="success" message={res.data.message} />);
        setLoader("");
      })
      .catch((err) => {
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
        setLoader("");
      });
  };

  return (
    <>
      <div
        className="p-0 m-0 ps-2 d-flex general-accordion"
        id="accordion1"
        style={{
          opacity: 1,
          height: "auto",
        }}
      >
        <div className="nestedLines p-0 ms-3 border-start border-secondary border-opacity-50" />
        <div className="p-0 ps-4 w-100 table-responsive">
          <table className="table table-borderless modelsTab goalsTable-Tab m-0">
            {props?.goal?.keyresults?.map((keyresult) => {
              const percentage =
                ((keyresult?.metrics?.at(-1)?.value - keyresult?.startValue) /
                  (keyresult?.targetValue - keyresult?.startValue)) *
                  100 || 0;

              return (
                <tbody className="position-relative">
                  <tr className="w-25">
                    <td className="p-0">
                      <hr className="accordionLine" />
                    </td>
                  </tr>
                  <tr className="border border-dark border-opacity-25 align-middle">
                    <td scope="row" className="nestedItem ps-4">
                      {keyresult.name}
                    </td>
                    <td>{new Date(keyresult.createdAt).toDateString()}</td>
                    <td>
                      {keyresult.users?.map((user) => (
                        <Avatar name={user.name} size={30} round={true} />
                      ))}
                    </td>
                    <td
                      style={{
                        width: "250px",
                      }}
                    >
                      <p
                        className="p-0 m-0 mb-3 pb-2 text-warning border-0 border-bottom border-3 border-warning"
                        style={
                          percentage > 100
                            ? { width: "100%" }
                            : { width: percentage + "%" }
                        }
                      >
                        {percentage?.toFixed(2)}%
                      </p>
                      <span>
                        Updated on{" "}
                        {new Date(keyresult?.updatedAt).toDateString()}
                      </span>
                    </td>
                    <td>
                      <select
                        className="form-select border-warning text-warning px-2 py-2"
                        aria-label="Default select example"
                      >
                        <option value={1} className="text-danger">
                          At Risk
                        </option>
                        <option selected="">on Track</option>
                        <option value={2} className="text-warning">
                          Has Issues
                        </option>
                        <option value={3} className="text-secondary">
                          Done
                        </option>
                      </select>
                    </td>
                    <td>
                      {/* Dropdown start */}
                      <div className="dropdown">
                        <button
                          id="dropdownMenuButton1"
                          className="border-0 bg-transparent"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="fa-solid fa-ellipsis-vertical" />
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <Link
                              to=""
                              className="dropdown-item"
                              data-bs-toggle="modal"
                              data-bs-target="#updateMetric"
                              onClick={() => {
                                setGoal(props.goal);
                                setKeyresult(keyresult);
                              }}
                            >
                              Update Value
                            </Link>
                          </li>
                          <li>
                            <Link
                              to=""
                              className="dropdown-item"
                              data-bs-toggle="modal"
                              data-bs-target="#manage"
                              onClick={() => {
                                setKeyresult(keyresult);
                                setGoal(props.goal);
                                const selectedOptions = [];
                                keyresult.users.forEach((member) => {
                                  selectedOptions.push({
                                    value: member._id,
                                    label: member.name,
                                  });
                                });
                                setSelectedOption(selectedOptions);
                              }}
                            >
                              Assign User
                            </Link>
                          </li>
                          <li>
                            <a className="dropdown-item text-danger" href="#">
                              Delete
                            </a>
                          </li>
                        </ul>
                      </div>
                      {/* Dropdown End */}
                    </td>
                  </tr>
                  <tr>
                    <td className="p-0">
                      <br />
                    </td>
                  </tr>
                </tbody>
              );
            })}

            {/* Model Tr start */}
            <tbody>
              <tr>
                <td>
                  <button
                    className="addKey-btn btn text-primary border-0"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalTwo"
                  >
                    <i className="fa-solid fa-add" /> Add Key Results
                  </button>
                  {/* Modal Code Start */}
                  <div
                    className="modal fade"
                    id="exampleModalTwo"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content border-0 rounded-2 p-3">
                        <form onSubmit={createKeyresult}>
                          <div className="modal-header flex-column align-items-start border-0 pb-3">
                            <h3 id="exampleModalLabel">Create Key Result</h3>
                            {message}
                            <p className="text-muted modelDes">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Nulla auctor. Sit amet, consectetur
                              adipiscing consectetur adipiscing elit.
                            </p>
                          </div>
                          <div className="modal-body pb-0 pt-0">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="floatingInputValue"
                                placeholder="Name Your Key Result"
                                name="name"
                              />
                              <label htmlFor="floatingInputValue">NAME</label>
                            </div>
                            <div className="form-floating">
                              <select
                                className="form-select mb-3"
                                id="floatingSelect"
                                aria-label="Floating label select example"
                                name="value"
                              >
                                <option value="number">Number</option>
                                <option value="currency">Currency</option>
                                <option selected="percentage">
                                  Percentage
                                </option>
                              </select>
                              <label htmlFor="floatingSelect">VALUE</label>
                            </div>
                            <div className="row g-2 mb-3">
                              <div className="col-md">
                                <div className="form-floating">
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="floatingInputGrid"
                                    placeholder="Enter Value"
                                    name="startValue"
                                  />
                                  <label htmlFor="floatingInputGrid">
                                    START VALUE
                                  </label>
                                </div>
                              </div>
                              <div className="col-md">
                                <div className="form-floating">
                                  <input
                                    type="Number"
                                    className="form-control"
                                    id="floatingInputGrid"
                                    placeholder="Enter Value"
                                    name="targetValue"
                                  />
                                  <label htmlFor="floatingInputGrid">
                                    TARGET VALUE
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row g-2">
                              <div className="col-md">
                                <div className="form-floating">
                                  <input
                                    type="date"
                                    className="form-control"
                                    id="floatingInputGrid"
                                    placeholder="Enter Date"
                                    name="startDate"
                                  />
                                  <label htmlFor="floatingInputGrid">
                                    START DATE
                                  </label>
                                  {/* <i class="fa-solid fa-calendar-days text-muted dateIcon"></i> */}
                                </div>
                              </div>
                              <div className="col-md">
                                <div className="form-floating">
                                  <input
                                    type="date"
                                    className="form-control"
                                    id="floatingInputGrid"
                                    placeholder="Enter Date"
                                    name="endDate"
                                  />
                                  <label htmlFor="floatingInputGrid">
                                    END DATE
                                  </label>
                                  {/* <i class="fa-solid fa-calendar-days text-muted dateIcon"></i> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="modal-footer justify-content-end border-0">
                            <button
                              type="button"
                              className="btn btn-outline-danger rounded-1 px-4"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary rounded-1 px-4"
                            >
                              Create {loader && <Loader />}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  {/* Modal Code End */}
                  <div
                    className="modal fade"
                    id="updateMetric"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content rounded-1 p-3">
                        <div className="modal-header flex-column align-items-start border-0 pb-0">
                          <h3 id="exampleModalLabel">Add New Value</h3>
                          {/*  */}
                          <div className="d-flex justify-content-between w-100 mt-3 px-5">
                            <h6>
                              Start Value:{" "}
                              <span className="fw-bold text-muted">
                                {keyresult.startValue}
                              </span>
                            </h6>
                            <h6>
                              Target Value:{" "}
                              <span className="fw-bold text-muted">
                                {keyresult.targetValue}
                              </span>
                            </h6>
                          </div>
                        </div>
                        {message}
                        <form onSubmit={updateMetric}>
                          <div className="modal-body pb-0">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="floatingInputValue"
                                placeholder="Enter Current Value"
                                name="value"
                              />
                              <label htmlFor="floatingInputValue">
                                CURRENT VALUE
                              </label>
                            </div>
                            <div className="form-floating mb-3 dateField">
                              <input
                                type="date"
                                className="form-control"
                                id="floatingInputGrid"
                                placeholder=" DATE"
                                name="date"
                              />
                              <label htmlFor="floatingInputGrid">
                                Select Date
                              </label>
                            </div>
                          </div>
                          <div className="modal-footer border-0">
                            <button
                              type="reset"
                              className="btn btn-outline-danger rounded-1"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary rounded-1 customBtn"
                            >
                              Add Value {loader}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Modal id="manage" title="Manage Access">
        <div className="modal-body">
          {message}
          <Select
            value={selectedOption}
            onChange={setSelectedOption}
            options={options}
            isMulti={true}
          />
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button type="button" className="btn btn-primary" onClick={addUsers}>
            Save changes {loader}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Keyresult;
