import React from "react";
import { useState } from "react";
import { NavLink, useParams } from "react-router-dom";

const Sidebar = () => {
  const { id } = useParams();
  const [tab, setTab] = useState("goals");
  const [projectId, setProjectId] = useState(localStorage.getItem("project"));

  let activeStyle = null;
  let activeClassName = "underline";

  return (
    <div className="sideNav py-5 border-0 border-end d-flex flex-column justify-content-start align-items-center">
      <ul className="list-group gap-3 pt-4">
        <NavLink
          to={"/projects/" + projectId + "/goals"}
          // send state to child component
          className="text-decoration-none"
          onClick={() => localStorage.setItem("tab", "goals")}
        >
          <li className="list-group-item border-0 bg-transparent">
            <div className="d-flex flex-column align-items-center gap-2">
              <img
                src={
                  localStorage.getItem("tab") === "goals"
                    ? "/assets/navItem1(active).svg"
                    : "/assets/navItem1.svg"
                }
                alt=""
              />
              <span
                className={
                  tab === "goals"
                    ? "text-primary text-muted fs-6 fw-bold"
                    : "text-muted fs-5"
                }
              >
                Goals
              </span>
            </div>
          </li>
        </NavLink>
        <NavLink
          to={"/projects/" + projectId + "/ideas"}
          className="text-decoration-none "
          onClick={() => localStorage.setItem("tab", "ideas")}
        >
          <li className="list-group-item border-0 bg-transparent">
            <div className="d-flex flex-column align-items-center gap-2">
              <img
                src={
                  localStorage.getItem("tab") === "ideas"
                    ? "/assets/navItem2(active).svg"
                    : "/assets/navItem2.svg"
                }
                alt=""
              />
              <span className={tab === "ideas" ? "text-primary" : ""}>
                Ideas
              </span>
            </div>
          </li>
        </NavLink>
        <NavLink
          to={"/projects/" + projectId + "/tasks"}
          className="text-decoration-none"
          onClick={() => localStorage.setItem("tab", "tasks")}
        >
          <li className="list-group-item border-0 bg-transparent">
            <div className="d-flex flex-column align-items-center gap-2">
              <img
                src={
                  localStorage.getItem("tab") === "tasks"
                    ? "/assets/navItem3(active).svg"
                    : "/assets/navItem3.svg"
                }
                alt=""
              />
              <span>Tasks</span>
            </div>
          </li>
        </NavLink>
        <NavLink
          to={"/projects/" + projectId + "/learnings"}
          className="text-decoration-none"
          onClick={() => localStorage.setItem("tab", "learnings")}
        >
          <li className="list-group-item border-0 bg-transparent">
            <div className="d-flex flex-column align-items-center gap-2">
              <img
                src={
                  localStorage.getItem("tab") === "learnings"
                    ? "/assets/navItem4(active).svg"
                    : "/assets/navItem4.svg"
                }
                alt="learnings"
              />
              <span>Learnings</span>
            </div>
          </li>
        </NavLink>
        <NavLink
          to={"/projects/" + projectId + "/health"}
          className="text-decoration-none"
          onClick={() => localStorage.setItem("tab", "health")}
        >
          <li className="list-group-item border-0 bg-transparent">
            <div className="d-flex flex-column align-items-center gap-2">
              <img
                src={
                  localStorage.getItem("tab") === "health"
                    ? "/assets/navItem5(active).svg"
                    : "/assets/navItem5.svg"
                }
                alt="health"
              />
              <span>Health</span>
            </div>
          </li>
        </NavLink>
      </ul>
    </div>
  );
};

export default Sidebar;
