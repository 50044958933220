import React, { useState } from "react";
import MainLayout from "../../../layout/MainLayout";
import { Link } from "react-router-dom";
import DCF from "../../../tools/DCF";
import Coma from "../../../components/Coma";
import Modal from "../../../components/Modal";
import { Line } from "react-chartjs-2";
import axios from "axios";
import { server, config } from "../../../env";

const NewModel = () => {
  const [data, setData] = useState({
    starting_date: new Date().toISOString().slice(0, 10),
    cash_in_bank: 1000000,
    initial_no_of_customers: 0,
    avg_ord_val: 950,
    units_per_order: 1,
    blended_cogs: 30,
    realisation_rate: 80,
    outbound_salary: 30000,
    contacts_per_month_per_sdr: 2000,
    number_of_sdrs: 0,
    contact_to_lead_conversion_rate: 2,
    lead_to_customer_conversion_rate_outbound: 8,
    organic_views_per_month: 0,
    organic_view_to_lead_conversion_rate: 0.5,
    lead_to_customer_conversion_rate_organic: 6,
    monthly_ad_spend: 750000,
    cpm: 150,
    ctr: 1,
    lpv_rate: 70,
    lead_gen_rate: 100,
    conv_rate: 3,
    returning_cust_rate: 25,
    time_to_return: 1,
    cost_to_market_return: 7.5,
    referers_out_of_customers: 2,
    invitees_per_referal: 5,
    invitees_conv_rate: 5,
    refund_rate: 1.5,
    fixed_loss_per_refund: 100,
    payment_processor_fees: 2,
    merchant_account_fees: 0.5,
    fixed_cost_per_month: 200000,
    fixed_cost_increase_per_100_cust: 5000,
    upfront_investment_cost: 0,
    debt: 0,
    debt_interest_rate: 6,
    tax_rate: 20,
    number_of_shares: 20000,
    projection_period: 12,
    discount_rate: 12,
    perpetual_growth_rate: 3,
  });
  const [result, setResult] = useState([]);
  const [name, setName] = useState("Model Name");
  const [currency, setCurrency] = useState(
    localStorage.getItem("currency") || "₹"
  );
  const [inbound, setInbound] = useState(false);
  const [outbound, setOutbound] = useState(false);
  const [organic, setOrganic] = useState(false);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: +e.target.value });
  };
  async function saveValues(e) {
    e.preventDefault();

    const params = {
      name: name,
      values: data,
    };

    await axios
      .post(server + "/model/create", params, config)
      .then((rsp) => {})
      .catch((e) => {});
  }

  return (
    <MainLayout>
      <section className="model-heading tools-heading">
        <div className="container">
          <div className="row ps-4 pad">
            <div className="p-0 headingSide col-md-6 col-sm-12">
              <Link to="/tools/models">
                <p className="ps-0 mb-1 text-muted">
                  <i className="fa-solid fa-arrow-left-long" />
                  &nbsp; Model List
                </p>
              </Link>
              <div className="p-0 m-0 d-flex align-items-center">
                <h1 className="ps-0">{name}</h1>
                <p
                  className="p-0 m-0 ms-3"
                  data-bs-toggle="modal"
                  data-bs-target="#Edit"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <img src="/assets/editIcon.png" />
                </p>
              </div>
              <p className="ps-0 mt-2">ID : 1432521</p>
            </div>
            <div className="p-0 buttonsSide col-md-6 col-sm-12 d-flex justify-content-end align-items-center">
              <button className="btn btn-primary me-4 rounded-1 py-2 px-4">
                <i className="fa-solid fa-arrow-up-from-bracket rounded-1" />
                &nbsp;&nbsp; Export
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="modelName-section">
        <div className="container">
          {/* Input Fields Start */}
          {/* Starting State */}
          <div className="row ps-4 mb-4 pad">
            <h3 className="ps-0 mb-3">Starting State</h3>
            <div className="row mb-4">
              <div className="col-lg-3 ps-0 pb-2">
                <div className="form-floating ">
                  <input
                    type="number"
                    className="form-control"
                    id="floatingInputValue"
                    placeholder="LTV(₹)"
                    name="cash_in_bank"
                    value={data.cash_in_bank}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />

                  <label htmlFor="floatingInputValue label-opacity">
                    Cash In Bank
                  </label>
                </div>
              </div>
              <div className="col-lg-3 ps-0 pb-2">
                <div className="form-floating ">
                  <input
                    type="number"
                    className="form-control "
                    id="floatingInputValue"
                    placeholder="LTV(₹)"
                    name="initial_no_of_customers"
                    value={data.initial_no_of_customers}
                    onChange={handleChange}
                    aria-describedby="button-addon2"
                  />

                  <label htmlFor="floatingInputValue label-opacity">
                    Initial Number of Customers
                  </label>
                </div>
              </div>
            </div>
            <hr />
          </div>
          {/* Financial Data */}
          <div className="row ps-4 mb-4 pad">
            <h4 className="ps-0 mb-3">Financial Data</h4>
            <div className="row mb-4">
              <div className="col-lg-3 ps-0 pb-2">
                <div className="form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="floatingInputValue"
                    placeholder="LTV(₹)"
                    name="avg_ord_val"
                    value={data.avg_ord_val}
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingInputValue">
                    Average Order Value ({currency})
                  </label>
                </div>
              </div>
              <div className="col-lg-3 ps-0 pb-2">
                <div className="form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="floatingInputValue"
                    placeholder="LTV(₹)"
                    name="units_per_order"
                    value={data.units_per_order}
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingInputValue">Units/Order</label>
                </div>
              </div>
              <div className="col-lg-3 ps-0 pb-2">
                <div className="form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="floatingInputValue"
                    placeholder="LTV(₹)"
                    name="blended_cogs"
                    value={data.blended_cogs}
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingInputValue">Blended COGS (%)</label>
                </div>
              </div>
              <div className="col-lg-3 ps-0 pb-2">
                <form
                  className="form-floating"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-custom-class="custom-tooltip"
                  title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc pretium pharetra metus"
                >
                  <input
                    type="number"
                    className="form-control"
                    id="floatingInputValue"
                    placeholder="LTV(₹)"
                    name="realisation_rate"
                    value={data.realisation_rate}
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingInputValue">
                    Realisation Rate (%)
                  </label>
                </form>
              </div>
            </div>
            <hr />
          </div>
          {/* Marketing Metrics */}
          <div className="row ps-4 mb-4 pad">
            <h4 className="ps-0 mb-3">Marketing Metrics</h4>
            <p className="ps-0 mb-2">
              <span className="text-muted">Inbound</span>&nbsp;&nbsp;
              <span>
                <input
                  className="form-check-input checked border border-2 border-secondary"
                  type="checkbox"
                  id="inbound"
                  checked={inbound}
                  onChange={() => {
                    setInbound(!inbound);
                  }}
                />
              </span>
            </p>
            {inbound && (
              <div className="row mb-4">
                <div className="col-lg-3 ps-0 pb-2">
                  <div className="form-floating">
                    <input
                      type="number"
                      className="form-control"
                      id="floatingInputValue"
                      placeholder="LTV(₹)"
                      name="monthly_ad_spend"
                      value={data.monthly_ad_spend}
                      onChange={handleChange}
                    />
                    <label htmlFor="floatingInputValue">
                      Monthly Ad Spend ({currency})
                    </label>
                  </div>
                </div>
                <div className="col-lg-3 ps-0 pb-2">
                  <div className="form-floating">
                    <input
                      type="number"
                      className="form-control"
                      id="floatingInputValue"
                      placeholder="LTV(₹)"
                      name="cpm"
                      value={data.cpm}
                      onChange={handleChange}
                    />
                    <label htmlFor="floatingInputValue">CPM ({currency})</label>
                  </div>
                </div>
                <div className="col-lg-3 ps-0 pb-2">
                  <div className="form-floating">
                    <input
                      type="number"
                      className="form-control"
                      id="floatingInputValue"
                      placeholder="LTV(₹)"
                      name="ctr"
                      value={data.ctr}
                      onChange={handleChange}
                    />
                    <label htmlFor="floatingInputValue">CTR (%)</label>
                  </div>
                </div>
                <div className="col-lg-3 ps-0 pb-2">
                  <div className="form-floating">
                    <input
                      type="number"
                      className="form-control"
                      id="floatingInputValue"
                      placeholder="LTV(₹)"
                      name="lpv_rate"
                      value={data.lpv_rate}
                      onChange={handleChange}
                    />
                    <label htmlFor="floatingInputValue">LPV Rate (%)</label>
                  </div>
                </div>
                <div className="col-lg-3 ps-0 pb-2">
                  <div className="form-floating">
                    <input
                      type="number"
                      className="form-control"
                      id="floatingInputValue"
                      placeholder="LTV(₹)"
                      name="lead_gen_rate"
                      value={data.lead_gen_rate}
                      onChange={handleChange}
                    />
                    <label htmlFor="floatingInputValue">
                      Lead Generation Rate (%)
                    </label>
                  </div>
                </div>
                <div className="col-lg-3 ps-0 pb-2">
                  <div className="form-floating">
                    <input
                      type="number"
                      className="form-control"
                      id="floatingInputValue"
                      placeholder="LTV(₹)"
                      name="conv_rate"
                      value={data.conv_rate}
                      onChange={handleChange}
                    />
                    <label htmlFor="floatingInputValue">
                      Conversion Rate (%)
                    </label>
                  </div>
                </div>
              </div>
            )}
            <p className="ps-0 mb-2">
              <span className="text-muted">Outbound</span>&nbsp;&nbsp;
              <span>
                <input
                  className="form-check-input border border-2 border-secondary"
                  type="checkbox"
                  id="outbound"
                  checked={outbound}
                  onChange={() => {
                    setOutbound(!outbound);
                  }}
                />
              </span>
            </p>
            {outbound && (
              <div className="row">
                <div className="col-lg-3 ps-0 pb-2">
                  <div className="form-floating">
                    <input
                      type="number"
                      className="form-control"
                      id="floatingInputValue"
                      placeholder="LTV(₹)"
                      name="outbound_salary"
                      value={data.outbound_salary}
                      onChange={handleChange}
                    />
                    <label htmlFor="floatingInputValue">
                      Outbound Salary ({currency})
                    </label>
                  </div>
                </div>
                <div className="col-lg-3 ps-0 pb-2">
                  <div className="form-floating">
                    <input
                      type="number"
                      className="form-control"
                      id="floatingInputValue"
                      placeholder="LTV(₹)"
                      name="number_of_sdrs"
                      value={data.number_of_sdrs}
                      onChange={handleChange}
                    />
                    <label htmlFor="floatingInputValue">Number of SDR’s</label>
                  </div>
                </div>
                <div className="col-lg-3 ps-0 pb-2">
                  <div className="form-floating">
                    <input
                      type="number"
                      className="form-control"
                      id="floatingInputValue"
                      placeholder="LTV(₹)"
                      name="contacts_per_month_per_sdr"
                      value={data.contacts_per_month_per_sdr}
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="floatingInputValue"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      Number of Contacts per Month (per SDR)
                    </label>
                  </div>
                </div>
                <div className="col-lg-3 ps-0 pb-2">
                  <div className="form-floating">
                    <input
                      type="number"
                      className="form-control"
                      id="floatingInputValue"
                      placeholder="LTV(₹)"
                      name="contact_to_lead_conversion_rate"
                      value={data.contact_to_lead_conversion_rate}
                      onChange={handleChange}
                    />
                    <label htmlFor="floatingInputValue">
                      Contact to Lead Conversion Rate (%)
                    </label>
                  </div>
                </div>
                <div className="col-lg-3 ps-0 pb-2">
                  <div className="form-floating">
                    <input
                      type="number"
                      className="form-control"
                      id="floatingInputValue"
                      placeholder="LTV(₹)"
                      name="lead_to_customer_conversion_rate_outbound"
                      value={data.lead_to_customer_conversion_rate_outbound}
                      onChange={handleChange}
                    />
                    <label htmlFor="floatingInputValue">
                      Lead to Customer Conversion Rate (%)
                    </label>
                  </div>
                </div>
                <div className="col-lg-3 ps-0 pb-2">
                  <div className="form-floating">
                    <input
                      type="number"
                      className="form-control"
                      id="floatingInputValue"
                      placeholder="LTV(₹)"
                      name="conv_rate"
                      value={data.conv_rate}
                      onChange={handleChange}
                    />
                    <label htmlFor="floatingInputValue">
                      Conversion Rate (%)
                    </label>
                  </div>
                </div>
              </div>
            )}
            <p className="ps-0 mb-2 ">
              <span className="text-muted">Organic</span>&nbsp;&nbsp;
              <span>
                <input
                  className="form-check-input checked border border-2 border-secondary"
                  type="checkbox"
                  id="organic"
                  checked={organic}
                  onChange={() => {
                    setOrganic(!organic);
                  }}
                />
              </span>
            </p>
            {organic && (
              <div className="row mb-4">
                <div className="col-lg-4 ps-0 pb-2">
                  <div className="form-floating">
                    <input
                      type="number"
                      className="form-control"
                      id="floatingInputValue"
                      placeholder="LTV(₹)"
                      name="organic_views_per_month"
                      value={data.organic_views_per_month}
                      onChange={handleChange}
                    />
                    <label htmlFor="floatingInputValue">
                      Organic Views per Month
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 ps-0 pb-2">
                  <div className="form-floating">
                    <input
                      type="number"
                      className="form-control"
                      id="floatingInputValue"
                      placeholder="LTV(₹)"
                      name="organic_view_to_lead_conversion_rate"
                      value={data.organic_view_to_lead_conversion_rate}
                      onChange={handleChange}
                    />
                    <label htmlFor="floatingInputValue">
                      Organic Views to Lead Conversion Rate (%)
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 ps-0 pb-2">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInputValue"
                      placeholder="LTV(₹)"
                      name="lead_to_customer_conversion_rate_organic"
                      value={data.lead_to_customer_conversion_rate_organic}
                      onChange={handleChange}
                    />
                    <label htmlFor="floatingInputValue">
                      Lead to Customer Conversion Rate (%)
                    </label>
                  </div>
                </div>
              </div>
            )}
            <hr />
          </div>
          {/* Retention Metrics */}
          <div className="row ps-4 mb-4 pad">
            <h4 className="ps-0 mb-3">Retention Metrics</h4>
            <div className="row mb-4">
              <div className="col-lg-4 ps-0 pb-2">
                <div className="form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="floatingInputValue"
                    placeholder="LTV(₹)"
                    name="returning_cust_rate"
                    value={data.returning_cust_rate}
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingInputValue">
                    Returning Customer Rate (%) Enter
                  </label>
                </div>
              </div>
              <div className="col-lg-4 ps-0 pb-2">
                <div className="form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="floatingInputValue"
                    placeholder="LTV(₹)"
                    name="time_to_return"
                    value={data.time_to_return}
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingInputValue">
                    Time to Return (Months)
                  </label>
                </div>
              </div>
              <div className="col-lg-4 ps-0 pb-2">
                <div className="form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="floatingInputValue"
                    placeholder="LTV(₹)"
                    name="cost_to_market_return"
                    value={data.cost_to_market_return}
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingInputValue">
                    Cost to Market Return (%)
                  </label>
                </div>
              </div>
            </div>
            <hr />
          </div>
          {/* Virality Metrics */}
          <div className="row ps-4 mb-4 pad">
            <h4 className="ps-0 mb-3">Virality Metrics</h4>
            <div className="row mb-4">
              <div className="col-lg-4 ps-0 pb-2">
                <div className="form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="floatingInputValue"
                    placeholder="LTV(₹)"
                    value={data.referers_out_of_customers}
                    name="referers_out_of_customers"
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingInputValue">
                    Referrers out of Customers (%){" "}
                  </label>
                </div>
              </div>
              <div className="col-lg-4 ps-0 pb-2">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInputValue"
                    placeholder="LTV(₹)"
                    value={data.invitees_per_referal}
                    name="invitees_per_referal"
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingInputValue">
                    Invitees Per Referra
                  </label>
                </div>
              </div>
              <div className="col-lg-4 ps-0 pb-2">
                <div className="form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="floatingInputValue"
                    placeholder="LTV(₹)"
                    value={data.invitees_conv_rate}
                    name="invitees_conv_rate"
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingInputValue">
                    Invitees Conversion Rate (%)
                  </label>
                </div>
              </div>
            </div>
            <hr />
          </div>
          {/* Admin */}
          <div className="row ps-4 mb-4 pad">
            <h4 className="ps-0 mb-3">Admin</h4>
            <div className="row mb-4">
              <div className="col-lg-3 ps-0 pb-2">
                <div className="form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="floatingInputValue"
                    placeholder="LTV(₹)"
                    value={data.refund_rate}
                    name="refund_rate"
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingInputValue">Refund Rate (%)</label>
                </div>
              </div>
              <div className="col-lg-3 ps-0 pb-2">
                <div className="form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="floatingInputValue"
                    placeholder="LTV(₹)"
                    value={data.fixed_loss_per_refund}
                    name="fixed_loss_per_refund"
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingInputValue">
                    Fixed Loss per Refund ({currency})
                  </label>
                </div>
              </div>
              <div className="col-lg-3 ps-0 pb-2">
                <div className="form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="floatingInputValue"
                    placeholder="LTV(₹)"
                    value={data.payment_processor_fees}
                    name="payment_processor_fees"
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingInputValue">
                    Payment Processor Fees ({currency})
                  </label>
                </div>
              </div>
              <div className="col-lg-3 ps-0 pb-2">
                <div className="form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="floatingInputValue"
                    placeholder="LTV(₹)"
                    name="merchant_account_fees"
                    value={data.merchant_account_fees}
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingInputValue">
                    Merchant Account Fees (%)
                  </label>
                </div>
              </div>
              <div className="col-lg-3 ps-0 pb-2">
                <div className="form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="floatingInputValue"
                    placeholder="LTV(₹)"
                    value={data.fixed_cost_per_month}
                    name="fixed_cost_per_month"
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingInputValue">
                    Fixed Cost per Month ({currency})
                  </label>
                </div>
              </div>
              <div className="col-lg-3 ps-0 pb-2">
                <div className="form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="floatingInputValue"
                    placeholder="LTV(₹)"
                    value={data.fixed_cost_increase_per_100_cust}
                    name="fixed_cost_increase_per_100_cust"
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="floatingInputValue"
                    style={{
                      fontSize: "15px",
                    }}
                  >
                    Monthly Fixed Cost Inc/100 Customers({currency})
                  </label>
                </div>
              </div>
              <div className="col-lg-3 ps-0 pb-2">
                <div className="form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="floatingInputValue"
                    placeholder="LTV(₹)"
                    value={data.upfront_investment_cost}
                    name="upfront_investment_cost"
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingInputValue">
                    Upfront Investment Cost ({currency})
                  </label>
                </div>
              </div>
              <div className="col-lg-3 ps-0 pb-2">
                <div className="form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="floatingInputValue"
                    placeholder="LTV(₹)"
                    value={data.debt}
                    name="debt"
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingInputValue">Debt ({currency})</label>
                </div>
              </div>
              <div className="col-lg-3 ps-0 pb-2">
                <div className="form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="floatingInputValue"
                    placeholder="LTV(₹)"
                    value={data.debt_interest_rate}
                    name="debt_interest_rate"
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingInputValue">
                    Annual Debt Interest Rate (%)
                  </label>
                </div>
              </div>
            </div>
            <hr />
          </div>
          {/* Valuations */}
          <div className="row ps-4 mb-5 pad">
            <h4 className="ps-0 mb-3">Valuations</h4>
            <div className="row mb-4">
              <div className="col-lg-4 ps-0 pb-2">
                <div className="form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="floatingInputValue"
                    placeholder="LTV(₹)"
                    value={data.tax_rate}
                    name="tax_rate"
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingInputValue">Tax Rate (Months)</label>
                </div>
              </div>
              <div className="col-lg-4 ps-0 pb-2">
                <div className="form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="floatingInputValue"
                    placeholder="LTV(₹)"
                    value={data.discount_rate}
                    name="discount_rate"
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingInputValue">Discount Rate (%)</label>
                </div>
              </div>
              <div className="col-lg-4 ps-0 pb-2">
                <div className="form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="floatingInputValue"
                    placeholder="LTV(₹)"
                    value={data.perpetual_growth_rate}
                    name="perpetual_growth_rate"
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingInputValue">
                    Perpetual Growth Rate (%)
                  </label>
                </div>
              </div>
            </div>
            {/* Calculate Buttons */}
            <div className="p-0 d-flex align-items-center gap-3">
              <button
                className="btn btn-primary rounded-1 py-2 px-3"
                onClick={() => {
                  setResult(DCF(data));
                  console.log(DCF(data));
                }}
              >
                Calculate
              </button>
              <button
                type="button"
                className="btn btn-outline-primary rounded-1 py-2 px-3"
                onClick={(e) => {
                  saveValues(e);
                }}
              >
                Save
              </button>
            </div>
          </div>
          {/* Valuations End*/}
          {/* Input Fields End */}
          {/* Calulated Results */}
          <div className="row ps-4 mb-5 pad calcRow">
            <div className="calcBox py-3 px-4 mb-1 border border-dark rounded-1 border-opacity-25">
              <p className="mb-0 text-muted">
                CUSTOMER ACQUISITION COST ({currency})
              </p>
              <h3 className="mb-0">
                {currency}
                {result.length > 0
                  ? (
                      result[0].marketing_spend /
                      result[0].marketing_customers_acquired
                    ).toFixed(2)
                  : 0}
              </h3>
            </div>
            <div className="calcBox py-3 px-4 mb-1 border border-dark rounded-1 border-opacity-25">
              <p className="mb-0 text-muted">
                LTV PER CUSTOMER (12 MONTHS) ({currency})
              </p>
              <h3 className="mb-0">
                {currency}
                {(() => {
                  var sum_of_total_customer_base;
                  let sum_of_total_gross_revenue = 0;

                  for (let month = 0; month < result.length; month++) {
                    sum_of_total_gross_revenue +=
                      result[month].total_gross_revenue;
                  }

                  result.length > 0
                    ? (sum_of_total_customer_base =
                        result[result.length - 1].total_customer_base)
                    : (sum_of_total_customer_base = 0);

                  return (
                    sum_of_total_gross_revenue / sum_of_total_customer_base
                  ).toFixed(2);
                })()}
              </h3>
            </div>
            <div className="calcBox py-3 px-4 mb-1 border border-dark rounded-1 border-opacity-25">
              <p className="mb-0 text-muted">
                GROSS PROFIT PER CUSTOMER (12 MONTHS)({currency})
              </p>
              <h3 className="mb-0">
                {currency}
                {(() => {
                  const lifetime_value_per_customer = () => {
                    let sum_of_total_gross_revenue = 0;
                    var sum_of_total_customer_base;
                    for (let month = 0; month < result.length; month++) {
                      sum_of_total_gross_revenue +=
                        result[month].total_gross_revenue;
                    }

                    {
                      result.length > 0
                        ? (sum_of_total_customer_base =
                            result[result.length - 1].total_customer_base)
                        : (sum_of_total_customer_base = 0);
                    }

                    return (
                      sum_of_total_gross_revenue / sum_of_total_customer_base
                    );
                  };
                  return (
                    lifetime_value_per_customer() *
                    (1 - data.blended_cogs / 100)
                  ).toFixed(2);
                })()}
              </h3>
            </div>
            <div className="calcBox py-3 px-4 mb-1 border border-dark rounded-1 border-opacity-25">
              <p className="mb-0 text-muted">
                CASH BELOW {currency}0 MONTH ({currency})
              </p>
              <h3 className="mb-0">
                {(() => {
                  const getUnderZeroCashMonth = () => {
                    for (let i = 0; i < result.length; i++) {
                      const month = result[i];
                      const monthlyCash = month.cash_in_bank_curr;
                      if (monthlyCash < 0) {
                        return i + 1;
                      }
                    }
                  };

                  const underZeroCashMonth = getUnderZeroCashMonth();

                  return underZeroCashMonth
                    ? `Month ${underZeroCashMonth}`
                    : "False";
                })()}
              </h3>
            </div>
            <div className="calcBox py-3 px-4 mb-1 border border-dark rounded-1 border-opacity-25">
              <p className="mb-0 text-muted">DCF EQUITY VALUE ({currency})</p>
              <h3 className="mb-0">
                {currency}
                {result.length > 0
                  ? Coma(result[result.length - 1].equity_value.toFixed(2))
                  : 0}
              </h3>
            </div>
            <div className="calcBox py-3 px-4 mb-1 border border-dark rounded-1 border-opacity-25">
              <p className="mb-0 text-muted">
                TIME FOR GROSS PROFITABILITY ({currency})
              </p>
              <h3 className="mb-0">
                {(() => {
                  const getGrossProfitMonth = function() {
                    for (let i = 0; i < result.length; i++) {
                      const month = result[i];
                      const monthlyProfit =
                        month.total_gross_profit_after_adspend;
                      if (monthlyProfit > 0) {
                        return i + 1;
                      }
                    }
                  };

                  const grossProfitMonth = getGrossProfitMonth();

                  return grossProfitMonth
                    ? grossProfitMonth.toString() +
                        " " +
                        (grossProfitMonth > 1 ? "Months" : "Month")
                    : "False";
                })()}
              </h3>
            </div>
            <div className="calcBox py-3 px-4 mb-1 border border-dark rounded-1 border-opacity-25">
              <p className="mb-0 text-muted">CASH PAYBACK PERIOD</p>
              <h3 className="mb-0">
                {(() => {
                  if (data.cash_in_bank <= 0) {
                    return "False";
                  }

                  if (result.length > 0) {
                    if (result[11].cash_ROI_compared_to_day_1 < 1) {
                      return "More than 12 months";
                    }
                  }

                  const getCashPaybackPeriodMonth = function() {
                    for (let i = 0; i < result.length; i++) {
                      const month = result[i];

                      const monthlyComparedCashROI =
                        month.cash_ROI_compared_to_day_1;
                      if (monthlyComparedCashROI > 1) {
                        return i + 1;
                      }
                    }
                  };

                  const cashPaybackPeriodMonth = getCashPaybackPeriodMonth();

                  return cashPaybackPeriodMonth
                    ? cashPaybackPeriodMonth.toString() +
                        " " +
                        (cashPaybackPeriodMonth > 1 ? "Months" : "Month")
                    : "False";
                })()}
              </h3>
            </div>
            <div className="calcBox py-3 px-4 mb-1 border border-dark rounded-1 border-opacity-25">
              <p className="mb-0 text-muted">CASH ROI COMPARED TO DAY 1</p>
              <h3 className="mb-0">
                {result.length > 0
                  ? result[
                      result.length - 1
                    ].cash_ROI_compared_to_day_1.toFixed(2)
                  : "False"}
              </h3>
            </div>
          </div>
          {/* Graphs */}

          <div className="row mb-3 g-3">
            {
              <>
                <div className="col-lg-6">
                  <div className="card ">
                    <div className="card-header">
                      <h3 className="card-title text-center">
                        Total Revenue vs Time
                      </h3>
                    </div>
                    <div className="card-body">
                      <Line
                        options={{
                          responsive: true,
                          maintainAspectRatio: true,
                          scales: {
                            yAxes: [
                              {
                                ticks: {
                                  beginAtZero: true,
                                },
                              },
                            ],
                          },
                        }}
                        data={{
                          labels: Array.from(
                            Array(+data.projection_period)
                          ).map((month, i) => `Month ${i + 1}`),
                          datasets: [
                            {
                              label: "Total Revenue (including refunds)",
                              data: result.map(
                                (month) => month.total_gross_revenue
                              ),
                              fill: false,
                              backgroundColor: "green",
                              borderColor: "green",
                            },
                            {
                              label: "Total Gross Profit (After Adspend)",
                              data: result.map(
                                (month) =>
                                  month.total_gross_profit_after_adspend
                              ),
                              fill: false,
                              backgroundColor: "orange",
                              borderColor: "orange",
                            },
                          ],
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title text-center">
                        Revenue, Earnings, Cash vs Time
                      </h3>
                    </div>
                    <div className="card-body">
                      <Line
                        options={{
                          responsive: true,
                          maintainAspectRatio: true,
                          scales: {
                            yAxes: [
                              {
                                ticks: {
                                  beginAtZero: true,
                                },
                              },
                            ],
                          },
                        }}
                        data={{
                          labels: Array.from(
                            Array(+data.projection_period)
                          ).map((month, i) => `Month ${i + 1}`),
                          datasets: [
                            {
                              label: "Total Revenue (including refunds)",
                              data: result.map(
                                (month) => month.total_gross_revenue
                              ),
                              fill: false,
                              backgroundColor: "green",
                              borderColor: "green",
                            },
                            {
                              label: "Earnings",
                              data: result.map(
                                (month) => month.earnings_before_tax
                              ),
                              fill: false,
                              backgroundColor: "orange",
                              borderColor: "orange",
                            },
                            {
                              label: "Cash",
                              data: result.map(
                                (month) => month.cash_in_bank_curr
                              ),
                              fill: false,
                              backgroundColor: "blue",
                              borderColor: "blue",
                            },
                          ],
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title text-center">
                        Equity Value vs Time
                      </h3>
                    </div>
                    <div className="card-body">
                      <Line
                        options={{
                          responsive: true,
                          maintainAspectRatio: true,
                          scales: {
                            yAxes: [
                              {
                                ticks: {
                                  beginAtZero: true,
                                },
                              },
                            ],
                          },
                        }}
                        data={{
                          labels: Array.from(
                            Array(+data.projection_period)
                          ).map((month, i) => `Month ${i + 1}`),
                          datasets: [
                            {
                              label: "Equity Value",
                              data: result.map((month) => month.equity_value),
                              fill: false,
                              backgroundColor: "green",
                              borderColor: "green",
                            },
                          ],
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title text-center">
                        Total Customers vs Time
                      </h3>
                    </div>
                    <div className="card-body">
                      <Line
                        options={{
                          responsive: true,
                          maintainAspectRatio: true,
                          scales: {
                            yAxes: [
                              {
                                ticks: {
                                  beginAtZero: true,
                                },
                              },
                            ],
                          },
                        }}
                        data={{
                          labels: Array.from(
                            Array(+data.projection_period)
                          ).map((month, i) => `Month ${i + 1}`),
                          datasets: [
                            {
                              label: "Total Customers",
                              data: result.map(
                                (month) => month.total_customer_base
                              ),
                              fill: false,
                              backgroundColor: "green",
                              borderColor: "green",
                            },
                          ],
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
        </div>
        <Modal id="Edit" title="Model Name">
          <div className="modal-body">
            <div className="form-group">
              <label>Model Name</label>
              <input
                type="text"
                className="form-control"
                id="modelName"
                placeholder="Model Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
        </Modal>
      </section>
    </MainLayout>
  );
};

export default NewModel;
