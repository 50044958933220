import React, { useState, useEffect } from "react";
import MainLayout from "../../layout/MainLayout";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { server, config } from "../../env";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";
import NewModal from "../../components/NewModal";

const toolbarOptions = [
  ["bold", "italic", "underline", "strike", "image", "video", "code", "link"], // toggled buttons
  ["blockquote", "code-block"],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction

  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: ["#fff"] }],
  [{ align: [] }],

  ["clean"], // remove formatting button
];
const Content = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loader, setLoader] = useState("");
  const [message, setMessage] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [content, setContent] = useState("");
  const [data, setData] = useState("");
  const [deleteMessage, setDeleteMessage] = useState("");
  const [deleteLoader, setDeleteLoader] = useState("");

  const readContent = async () => {
    await axios
      .get(server + "/content/read/" + id, config)
      .then((res) => {
        setContent(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateContent = async () => {
    setLoader(<Loader />);

    const data = {
      data: content.data,
      name: content.name,
    };
    await axios
      .post(server + "/content/update/" + id, data, config)
      .then((res) => {
        setMessage(<Alert className="success" message={res.data.message} />);
        setLoader("");
      })
      .catch((err) => {
        setLoader("");
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  const deleteContent = async (e) => {
    e.preventDefault();
    setDeleteLoader(<Loader />);
    await axios
      .delete(server + "/content/delete/" + id, config)
      .then((res) => {
        setDeleteMessage(
          <Alert className="success" message={res.data.message} />
        );
        setDeleteLoader("");
        navigate("/actionplans");
      })
      .catch((err) => {
        setDeleteLoader("");
        setDeleteMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });
  };
  const checkAdmin = async () => {
    await axios
      .get(server + "/auth/isAdmin", config)
      .then((res) => {
        setIsAdmin(true);
      })
      .catch((err) => {
        setIsAdmin(false);
        console.log(err);
      });
  };

  useEffect(() => {
    readContent();
    checkAdmin();
  }, []);

  return (
    <MainLayout>
      <div className="container">
        {isAdmin ? (
          <div className="card p-3">
            <div className="card-body p-3">
              {message}
              {/* update title  */}
              <div className="form-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Title"
                  value={content.name}
                  onChange={(e) => {
                    setContent({ ...content, name: e.target.value });
                  }}
                  style={{
                    height: "70px",
                    fontSize: "30px",
                  }}
                />
              </div>

              <ReactQuill
                modules={{ toolbar: toolbarOptions }}
                theme="snow"
                style={{
                  height: "300px",
                  marginBottom: "90px",
                }}
                value={content?.data}
                onChange={(value) => {
                  setContent({ ...content, data: value });
                }}
              />
            </div>
            <div className="card-footer d-flex justify-content-between">
              <button
                className="btn btn-primary"
                onClick={() => {
                  updateContent();
                }}
              >
                Update {loader}
              </button>
              <button
                className="btn btn-danger"
                data-toggle="modal"
                data-target="#deleteContent"
              >
                Delete {loader}
              </button>
            </div>
          </div>
        ) : (
          <div className="row">
            <div dangerouslySetInnerHTML={{ __html: content?.data }} />
          </div>
        )}
      </div>

      <NewModal id="deleteContent" title="Delete Content">
        <div className="modal-body">
          <form onSubmit={deleteContent}>
            {deleteMessage}
            <div className="form-group">
              <label htmlFor="gb">
                Are you sure you want to delete this Content?
              </label>
            </div>
            <div className="form-group d-flex justify-content-end">
              <button type="submit" className="btn btn-danger">
                Delete {deleteLoader}
              </button>
            </div>
          </form>
        </div>
      </NewModal>
    </MainLayout>
  );
};

export default Content;
