import React from "react";
import { useRoutes } from "react-router-dom";
import Login from "../sources/auth/Login";

const AuthRoutes = () => {
  return useRoutes([
    {
      path: "*",
      element: <Login />,
    },
  ]);
};

export default AuthRoutes;
