import Sidebar from "../../../components/Sidebar";
import MainLayout from "../../../layout/MainLayout";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { server, config } from "../../../env";

const TaskName = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [task, setTask] = useState({});

  useEffect(() => {
    const readTask = async () => {
      await axios
        .get(server + "/task/read/" + id, config)
        .then((res) => {
          setTask(res.data.task);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    readTask();
  }, []);

  const movetoLearning = async (e) => {
    e.preventDefault();

    const data = {
      worked: e.target.worked.value,
      result: e.target.result.value,
    };

    await axios
      .post(server + "/task/moveToLearning/" + id, data, config)
      .then((res) => {
        // navigate(`/projects/${task.project}/learnings`);
        window.location.href = `/projects/${task.project}/learnings`;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <MainLayout>
      <section className="mainContent">
        <div className="row">
          <Sidebar />
          <div className="goalNameSection UntitledIdea pt-4 ps-5">
            <div className="row px-3 pt-4 pe-5 mb-3">
              <div className="p-0 headingSide col-md-6 col-sm-12">
                <Link to={`/projects/${task.project}/tasks`}>
                  <p className="ps-0 mb-1 backBtn">
                    <i className="fa-solid fa-arrow-left-long" />
                    &nbsp; All Tasks
                  </p>
                </Link>
                <h1 className="ps-0">{task.name}</h1>
              </div>
              <div className="p-0 buttonsSide col-md-6 col-sm-12 d-flex justify-content-end align-items-center">
                {task.status === "Done" && (
                  <button
                    className="btn border-0 p-0 me-4"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    <img src="/assets/moveToLearningBtn.svg" />
                  </button>
                )}
                <Link to={`/task/${id}/edit`} className="btn border-0 p-0 me-4">
                  <img src="/assets/editBtn.svg" />
                </Link>
                {/* Modal Code Start */}
                <div
                  className="modal fade"
                  id="exampleModal"
                  tabIndex={-1}
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content border-0 rounded-2 p-3">
                      <div className="modal-header flex-column align-items-start border-0 pb-3">
                        <h3 id="exampleModalLabel">Move To Learning</h3>
                      </div>
                      <form onSubmit={movetoLearning}>
                        <div className="modal-body pb-0 pt-0">
                          <div className="form-floating ps-0 mb-2">
                            <select
                              className="form-select selectOpt"
                              id="floatingSelect"
                              aria-label="Floating label select example"
                              name="worked"
                            >
                              <option selected="">Did It Worked?</option>
                              <option value="true">Success</option>
                              <option value="false">Failure</option>
                            </select>
                            <label htmlFor="floatingSelect" className="pt-3">
                              RESULT{" "}
                            </label>
                          </div>
                          <div className="form-floating mb-2">
                            <textarea
                              className="form-control modelTextarea"
                              placeholder="Description"
                              id="floatingTextarea2"
                              name="result"
                            />
                            <label htmlFor="floatingTextarea2" className="pb-2">
                              RESULT
                            </label>
                          </div>
                          <div className="form-floating mb-3 position-relative">
                            <input
                              type="text"
                              className="form-control dashed bg-transparent"
                              id="floatingInputValue"
                              placeholder="Upload an Image"
                              defaultValue="Upload an Image"
                              disabled=""
                            />
                            <label htmlFor="floatingInputValue">IMAGE</label>
                            <input
                              type="file"
                              name="myfile"
                              className="fileField"
                            />
                            <i className="fa-solid fa-plus bg-dark bg-opacity-50 p-1 rounded-1 plusIcon" />
                          </div>
                        </div>
                        <div className="modal-footer justify-content-end border-0 ">
                          <button
                            type="button"
                            className="btn btn-outline-danger rounded-1 px-4"
                            data-bs-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary rounded-1 px-4"
                          >
                            Move
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                {/* Modal Code End */}
              </div>
            </div>
            <div className="row px-3 pt-3 pe-5">
              <p className="p-0 m-0 mb-2 checklist-label">Lever</p>
              <div className="p-0 m-0 mb-4">
                {task.lever === "Acquisition" ? (
                  <span className="px-1 py-2 m-0 bg-warning text-warning bg-opacity-25 rounded-1">
                    {task.lever}
                  </span>
                ) : task.lever === "Activation" ? (
                  <span className="px-1 py-2 m-0 bg-primary text-primary bg-opacity-25 rounded-1">
                    {task.lever}
                  </span>
                ) : (
                  <span className="px-1 py-2 m-0 bg-danger text-danger bg-opacity-25 rounded-1">
                    {task.lever}
                  </span>
                )}
              </div>
              <p className="p-0 m-0 checklist-label">Goal</p>
              <p className="p-0 fs-5 mb-4">{task.goal?.name}</p>
              <p className="p-0 m-0 mb-2 checklist-label">Members</p>
              <div className="form-floating form-floating-custom ps-0 position-relative">
                <div className="p-0 m-0 position-absolute start-0 d-flex gap-1">
                  {task.teammates?.map((teammate, index) => (
                    <p className="m-0 p-0 border-0 rounded-1 px-1">
                      <img
                        src="/assets/account.png"
                        alt=""
                        className="avatar"
                      />
                      &nbsp; {teammate.name}
                    </p>
                  ))}
                </div>
              </div>
              <div className="p-0 pt-4 m-0 mb-4 mt-4 d-flex justify-content-between">
                <div className="col-lg-5 p-0 m-0">
                  <p className="p-0 m-0 checklist-label">START DATE</p>
                  <p className="p-0 fs-5 m-0">
                    {task.startDate &&
                      new Date(task.startDate).toLocaleDateString()}
                  </p>
                </div>
                <div className="col-lg-5 p-0 m-0">
                  <p className="p-0 m-0 checklist-label">Due Date</p>
                  <p className="p-0 fs-5 m-0">
                    {task.endDate &&
                      new Date(task.endDate).toLocaleDateString()}
                  </p>
                </div>
              </div>
              <p className="p-0 m-0 mb-2 checklist-label">DESCRIPTION</p>
              <p className="p-0 fs-5 w-75 mb-4">{task.description}</p>
              <p className="p-0 m-0 mb-2 checklist-label">HYPOTHESIS</p>
              <p className="p-0 fs-5 w-75 mb-4">{task.hypothesis}</p>
              <p className="p-0 m-0 checklist-label">CHECKLIST</p>
              <div className="p-0 m-0 mt-1 mb-3">
                {task.checklist?.map((checklist) => (
                  <div className="form-check mb-1">
                    <input
                      className="form-check-input border border-2 border-secondary"
                      type="checkbox"
                      checked={checklist.status}
                      disabled
                      style={{
                        opacity: "1",
                      }}
                    />
                    <label
                      className="form-check-label text-muted"
                      htmlFor="flexCheckChecked"
                      style={{
                        fontSize: "1rem",
                        opacity: "1",
                      }}
                    >
                      {checklist.name}
                    </label>
                  </div>
                ))}
              </div>
              <div className="col-lg-11 p-0 pb-4 m-0 mb-4">
                <p className="p-0 m-0 mb-3">FILES</p>
                <div className="p-0 m-0 d-flex gap-2">
                  {task.files?.map((file, index) => (
                    <div className="p-0 m-0 position-relative">
                      <img
                        src={server + "/" + file}
                        className="img-thumbnail"
                        alt="sds"
                        style={{
                          width: "100px",
                          height: "100%",
                        }}
                      />
                      <div className="dropdown">
                        <img
                          src="/assets/opendialogBtn.svg"
                          className="position-absolute openDialogIcon"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        />
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <a className="dropdown-item" href="#">
                              View
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              Download
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  );
};

export default TaskName;
