import React from "react";
import Sidebar from "../../../components/Sidebar";
import MainLayout from "../../../layout/MainLayout";

const LearningName = () => {
  return (
    <MainLayout>
      <section className="mainContent">
        <div className="row">
          <Sidebar />
          <div className="goalNameSection UntitledIdea pt-4 ps-5">
            <div className="row px-3 pt-4 pe-5 mb-3">
              <div className="p-0 headingSide col-md-6 col-sm-12">
                <p className="ps-0 mb-1 backBtn">
                  <i className="fa-solid fa-arrow-left-long" />
                  &nbsp; All Learnings
                </p>
                <h1 className="ps-0">Retargeting ads</h1>
              </div>
              <div className="p-0 buttonsSide col-md-6 col-sm-12 d-flex justify-content-end align-items-center">
                <button
                  className="btn border-0 p-0 me-4"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <img src="/assets/SuccessBtn.svg" />
                </button>
                <button className="btn border-0 p-0 me-4">
                  <img src="/assets/editBtn.svg" />
                </button>
              </div>
            </div>
            <div className="row px-3 pt-3 pe-5">
              <p className="p-0 m-0 mb-2 checklist-label">Lever</p>
              <div className="p-0 m-0 mb-4">
                <span className="p-1 px-2 text-center text-warning border border-warning rounded-1">
                  Acquisition
                </span>
              </div>
              <p className="p-0 m-0 checklist-label">Goal</p>
              <p className="p-0 fs-5 mb-4">Goal 3</p>
              <p className="p-0 m-0 mb-2 checklist-label">Goal</p>
              <div className="form-floating form-floating-custom ps-0 position-relative">
                <div className="p-0 m-0 position-absolute start-0 d-flex gap-1">
                  <p className="m-0 p-0 border-0 rounded-1 px-1">
                    <img src="/assets/account.png" alt="" className="avatar" />
                    &nbsp; Aryan
                  </p>
                  <p className="m-0 p-0 border-0 rounded-1 px-1">
                    <img src="/assets/account.png" alt="" className="avatar" />
                    &nbsp; Aryan
                  </p>
                  <p className="m-0 p-0 border-0 rounded-1 px-1">
                    <img src="/assets/account.png" alt="" className="avatar" />
                    &nbsp; Aryan
                  </p>
                </div>
              </div>
              <div className="p-0 pt-4 m-0 mb-4 mt-4 d-flex justify-content-between">
                <div className="col-lg-5 p-0 m-0">
                  <p className="p-0 m-0 checklist-label">START DATE</p>
                  <p className="p-0 fs-5 m-0">12/03/2022</p>
                </div>
                <div className="col-lg-5 p-0 m-0">
                  <p className="p-0 m-0 checklist-label">Due Date</p>
                  <p className="p-0 fs-5 m-0">16/03/2022</p>
                </div>
              </div>
              <p className="p-0 m-0 mb-2 checklist-label">DESCRIPTION</p>
              <p className="p-0 fs-5 w-75 mb-4">
                Nullam eget posuere odio, varius rutrum sapien. Duis neque
                libero, blandit sed ligula vitae, elementum tincidunt purus.
                Etiam feugiat laoreet diam eget mollis. Proin lectus mi, iaculis
                sed sollicitudin a, suscipit id diam. Sed at ligula Duis neque
                libero, blandit sed ligula vitae, elementum tincidunt purus.
                Etiam feugiat laoreet
              </p>
              <p className="p-0 m-0 mb-2 checklist-label">HYPOTHESIS</p>
              <p className="p-0 fs-5 w-75 mb-4">
                diam eget mollis. Proin lectus mi, iaculis sed sollicitudin a,
                suscipit id diam. Sed at ligula Duis neque libero, blandit sed
                ligula vitae, elementum tincidunt purus. Etiam feugiat laoreet
              </p>
              <p className="p-0 m-0 checklist-label">CHECKLIST</p>
              <div className="p-0 m-0 mt-1 mb-3">
                <div className="form-check mb-1">
                  <input
                    className="form-check-input border border-2 border-secondary"
                    type="checkbox"
                    defaultChecked=""
                  />
                  <label
                    className="form-check-label text-decoration-line-through"
                    htmlFor="flexCheckChecked"
                  >
                    Pointer 1
                  </label>
                </div>
                <div className="form-check mb-1">
                  <input
                    className="form-check-input border border-2 border-secondary"
                    type="checkbox"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckChecked"
                  >
                    Pointer 1
                  </label>
                </div>
              </div>
              <div className="col-lg-11 p-0 pb-4 m-0 mb-4">
                <p className="p-0 m-0 mb-3">FILES</p>
                <div className="p-0 m-0 d-flex gap-2">
                  <div className="p-0 m-0 position-relative">
                    <img
                      src="/assets/rectangleFile.svg"
                      className="img-thumbnail"
                    />
                    <div className="dropdown">
                      <img
                        src="/assets/opendialogBtn.svg"
                        className="position-absolute openDialogIcon"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      />
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <a className="dropdown-item" href="#">
                            View
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            Download
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="p-0 m-0 position-relative">
                    <img
                      src="/assets/rectangleFile.svg"
                      className="img-thumbnail"
                    />
                    <div className="dropdown">
                      <img
                        src="/assets/opendialogBtn.svg"
                        className="position-absolute openDialogIcon"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      />
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <a className="dropdown-item" href="#">
                            View
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            Download
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="p-0 m-0 position-relative">
                    <img
                      src="/assets/rectangleFile.svg"
                      className="img-thumbnail"
                    />
                    <div className="dropdown">
                      <img
                        src="/assets/opendialogBtn.svg"
                        className="position-absolute openDialogIcon"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      />
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <a className="dropdown-item" href="#">
                            View
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            Download
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  );
};

export default LearningName;
